import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {Row, Container, Col, Table, button, Modal, ModalHeader, ModalBody, Button} from "reactstrap";
import {Link} from "react-router-dom";

import Storage from "../../../util/storage.service";
import {formatDate, formatRoute, generateQueryUrl} from "../../../util/util.service";
import {API_URL} from "../../../constants/apiConstants";
import {SIGN_IN_MODE} from "../../../constants/actionTypes";
import {loginUserFailure, showAuthModal, fetchBookings} from "../../../redux/actions";
import ButtonLoader from "../../../util/ButtonLoader";
import shipLineIcon from '../../../assets/images/shipline-icon.png';
import bokingconfirmboat from '../../../assets/images/boking-confirm-boat.png';
// import icon from '../../../assets/images/icon.png';
import Request from "../../../util/api.service";
import Booking from "../../../util/booking.service";
import {AvField, AvForm, AvGroup, AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import {REFRIGERATED} from "../../../constants/containerTypes";
import Auth from "../../../util/auth.service";
import {hazClasses} from "../../../constants/hazClasses";
// import moment from "moment";
import ContactModal from "../../../components/contact/ContactModal";
import Common from "../../../util/common.service";
import Contact from "../../../util/contact.service";
import {NotificationContainer, NotificationManager} from "react-notifications";
import {SHIPPER} from "../../../constants/contactType";

class ViewDeal extends React.Component {

    state = {
        queryParams: [],
        vendorList: [],
        confirm_booking_error: '',
        dealLoading: true,
        bookNowLoading: false,
        deal: null,
        isDG: false,
        dgClass: 0,
        container_20ft: 0,
        container_40ft: 0,
        container_40hc: 0,
        container_20ft_charges: 0,
        container_40ft_charges: 0,
        container_40hc_charges: 0,
        haz_charges_20ft: 0,
        haz_charges_40ft: 0,
        haz_charges_40hc: 0,
        total_containers: 1,
        haz_charges: 0,
        total_amount: 0,
        total_amount_inr: 0,
        freight_terms: 1,
        container_type: 1,
        quoteIncludes: [
            'Sea Freight.',
            'Bill of Lading Fees, AMS, ENS, POL THC etc.'
        ],
        quoteExcludes: [
            'Hazardous / Dangerous',
            'BL Telex Fees.',
            'Cargo Insurance',
            'All detention/storage/demurrage charges at port(s).',
            'Any Customs Clearence charges (at POL & POD).',
            'All Ex-Works Logistics Charges at POL on FOT Terms.',
            'POD Local Charges (Chargeable to Consignee).'
        ],
        openPriceBreakdownModal: false,
        openExporetrModal: false,
        openEnquiryModal: false,
        exporterList: [],
        exporter: null,
        enquirable: false,
        un_number: null,
        goods_description: null,
        packing_group: null,
        cargo_weight_20: null,
        cargo_weight_40: null,
        cargo_weight_40hc: null,
        countryList: [],
        promoCodeCharges: 0,
        originalPromoCodeCharges: 0,
        action: true,
        modalLoading: false,
        loading: false,
        title: "",
        promoCode: ""
    };

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        let dealDate;
        if (params.get('date')) {
            dealDate = new Date(params.get('date'));
        }
        if ((!params.get('route') || !params.get('date') || dealDate.getTime() < (new Date()).getTime()) &&
            (params.get('pol') && params.get('pod') && params.get('commodity'))) {
            // if route id is not provided or valid date is not in URL but
            // other params are present then redirect to search result page
            dealDate = new Date();
            dealDate.setDate(dealDate.getDate() + 2);
            let queryParams = [];
            queryParams['pol'] = params.get('pol');
            queryParams['pod'] = params.get('pod');
            queryParams['container_type'] = params.get('container_type');
            queryParams['commodity'] = params.get('commodity');
            queryParams['containers'] = params.get('containers');
            queryParams['date'] = formatDate(dealDate);
            let searchQuery = generateQueryUrl(queryParams);
            Storage.setFlash('error_message', 'Invalid selection, please try gain');
            this.props.history.push('/search' + searchQuery);
        } else if (!params.get('pol') || !params.get('pod') || !params.get('commodity')) {
            // if any of the 3 params not found in URL then redirect to home page
            Storage.setFlash('error_message', 'Invalid selection, please try gain');
            this.props.history.push('/');
        } else {

            let containers = params.get('containers').split(",");
            let queryParams = {
                etd_pol: dealDate,
                pol_un_code: params.get('pol'),
                pod_un_code: params.get('pod'),
                commodity_type_id: parseInt(params.get('commodity')),
                container_type: parseInt(params.get('container_type')),
                containers: params.get('containers'),
                route_id: params.get('route')
            };
            this.setState({
                readiness_date: params.get('readiness_date'),
                queryParams,
                container_20ft: containers[0],
                container_40ft: containers[1],
                container_40hc: containers[2],
                container_type: parseInt(params.get('container_type')),
                total_containers: parseInt(containers[0]) + parseInt(containers[1]) + parseInt(containers[2])
            });

            this.getDealDetails(queryParams);
            this.getExporterList();
            this.getCountryDetails();
        }
    }

    getExporterList = () => {
        if (this.props.auth.is_authenticated) {
            Contact.getContactByType(SHIPPER).then(response => {
                let exporter = response.data.data[0].id;
                this.setState({exporterList: response.data.data, exporter});
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        }
    };

    getCountryDetails = () => {
        Common.getAllCountry()
            .then(response => {
                let country_list = response.data.data, countryList = [];
                for (let country in country_list) {
                    countryList.push({
                        label: country_list[country].name,
                        value: country_list[country].id,
                        key: country_list[country].id
                    })
                }
                this.setState({countryList});
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    addAddress = (data) => {
        this.setState({modalLoading: true});
        Contact.add(data)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({openExporetrModal: !this.state.openExporetrModal, modalLoading: false});
                    this.getExporterList();
                    NotificationManager.success('Success message', response.data.message);
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    getDealDetails = (queryParams) => {
        let payload = {route_id: parseInt(queryParams['route_id']), etd_pol: formatDate(queryParams['etd_pol'])};
        Request.post(API_URL + 'view-deal', payload)
            .then(response => {
                let deal = formatRoute(response.data.data);
                this.setState({
                    deal,
                    dealLoading: false,
                    local_charges: response.data.local_charges,
                    platform_fees: response.data.platform_fees[0].value
                }, this.calculateCharges);
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    setDgClass = (dgClass) => {
        if (this.state.dgClass !== dgClass) {
            this.setState({dgClass});
        }
    };

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    changeContainerQuantity = (number) => {
        let {total_containers} = this.state;
        total_containers = total_containers + number;
        if (total_containers >= 1 && total_containers <= 10) {
            this.setState({total_containers}, this.calculateCharges);
        }
    };

    handleConfirmBooking = (event, errors, values) => {
        if (errors.length === 0) {
            if (this.state.isDG && this.state.dgClass === 0) {
                this.setState({confirm_booking_error: 'Please select IMDG Class'});
            } else if (!this.props.auth.is_authenticated) {
                this.props.showAuthModal(SIGN_IN_MODE);
                this.props.loginUserFailure('Please sign in or sign up to proceed to booking');
            } else if (this.state.total_containers <= 0) {
                this.setState({confirm_booking_error: 'Please select atleast one Container.'});
            } else {
                if (!this.state.deal.haz_classes.length && this.state.isDG) {

                    this.setState({
                        un_number: values.un_number,
                        packing_group: values.packing_group,
                        freight_terms: values.freight_terms,
                        goods_description: values.goods_description,
                        cargo_weight_20: values.cargo_weight_20,
                        cargo_weight_40: values.cargo_weight_40,
                        cargo_weight_40hc: values.cargo_weight_40hc,
                    }, this.toggleEnquiryModal);
                } else {
                    this.setState({bookNowLoading: true});
                    let payload = {
                        commodity_type_id: this.state.deal.commodity_type_id,
                        freight_terms: values.freight_terms,
                        cargo_weight_20ft: (typeof values.cargo_weight_20 !== 'undefined') ? values.cargo_weight_20 : "",
                        cargo_weight_40ft: (typeof values.cargo_weight_40 !== 'undefined') ? values.cargo_weight_40 : "",
                        cargo_weight_40hc: (typeof values.cargo_weight_40hc !== 'undefined') ? values.cargo_weight_40hc : "",
                        packing_group: (typeof values.packing_group !== 'undefined') ? values.packing_group : "",
                        un_number: (typeof values.un_number !== 'undefined') ? values.un_number : "",
                        temperature: (typeof values.temperature !== 'undefined') ? values.temperature : "",
                        humidity: (typeof values.humidity !== 'undefined') ? values.humidity : "",
                        ventilation: (typeof values.ventilation !== 'undefined') ? values.ventilation : "",
                        goods_description: values.goods_description,
                        container_20ft: this.state.container_20ft,
                        container_40ft: this.state.container_40ft,
                        container_40hc: this.state.container_40hc,
                        total_containers: this.state.total_containers,
                        container_type: parseInt(this.state.deal.container_type) === 1 ? 'Dry' : 'Reefer',
                        is_dg: this.state.isDG ? 1 : 0,
                        insurance: 0,
                        exporter: (typeof values.exporter_option !== 'undefined') ? values.exporter_option : "",
                        dg_class: this.state.dgClass,
                        route_id: this.state.deal.route_id,
                        promoCode: this.state.promoCode,
                        cut_off_date: this.state.deal.cut_off_date.format('YYYY-MM-DD'),
                        etd_pol: this.state.deal.etd_pol.format('YYYY-MM-DD'),
                        eta_pod: this.state.deal.eta_pod.format('YYYY-MM-DD'),
                    };
                    Booking.add(payload)
                        .then(response => {
                            if (response.data.status === 'success') {
                                Storage.setFlash('success_message', 'Booking has been requested successfully.');
                                this.setState({bookNowLoading: false});
                                this.props.fetchBookings();
                                this.props.history.push('/booking/' + response.data.data.booking_id + '/view');
                            }
                        }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
                }
            }

        }
    };

    addExporter = (event, errors, values) => {
        if (errors.length === 0) {
            let data = {
                created_by: Auth.user.id,
                company_id: Auth.user.company.id,
                name: values.exporter
            };
            Request.post(API_URL + 'user/exporter', data)
                .then(response => {
                    let exporter = response.data.data[0].id;
                    this.setState({
                        exporterList: response.data.data,
                        openExporetrModal: !this.state.openExporetrModal,
                        exporter
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    addEnquiry = (event, errors, values) => {
        if (errors.length === 0) {
            let user = Auth.user;
            let data = {
                ...values,
                pol: this.state.deal.pol.id,
                user_company_id: user.company_id,
                dg_class: this.state.dgClass,
                un_number: this.state.un_number,
                packing_group: this.state.packing_group,
                cargo_weight_20: this.state.cargo_weight_20,
                cargo_weight_40: this.state.cargo_weight_40,
                cargo_weight_40hc: this.state.cargo_weight_40hc,
                pod: this.state.deal.pod.id,
                freight_terms: this.state.freight_terms,
                enquiry_type: 3,
                exporter_id: (typeof values.exporter_option !== 'undefined') ? values.exporter_option : "",
                route_id: this.state.deal.route_id,
                container_type_id: this.state.container_type,
                commodity_type_id: this.state.deal.commodity_type_id,
                cargo_readiness: this.state.deal.etd_pol.format('YYYY-MM-DD h:mm:ss'),
                container_20ft: this.state.container_20ft,
                container_40ft: this.state.container_40ft,
                container_40hc: this.state.container_40hc,
                user_id: Auth.user.id,
                goods_description: this.state.goods_description,
                etd_pol: this.state.deal.etd_pol.format('YYYY-MM-DD h:mm:ss'),
                eta_pod: this.state.deal.eta_pod.format('YYYY-MM-DD h:mm:ss'),
                pol_agent_company_id: this.state.deal.pol_agent.company_id
            };
            Request.post(API_URL + '/enquiry', data)
                .then((response) => {
                    this.setState({
                        openEnquiryModal: !this.state.openEnquiryModal
                    });
                    this.enquiryForm.reset();
                    if (response.data.status === "success") {
                        this.props.history.push('/enquiry/' + response.data.enquiry_id + '/view');
                        NotificationManager.success('Success message', response.data.message);
                    } else {
                        NotificationManager.error('Error message', response.data.message);
                    }
                })
                .catch((error) => {
                    NotificationManager.error('Error found');
                    console.log(error);
                });
        }
    };

    calculateCharges = () => {
        let deal = this.state.deal;
        let container_20ft_charges = parseFloat(deal['freight_charges_20']) * parseFloat(this.state.container_20ft);
        let container_40ft_charges = parseFloat(deal['freight_charges_40']) * parseFloat(this.state.container_40ft);
        let container_40hc_charges = parseFloat(deal['freight_charges_40hc']) * parseFloat(this.state.container_40hc);
        let haz_charges_20ft = 0;
        let haz_charges_40ft = 0;
        let haz_charges_40hc = 0;

        let local_charge_20 = 0, local_charge_20_inr = 0;
        let local_charge_40 = 0, local_charge_40_inr = 0;
        let local_charge_40hc = 0, local_charge_40hc_inr = 0;
        let local_charges_usd = 0, local_charges_inr = 0;
        for (let local_charge of this.state.local_charges) {
            if (local_charge.currency === "USD") {
                if (local_charge.applicability === "C") {
                    local_charge_20 = (parseFloat(local_charge.charge_20) * parseFloat(this.state.container_20ft));
                    local_charge_40 = parseFloat(local_charge.charge_40) * parseFloat(this.state.container_40ft);
                    local_charge_40hc = parseFloat(local_charge.charge_40hc) * parseFloat(this.state.container_40hc);
                    local_charges_usd += local_charge_20 + local_charge_40 + local_charge_40hc;
                } else {
                    local_charges_usd += parseFloat(local_charge.charge_per_booking);
                }
            } else {
                if (local_charge.applicability === "C") {
                    local_charge_20_inr = (parseFloat(local_charge.charge_20) * parseFloat(this.state.container_20ft));
                    local_charge_40_inr = parseFloat(local_charge.charge_40) * parseFloat(this.state.container_40ft);
                    local_charge_40hc_inr = parseFloat(local_charge.charge_40hc) * parseFloat(this.state.container_40hc);
                    local_charges_inr += local_charge_20_inr + local_charge_40_inr + local_charge_40hc_inr;

                } else {
                    local_charges_inr += parseFloat(local_charge.charge_per_booking);
                }
            }
        }

        if (this.state.isDG) {
            haz_charges_20ft = (parseFloat(this.state.container_20ft) * parseFloat(deal.freight_charges['haz_20']));
            haz_charges_40ft = (parseFloat(this.state.container_40ft) * parseFloat(deal.freight_charges['haz_40']));
            haz_charges_40hc = (parseFloat(this.state.container_40hc) * parseFloat(deal.freight_charges['haz_40hc']));
        }
        let promoCodeCharges = this.state.originalPromoCodeCharges;
        let freightCharges = container_20ft_charges + container_40ft_charges + container_40hc_charges;
        if (freightCharges < this.state.originalPromoCodeCharges) {
            promoCodeCharges = freightCharges;
            freightCharges = 0;
        } else {
            freightCharges = freightCharges - this.state.originalPromoCodeCharges;
        }
        let total_amount = freightCharges + haz_charges_20ft + haz_charges_40ft + haz_charges_40hc + local_charges_usd + parseFloat(this.state.platform_fees);
        this.setState({
            promoCodeCharges,
            total_amount,
            total_amount_inr: local_charges_inr,
            haz_charges_20ft,
            haz_charges_40ft,
            haz_charges_40hc,
            container_20ft_charges,
            container_40ft_charges,
            container_40hc_charges
        });
    };

    increment = (size) => {
        let container_count = this.state['container_' + size];
        let total_containers = this.state.total_containers;
        if (container_count < 10) {
            total_containers++;
            this.setState({
                ['container_' + size]: ++container_count,
                total_containers
            }, this.calculatePromoCodeCharges);
        }
    };

    decrement = (size) => {
        let container_count = this.state['container_' + size];
        let total_containers = this.state.total_containers;
        if (container_count > 0) {
            total_containers--;
            this.setState({
                ['container_' + size]: --container_count,
                total_containers
            }, this.calculatePromoCodeCharges);
        }
    };

    handleDgChange = (event) => {
        let enquirable = false;
        let {quoteIncludes, quoteExcludes, isDG} = this.state;
        isDG = !isDG;
        if (isDG) {
            let dgNote = quoteExcludes.splice(0, 1);
            quoteIncludes.push(dgNote[0]);
        } else {
            let dgNote = quoteIncludes.pop();
            quoteExcludes.unshift(dgNote);
        }
        if (!this.state.deal.haz_classes.length && isDG) {
            enquirable = true;
        }
        this.setState({isDG, quoteIncludes, quoteExcludes, enquirable}, this.calculateCharges);
    };

    toggleopenPriceBreakdownModal = () => {
        this.setState({
            openPriceBreakdownModal: !this.state.openPriceBreakdownModal
        });
    };

    toggleExporterModal = () => {
        this.setState({
            openExporetrModal: !this.state.openExporetrModal
        });
    };

    toggleEnquiryModal = () => {
        this.setState({
            openEnquiryModal: !this.state.openEnquiryModal
        });
    };

    handlePromoCode = (event) => {
        this.setState({title: event.target.value});
    };

    checkPromoCode = () => {
        let invalidPromoCode = null;
        this.setState({
            promoCodeCharges: 0,
            invalidPromoCode: null,
            promoCode: "",
            originalPromoCodeCharges: 0,
            discountType: null,
            discountValue: 0
        });
        if (this.state.title !== "") {
            let data = {
                title: this.state.title,
                vendor_company_id: this.state.deal.pol_agent_company_id,
                shipline_id: this.state.deal.shipline_id,
                pol_id: this.state.deal.pol_id,
                pod_id: this.state.deal.pod_id
            }
            Request.post(API_URL + '/promo-code', data)
                .then(response => {
                    if (response.data.status === "failed") {
                        invalidPromoCode = response.data.message;
                        this.setState({
                            invalidPromoCode,
                            promoCodeCharges: 0,
                            originalPromoCodeCharges: 0
                        }, this.calculateCharges);
                    } else {
                        this.setState({
                            discountType: response.data.data.discount_type,
                            discountValue: response.data.data.discount_value,
                            promoCode: response.data.data.id
                        }, this.calculatePromoCodeCharges);
                        NotificationManager.success('Success message', response.data.message);
                    }
                }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
            this.setState({invalidPromoCode});
        } else {
            this.calculateCharges();
        }
    }

    calculatePromoCodeCharges = () => {
        let promoCodeCharges = 0, originalPromoCodeCharges = 0;
        if (this.state.discountType) {
            let deal = this.state.deal;
            let container_20ft_charges = parseFloat(deal['freight_charges_20']) * parseFloat(this.state.container_20ft);
            let container_40ft_charges = parseFloat(deal['freight_charges_40']) * parseFloat(this.state.container_40ft);
            let container_40hc_charges = parseFloat(deal['freight_charges_40hc']) * parseFloat(this.state.container_40hc);

            let feright_charges = container_20ft_charges + container_40ft_charges + container_40hc_charges;
            if (this.state.discountType === 1) {
                promoCodeCharges = this.state.discountValue;
            } else {
                promoCodeCharges = (feright_charges * this.state.discountValue) / 100;
            }
            originalPromoCodeCharges = promoCodeCharges;
        }

        this.setState({promoCodeCharges, originalPromoCodeCharges}, this.calculateCharges);
    };

    removePromoCode = () => {
        this.setState({
                promoCodeCharges: 0,
                invalidPromoCode: null,
                promoCode: "",
                originalPromoCodeCharges: 0,
                title: ""
            },
            this.calculateCharges
        );
        NotificationManager.success('Success message', 'Promo code has been removed.');
    };

    render() {
        let exporterOption = [<option value="">Select</option>];

        this.state.exporterList.map((value, index) => {
            exporterOption.push(<option value={value.id}>{value.contact_person}</option>);
        });

        let queryParams = {
            pol: this.state.queryParams.pol_un_code,
            pod: this.state.queryParams.pod_un_code,
            container_type: this.state.queryParams.container_type,
            commodity: this.state.queryParams.commodity_type_id,
            containers: this.state.queryParams.containers,
            // date: formatDate(this.state.queryParams.etd_pol)
            date: this.state.readiness_date
        };
        let searchQuery = generateQueryUrl(queryParams);

        let default_haz_classes = [];
        let user = Auth.user;

        for (let haz in hazClasses) {
            default_haz_classes.push(
                <li onClick={() => this.setDgClass(haz)}
                    key={haz}>
                    <div className={this.state.dgClass === haz ? 'container1 active' : 'container1'}>
                        <span className="triangle"/>
                        <span>{haz}</span>
                    </div>
                </li>
            );
        }
        let local_charges = [], charge = "", count = 0, misc_charge = 0;
        if (this.state.local_charges) {
            for (let local of this.state.local_charges) {
                count++;
                if (local.group_as_misc === 1 && local.currency === "INR") {
                    if (local.applicability === "C") {
                        misc_charge += parseFloat(local.charge_20) * parseFloat(this.state.container_20ft);
                        misc_charge += parseFloat(local.charge_40) * parseFloat(this.state.container_20ft);
                        misc_charge += parseFloat(local.charge_40hc) * parseFloat(this.state.container_40hc);
                    } else {
                        misc_charge += local.charge_per_booking;
                    }
                } else {
                    if (local.applicability === "C") {
                        charge = <Fragment key={count}>
                            {this.state.container_20ft > 0 ?
                                <tr>
                                    <td>{local.title}</td>
                                    <td>Per Container</td>
                                    <td>20FT</td>
                                    <td>{this.state.container_20ft}</td>
                                    <td>{local.currency === "INR" ? "(₹) " : "$ "}{local.charge_20}</td>
                                    <td>{local.currency === "USD" ? "$ " + parseInt(this.state.container_20ft) * parseInt(local.charge_20) : ""}</td>
                                    <td>{local.currency === "INR" ? "(₹) " + parseInt(this.state.container_20ft) * parseInt(local.charge_20) : ""}</td>
                                </tr>
                                : ""}
                            {this.state.container_40ft > 0 ?
                                <tr>
                                    <td>{(!this.state.container_20ft > 0) ? local.title : ""}</td>
                                    <td>Per Container</td>
                                    <td>40FT</td>
                                    <td>{this.state.container_40ft}</td>
                                    <td>{local.currency === "INR" ? "(₹) " : "$ "}{local.charge_40}</td>
                                    <td>{local.currency === "USD" ? "$ " + parseInt(this.state.container_40ft) * parseInt(local.charge_40) : ""}</td>
                                    <td>{local.currency === "INR" ? "(₹) " + parseInt(this.state.container_40ft) * parseInt(local.charge_40) : ""}</td>
                                </tr> : ""}
                            {this.state.container_40hc > 0 ?
                                <tr>
                                    <td>{(this.state.container_20ft <= 0) && (this.state.container_40ft <= 0) ? local.title : ""}</td>
                                    <td>Per Container</td>
                                    <td>40HC</td>
                                    <td>{this.state.container_40hc}</td>
                                    <td>{local.currency === "USD" ? "(₹) " : "$ "}{local.charge_40hc}</td>
                                    <td>{local.currency === "USD" ? "$ " + parseInt(this.state.container_40hc) * parseInt(local.charge_40hc) : ""}</td>
                                    <td>{local.currency === "INR" ? "(₹) " + parseInt(this.state.container_40hc) * parseInt(local.charge_40hc) : ""}</td>
                                </tr> : ""}
                        </Fragment>;

                    } else {
                        charge = <tr key={count}>
                            <td><b>{local.title}</b></td>
                            <td>Per Document</td>
                            <td/>
                            <td/>
                            <td/>
                            <td>{local.currency === "USD" ? "$ " + local.charge_per_booking : ""}</td>
                            <td>{local.currency === "INR" ? "(₹) " + local.charge_per_booking : ""}</td>
                        </tr>;
                    }
                    local_charges.push(charge);
                }

            }
        }
        local_charges.push(<tr>
                <td><b>Miscellaneous </b></td>
                <td>Per Document</td>
                <td/>
                <td/>
                <td/>
                <td/>
                <td>{"(₹) " + misc_charge}</td>
            </tr>
        );

        return (
            <Fragment>
                <div className="view-deal-full-page">
                    <Container>
                        <Row>
                            <Col sm="12" lg="12">
                                <div className="confirm-booking">
                                    <Link to={"/search" + searchQuery}>
                                        <i className="fa fa-long-arrow-left" aria-hidden="true"/> Back to Search Results
                                    </Link>
                                    <h1>Confirm Booking</h1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {this.state.dealLoading ?

                    <h1 className={'text-center'}>Loading...</h1> :
                    <Fragment>
                        <div className="new-change-confirm-booking-full">
                            <Container>
                                <Row>
                                    <Col lg="9" xl="9" sm="12" className="center-div-col">
                                        <div className="new-change-confirm-booking">
                                            <Row>
                                                <Col lg="8" sm="8" className="center-div-border">
                                                    <div className="new-change-confirm-booking-inn">
                                                        <Row>
                                                            <Col lg="5" sm="5" className="sm-p">
                                                                <div className="confirm-booking-inn-box">
                                                                    <h5>Departure</h5>
                                                                    <span>{this.state.deal.pol.country.name}</span>
                                                                    <h1>{this.state.deal.pol.un_code} - {this.state.deal.pol.port_name}</h1>
                                                                    <b><span>etd</span>{this.state.deal.etd_pol.format('DD MMM Y, dddd')}
                                                                    </b>
                                                                    <b><span>Cut Off</span>{this.state.deal.cut_off_date.format('DD MMM Y, dddd')}
                                                                    </b>
                                                                </div>
                                                            </Col>
                                                            <Col lg="2" sm="2" className="div-p-no">
                                                                <div className="confirm-booking-inn-box-img">
                                                                    <img src={bokingconfirmboat} alt=""
                                                                         className="img-fluid"/>
                                                                    <p>{this.state.deal.transit_time} Days</p>
                                                                </div>
                                                            </Col>
                                                            <Col lg="5" sm="5">
                                                                <div className="confirm-booking-inn-box">
                                                                    <h5>Arrival</h5>
                                                                    <span>{this.state.deal.pod.country.name}</span>
                                                                    <h1>{this.state.deal.pod.un_code} - {this.state.deal.pod.port_name}</h1>
                                                                    <b><span>eta</span> {this.state.deal.eta_pod.format('DD MMM Y, dddd')}
                                                                    </b>
                                                                </div>
                                                            </Col>
                                                            <Col lg="12" sm="12">
                                                                <div className="new-box-port">
                                                                <span><img src={shipLineIcon} alt=""
                                                                           className="img-fluid"/></span>
                                                                    <p>{this.state.deal.carrier_name}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </Col>

                                                <Col lg="4" sm="4" className="responsive-pd">
                                                    <div className="total-charges-newconfirm-box">
                                                        <h1>Total Charges</h1>
                                                        <ul>
                                                            <li>
                                                                <p>Charges in USD</p>
                                                                <b><span>USD ($)</span> {this.state.total_amount}</b>
                                                            </li>
                                                            <li>
                                                                <i className="fa fa-plus" aria-hidden="true"/>
                                                            </li>
                                                            <li>
                                                                <p>Charges in INR</p>
                                                                <b>
                                                                    <span>INR (&#8377;)</span>{this.state.total_amount_inr}
                                                                </b>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="total-charges-newconfirm-box">
                                                        <div className="view-price-breakdown-newconfirm-box">
                                                            <span>* Promo code is applied on basic freight charges only.</span>
                                                        </div>
                                                        <h1>Promo Code</h1>
                                                        <span
                                                            className={"auth-error"}>{this.state.invalidPromoCode}</span>
                                                        <div className={"promo-code-section"}>
                                                            <input type="text" placeholder={"Promocode"}
                                                                   value={this.state.title}
                                                                   onChange={this.handlePromoCode}
                                                                   disabled={this.state.promoCodeCharges}/>
                                                            {this.state.promoCodeCharges === 0 ?
                                                                <button onClick={this.checkPromoCode}>
                                                                    <i className="fa fa-check"/>
                                                                </button> :
                                                                <button onClick={this.removePromoCode}>
                                                                    <i className="fa fa-times"/>
                                                                </button>}
                                                        </div>

                                                        <ul>
                                                            <li>
                                                                <p>Total Discount</p>
                                                                <b>
                                                                    <span>USD ($)</span> {this.state.promoCodeCharges}
                                                                </b>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="view-price-breakdown-newconfirm-box">
                                                        {/*<h2><span>inr (&#8377;)</span>3,23,639</h2>*/}
                                                        <span>* Basis Provisional Exchange Rate. Actual may vary. {/* <img src={PriceDropIcon} alt="" className="img-fluid" />*/}</span>
                                                        <button onClick={this.toggleopenPriceBreakdownModal}>View
                                                            Price
                                                            Breakdown
                                                        </button>
                                                    </div>

                                                </Col>
                                            </Row>
                                            <AvForm ref={form => this.enquiryForm = form}
                                                    onSubmit={this.handleConfirmBooking}>
                                                <Row>
                                                    <Col lg="12" sm="12">
                                                        <div className="confirm-booking-new-table">
                                                            <table>
                                                                <thead>
                                                                <tr>
                                                                    <th>Container</th>
                                                                    <th>Qty</th>
                                                                    <th>Cargo Weight per Container ( kg )</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td>20 FT</td>
                                                                    <td>
                                                                        <div className="counter-bg">
                                                                <span className="input-group-btn">
                                                                <button type="button"
                                                                        onClick={() => this.decrement('20ft')}
                                                                        className="btn btn-default btn-number">
                                                                <i className="fa fa-minus"/>
                                                                </button>
                                                                </span>
                                                                            <input type="text" name="quant[1]"
                                                                                   className="form-control input-number"
                                                                                   max="10"
                                                                                   value={this.state.container_20ft}/>
                                                                            <span className="input-group-btn">
                                                                <button type="button"
                                                                        onClick={() => this.increment('20ft')}
                                                                        className="btn btn-default btn-number">
                                                                <i className="fa fa-plus"/>
                                                                </button>
                                                                </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <AvField type="number" name="cargo_weight_20"
                                                                                 readOnly={this.state.container_20ft <= 0}
                                                                                 placeholder="Cargo Weight"
                                                                                 validate={{
                                                                                     required: {
                                                                                         value: this.state.container_20ft > 0,
                                                                                         errorMessage: 'Please enter Cargo Weight'
                                                                                     },
                                                                                     max: {
                                                                                         value: parseInt(this.state.deal.frt_20_wt_limit) * 1000,
                                                                                         errorMessage: 'Cargo Weight upto ' + parseInt(this.state.deal.frt_20_wt_limit) * 1000 + ' KG allowed'
                                                                                     },
                                                                                     min: {
                                                                                         value: 1,
                                                                                         errorMessage: 'Cargo Weight should not be negative.'
                                                                                     }
                                                                                 }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                {this.state.container_type !== REFRIGERATED ?
                                                                    <tr>
                                                                        <td>40 FT</td>
                                                                        <td>
                                                                            <div className="counter-bg">
                                                                <span className="input-group-btn">
                                                                <button type="button"
                                                                        onClick={() => this.decrement('40ft')}
                                                                        className="btn btn-default btn-number">
                                                                <i className="fa fa-minus"/>
                                                                </button>
                                                                </span>
                                                                                <input type="text" name="quant[1]"
                                                                                       className="form-control input-number"
                                                                                       max="10"
                                                                                       value={this.state.container_40ft}/>
                                                                                <span className="input-group-btn">
                                                                <button type="button"
                                                                        onClick={() => this.increment('40ft')}
                                                                        className="btn btn-default btn-number">
                                                                <i className="fa fa-plus"/>
                                                                </button>
                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <AvField type="number"
                                                                                     name="cargo_weight_40"
                                                                                     value=""
                                                                                     placeholder="Cargo Weight"
                                                                                     readOnly={this.state.container_40ft <= 0}
                                                                                     validate={{
                                                                                         required: {
                                                                                             value: this.state.container_40ft > 0,
                                                                                             errorMessage: 'Please enter Cargo Weight'
                                                                                         },
                                                                                         max: {
                                                                                             value: parseInt(this.state.deal.frt_40_wt_limit) * 1000,
                                                                                             errorMessage: 'Cargo Weight upto ' + parseInt(this.state.deal.frt_40_wt_limit) * 1000 + ' KG allowed'
                                                                                         },
                                                                                         min: {
                                                                                             value: 1,
                                                                                             errorMessage: 'Cargo Weight should not be negative.'
                                                                                         }
                                                                                     }}/>
                                                                        </td>
                                                                    </tr> : null
                                                                }
                                                                <tr>
                                                                    <td>40 HC</td>
                                                                    <td>
                                                                        <div className="counter-bg">
                                                                <span className="input-group-btn">
                                                                <button type="button"
                                                                        onClick={() => this.decrement('40hc')}
                                                                        className="btn btn-default btn-number">
                                                                <i className="fa fa-minus"/>
                                                                </button>
                                                                </span>
                                                                            <input type="text" name="quant[1]"
                                                                                   className="form-control input-number"
                                                                                   max="10"
                                                                                   value={this.state.container_40hc}/>
                                                                            <span className="input-group-btn">
                                                                <button type="button"
                                                                        onClick={() => this.increment('40hc')}
                                                                        className="btn btn-default btn-number">
                                                                <i className="fa fa-plus"/>
                                                                </button>
                                                                </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {parseInt(this.state.container_40hc) !== 0 &&
                                                                        <AvField type="number" name="cargo_weight_40hc"
                                                                                 value=""
                                                                                 placeholder="Cargo Weight"
                                                                                 readOnly={this.state.container_40hc <= 0}
                                                                                 validate={{
                                                                                     required: {
                                                                                         value: this.state.container_40hc > 0,
                                                                                         errorMessage: 'Please enter Cargo Weight'
                                                                                     },
                                                                                     max: {
                                                                                         value: parseInt(this.state.deal.frt_40hc_wt_limit) * 1000,
                                                                                         errorMessage: 'Cargo Weight upto ' + parseInt(this.state.deal.frt_40hc_wt_limit) * 1000 + ' KG allowed'
                                                                                     },
                                                                                     min: {
                                                                                         value: 1,
                                                                                         errorMessage: 'Cargo Weight should not be negative.'
                                                                                     }
                                                                                 }}/>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {this.state.container_type !== REFRIGERATED ?
                                                    <div className="cargo-line-hazardous-btn">
                                                        {/*{this.state.deal.haz_classes.length ?*/}
                                                        <Row>
                                                            <Col lg="12" sm="12">
                                                                <div className="new-cargo-contains-hazardous">
                                                                    <label className="container-lable">My Cargo
                                                                        Contains
                                                                        Hazardous /
                                                                        Dangerous Goods
                                                                        <input type="checkbox"
                                                                               onChange={this.handleDgChange}
                                                                               value="1"
                                                                            // disabled={!this.state.deal.haz_classes.length}
                                                                               className="material-control-input"/>
                                                                        <span className="checkmark"/>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/*    : null*/}
                                                        {/*}*/}

                                                        {this.state.isDG ?
                                                            <Row>
                                                                <Col lg="5" sm="5">
                                                                    <div className="new-class-tr-div">
                                                                        <p>Class</p>
                                                                        <ul className="class-btn-new">
                                                                            {this.state.deal.haz_classes.length ?
                                                                                this.state.deal.haz_classes.map((haz_class) =>
                                                                                    <li onClick={() => this.setDgClass(haz_class)}
                                                                                        key={haz_class}>
                                                                                        <div
                                                                                            className={this.state.dgClass === haz_class ? 'container1 active' : 'container1'}>
                                                                                            <span className="triangle"/>
                                                                                            <span>{haz_class}</span>
                                                                                        </div>
                                                                                    </li>
                                                                                ) :
                                                                                default_haz_classes
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </Col>

                                                                <Col lg="4" sm="4">
                                                                    <div className="new-class-tr-div-packing-group">
                                                                        <p>Packing Group</p>
                                                                        <ul>
                                                                            <AvRadioGroup inline name="packing_group"
                                                                                          required
                                                                                          errorMessage="Pick one!">
                                                                                <AvRadio customInput label="group I"
                                                                                         value="1"/>
                                                                                <AvRadio customInput label="group II"
                                                                                         value="2"/>
                                                                                <AvRadio customInput label="group III"
                                                                                         value="3"/>
                                                                            </AvRadioGroup>
                                                                        </ul>
                                                                    </div>

                                                                </Col>

                                                                <Col lg="3" sm="3">
                                                                    <div className="new-un-number">
                                                                        <p>
                                                                            {/*<img src={PriceDropIcon} alt="" className="img-fluid" />*/}
                                                                            UN Number
                                                                        </p>
                                                                        {/*<input type="text" name="" value="1502" placeholder=""/>*/}
                                                                        <AvField type="text" name="un_number"
                                                                                 placeholder="UN Number"
                                                                                 validate={{
                                                                                     required: {
                                                                                         value: this.state.isDG,
                                                                                         errorMessage: 'Please enter UN Number'
                                                                                     },
                                                                                     pattern: {
                                                                                         value: '^\s*([0-9]{4})$',
                                                                                         errorMessage: 'Invalid UN Number'
                                                                                     }
                                                                                 }}/>
                                                                    </div>
                                                                </Col>
                                                            </Row> : null}

                                                    </div>
                                                    :
                                                    <div className="new-cargo-reefer-commodity">
                                                        <Row>
                                                            <Col lg="12" sm="12">
                                                                <div className="heading-commodity">
                                                                    <h5>My Cargo is Reefer Commodity</h5>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4" sm="4">
                                                                <div className="commodity-box-one">
                                                                    <p>Temperature ( ºC )</p>
                                                                    <AvField type="number" name="temperature"
                                                                             value=""
                                                                             placeholder="Temperature"/>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4" sm="4">
                                                                <div className="commodity-box-one">
                                                                    <p>Humidity ( % )</p>
                                                                    <AvField type="number" name="humidity"
                                                                             value=""
                                                                             placeholder="Humidity"/>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4" sm="4">
                                                                <div
                                                                    className="commodity-box-one align-change-ventilation">
                                                                    <p>Ventilation ( CBM / Per Hour )</p>
                                                                    <AvField type="number" name="ventilation"
                                                                             value=""
                                                                             placeholder="Ventilation "/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }
                                                <div className="new-describe-your-cargo-full">
                                                    <Row>
                                                        {(user && user.company.company_type) === 2 ?
                                                            <Col lg="6" sm="6">
                                                                <div className="new-class-tr-div-packing-group
                                                                btnfull-freight-terms">
                                                                    <p>Exporter</p>

                                                                    <AvField type="select" name="exporter_option"
                                                                             value={this.state.exporter}>
                                                                        {exporterOption}
                                                                    </AvField>
                                                                    <button type="button"
                                                                            onClick={() => this.toggleExporterModal()}
                                                                            className="btn btn-default btn-number">
                                                                        <i className="fa fa-plus increment-container"/>
                                                                    </button>
                                                                </div>
                                                            </Col>
                                                            : null}
                                                        <Col lg="6" sm="6">
                                                            <div
                                                                className="new-class-tr-div-packing-group btnfull-freight-terms">
                                                                <p>Freight terms</p>
                                                                <ul>
                                                                    <AvRadioGroup inline name="freight_terms" required
                                                                                  errorMessage="Pick one!">
                                                                        <AvRadio customInput label="Prepaid" value="1"/>
                                                                        <AvRadio customInput label="Collect" value="2"/>
                                                                    </AvRadioGroup>

                                                                </ul>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </div>
                                                {/*<div className="new-describe-your-cargo-full">
                                                                <Row>
                                                                <Col lg="6" sm="6">
                                                                <div
                                                                className="new-class-tr-div-packing-group btnfull-freight-terms">
                                                                <div className="new-describe-your-box">
                                                                <p>Apply Promo Code</p>
                                                                <input className="promo-code-input" type="text"
                                                                name="promo_code"
                                                                value={this.state.title}
                                                                onChange={this.handlePromoCode}
                                                                placeholder="Enter Promo Code"/>
                                                                <label style={{
                                                                fontSize: "80%",
                                                                color: "red"
                                                                }}>{this.state.invalidPromoCode}</label>
                                                                </div>
                                                                </div>
                                                                </Col>
                                                                <Col lg="6" sm="6">
                                                                <div
                                                                className="new-class-tr-div-packing-group btnfull-freight-terms">
                                                                <span className="promo-code-span"
                                                                onClick={this.checkPromoCode}>Apply</span>
                                                                </div>
                                                                </Col>
                                                                </Row>
                                                                </div>*/}

                                                <div className="new-describe-your-cargo-full">
                                                    <Row>
                                                        <Col lg="8" sm="12">
                                                            <div className="new-describe-your-box">
                                                                <label>Please describe your cargo or commodity<span> ( Max. 50 Characters )</span></label>
                                                                <AvField type="textarea" name="goods_description"
                                                                         placeholder="Enter cargo or commodity"
                                                                         maxLength={50}/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12" sm="12">
                                                            <div className="note-approvals">
                                                                <b>**Below commodities may need special handling and
                                                                    approvals.</b>
                                                                <p>Soya, Raw Cotton, Batteries, Tobacco, Incense and
                                                                    Match
                                                                    Sticks,
                                                                    Oils, Toys, Scrap, Personal Effects</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Row>
                                                    <Col lg="12" sm="12">
                                                        <div className="btn-confirm-new">
                                                            <p>* Please note above THC displayed is only basis XYZ
                                                                Terminal, for
                                                                other Terminals the same may vary.</p>
                                                            <p> {this.state.confirm_booking_error}</p>
                                                            <ul>
                                                                {this.state.enquirable ?
                                                                    <li>
                                                                        <button>Raise
                                                                            Enquiry
                                                                        </button>
                                                                    </li> :
                                                                    <li>
                                                                        <Button color="primary">
                                                                            <ButtonLoader
                                                                                isLoading={this.state.bookNowLoading}
                                                                                label={"Confirm Booking"}/>
                                                                        </Button>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </div>
                                    </Col>


                                </Row>
                                <div className="your-booking-quote-full">
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-12">
                                            <div className="bookin-gquote-heading">
                                                <h1>Your Booking Quote</h1>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-6">
                                            <div className="booking-includes">
                                                <div className="incliude-heading">
                                                    <h3>Includes</h3>
                                                </div>
                                                <ul>
                                                    {this.state.quoteIncludes.map((excludes, key) =>
                                                        <li key={key}><span>{excludes}</span></li>
                                                    )}

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-6">
                                            <div className="booking-includes booking-excludes">
                                                <div className="incliude-heading">
                                                    <h3>Excludes</h3>
                                                </div>
                                                <ul>
                                                    {this.state.quoteExcludes.map((excludes, key) =>
                                                        <li key={key}><span>{excludes}</span></li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-12">
                                            <div className="bookin-gquote-heading">
                                                <h1>Terms &amp; Conditions</h1>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-12">
                                            <div className="term-conditions">
                                                <h3>Quote Validity :</h3>
                                                <p>The quotation is valid only for this transaction. It cannot be
                                                    utilized
                                                    for any other transaction or vessel date for the same port
                                                    pair.</p>
                                                <h3>Payment :</h3>
                                                <p>On completion of the transaction, all charges to be paid in full
                                                    on
                                                    receipt of the invoice. The bill of lading would be released
                                                    only
                                                    thereafter. All prices are in USD and local currency as
                                                    mentioned in the
                                                    quotes should be credited to Freightgain Vendor account in full
                                                    without
                                                    deduction of any sender or receiver bank charges.</p>
                                                <h3> Vessel and Cut-Off Schedule :</h3>
                                                <p className="term-conditions-text">1. Vessel ETD, ETA are
                                                    indicative in
                                                    nature
                                                    and subject to change without notice.</p>
                                                <p>2. Cut-Off Dates to be met by the shipper as published unless
                                                    otherwise
                                                    communicated.</p>
                                                <h3> Booking Terms :</h3>
                                                <p className="term-conditions-text"># Please stuff cargo only as per
                                                    the
                                                    weight
                                                    mentioned in the quotation. Please do not exceed payload.</p>
                                                <p className="term-conditions-text">1. Hazardous / Dangerous Goods
                                                    booking
                                                    confirmation and loading is subject to approval by carrier.</p>
                                                <p className="term-conditions-text"> # For Hazardous Cargo, Please
                                                    ensure to
                                                    paste stickers on all sides of the containers as per IMD
                                                    guidelines.</p>
                                                <p className="term-conditions-text"># For Perishable Cargo, Please
                                                    ensure
                                                    receiver is ready in all aspects to take delivery on arrival.
                                                    Booking
                                                    Party responsible for cargo rejections / abandonment.</p>
                                                <p className="term-conditions-text"># Palletized cargo or where
                                                    wooden
                                                    packing
                                                    material is used needs to be fumigated as per Importing Country
                                                    Specific
                                                    Requirements.</p>
                                                <p className="term-conditions-text"> 3. Please cover adequate
                                                    insurance,
                                                    bill of
                                                    lading that covers protection of the cargo only at sea.</p>
                                                <p className="term-conditions-text">4. All quotations are
                                                    subject
                                                    to
                                                    local government taxes.</p>
                                                <p className="term-conditions-text">5. Container detention free time
                                                    is
                                                    not
                                                    mandatory and needs to be applied for and approved by the
                                                    shipping line
                                                    for each booking individually.</p>
                                                <p>2. Cut-Off Dates to be met by the shipper as published unless
                                                    otherwise
                                                    communicated.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>

                        <Modal className="price-breackdown-popup" isOpen={this.state.openPriceBreakdownModal}
                               toggle={this.toggleopenPriceBreakdownModal}>
                            <ModalHeader toggle={this.toggleopenPriceBreakdownModal}>
                                Price Breakdown ( India Specific )

                            </ModalHeader>
                            <ModalBody className="price-breakdown-table">
                                <Container>
                                    <div className="pol-full-line">

                                        <Row>
                                            <Col lg="4" sm="4">
                                                <div className="pol-view-price">
                                                    <b>Pol</b>
                                                    <p>{this.state.deal.pol.un_code} - {this.state.deal.pol.port_name}</p>
                                                </div>
                                            </Col>
                                            <Col lg="4" sm="4">
                                                <div className="pol-view-price">
                                                    <b>Pod</b>
                                                    <p>{this.state.deal.pod.un_code} - {this.state.deal.pod.port_name}</p>
                                                </div>
                                            </Col>
                                            <Col lg="2" sm="2">
                                                <div className="pol-view-price">
                                                    <b>Date</b>
                                                    <p>05-02-2020</p>
                                                </div>
                                            </Col>
                                            <Col lg="2" sm="2">
                                                <div className="pol-view-price">
                                                    <b>Etd</b>
                                                    <p>{this.state.deal.etd_pol.format('DD MMM Y, dddd')}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12" sm="12">
                                                <div className="table-full-price">
                                                    <Table className="">
                                                        <thead>
                                                        <tr>
                                                            <th>Heads</th>
                                                            <th>Basis</th>
                                                            <th>Type</th>
                                                            <th>Qty</th>
                                                            <th>Per container Price</th>
                                                            <th>usd</th>
                                                            <th>inr</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr className="border-none">
                                                            <td colSpan="4" className="local-area color-one">
                                                                <Link>international</Link>
                                                            </td>
                                                        </tr>
                                                        {this.state.container_20ft > 0 ?
                                                            <tr>
                                                                <td><b>Freight Charges</b></td>
                                                                <td>Per container</td>
                                                                <td>20FT</td>
                                                                <td>{this.state.container_20ft}</td>
                                                                <td>$ {this.state.deal['freight_charges_20']}</td>
                                                                <td> $ {this.state.container_20ft_charges}</td>
                                                                <td className="change-td"/>
                                                            </tr> : ""}
                                                        {this.state.container_40ft > 0 ?
                                                            <tr>
                                                                <td>
                                                                    <b>{(!this.state.container_20ft > 0) ? "Freight Charges" : ""}</b>
                                                                </td>
                                                                <td>Per container</td>
                                                                <td>40FT</td>
                                                                <td>{this.state.container_40ft}</td>
                                                                <td>$ {this.state.deal['freight_charges_40']}</td>
                                                                <td>$ {this.state.container_40ft_charges}</td>
                                                                <td className="change-td"/>
                                                            </tr> : ""}
                                                        {this.state.container_40hc > 0 ?
                                                            <tr>
                                                                <td>
                                                                    <b>{(this.state.container_20ft <= 0) && (this.state.container_40ft <= 0) ? "Freight Charges" : ""}</b>
                                                                </td>
                                                                <td>Per container</td>
                                                                <td>40HC</td>
                                                                <td>{this.state.container_40hc}</td>
                                                                <td>$ {this.state.deal['freight_charges_40hc']}</td>
                                                                <td>$ {this.state.container_40hc_charges}</td>
                                                                <td className="change-td"/>
                                                            </tr> : ""}

                                                        {this.state.isDG ?
                                                            <Fragment>
                                                                {this.state.container_20ft > 0 ?
                                                                    <tr>
                                                                        <td><b>Hazardous</b></td>
                                                                        <td>Per container</td>
                                                                        <td>20FT</td>
                                                                        <td>{this.state.container_20ft}</td>
                                                                        <td>$ {this.state.deal.freight_charges['haz_20']}</td>
                                                                        <td>$ {this.state.haz_charges_20ft}</td>
                                                                        <td className="change-td"/>
                                                                    </tr> : ""}
                                                                {this.state.container_40ft > 0 ?
                                                                    <tr>
                                                                        <td>{(!this.state.container_20ft > 0) ? "Hazardous" : ""}</td>
                                                                        <td>Per container</td>
                                                                        <td>40FT</td>
                                                                        <td>{this.state.container_40ft}</td>
                                                                        <td>$ {this.state.deal.freight_charges['haz_40']}</td>
                                                                        <td>$ {this.state.haz_charges_40ft}</td>
                                                                        <td className="change-td"/>
                                                                    </tr> : ""}
                                                                {this.state.container_40hc > 0 ?
                                                                    <tr>
                                                                        <td>{(this.state.container_20ft <= 0) && (this.state.container_40ft <= 0) ? "Hazardous" : ""}</td>
                                                                        <td>Per container</td>
                                                                        <td>40HC</td>
                                                                        <td>{this.state.container_40hc}</td>
                                                                        <td>$ {this.state.deal.freight_charges['haz_40hc']}</td>
                                                                        <td>$ {this.state.haz_charges_40hc}</td>
                                                                        <td className="change-td"/>
                                                                    </tr> : ""}
                                                            </Fragment> : ""}

                                                        <tr/>

                                                        <tr>
                                                            <td><b>Discount</b></td>
                                                            <td/>
                                                            <td/>
                                                            <td/>
                                                            <td/>
                                                            <td>$ {this.state.promoCodeCharges}</td>
                                                            <td/>
                                                        </tr>

                                                        <tr className="border-none">
                                                            <td colSpan="6" className="local-area">
                                                                <Link>Local</Link>
                                                            </td>
                                                        </tr>
                                                        {local_charges}
                                                        <tr>
                                                            <td><b>Platform fee</b></td>
                                                            <td>Per Document</td>
                                                            <td/>
                                                            <td/>
                                                            <td/>
                                                            <td>$ {this.state.platform_fees}</td>
                                                            <td/>
                                                        </tr>
                                                        <tr>
                                                            <td><b className="price-breakdown-table">Total Charges</b>
                                                            </td>
                                                            <td className="change-td total-charge-last-one"
                                                                colSpan="5">USD
                                                                ($) {parseFloat(this.state.total_amount)}</td>
                                                            <td className="change-td total-charge-last">INR
                                                                (₹) {this.state.total_amount_inr}
                                                            </td>
                                                        </tr>

                                                        </tbody>

                                                    </Table>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </Container>

                            </ModalBody>
                        </Modal>

                        {this.state.openExporetrModal ?
                            <ContactModal addAddress={this.addAddress}
                                          updateAddress={this.updateAddress}
                                          countryList={this.state.countryList}
                                          address={this.state.address}
                                          modalOpen={this.state.openExporetrModal}
                                          action={this.state.action}
                                          toggleAddModal={this.toggleExporterModal}
                                          modalLoading={this.state.modalLoading}
                                          selectedContactType={SHIPPER}/> : null}


                        <Modal className="price-breackdown-popup  add-exporter" isOpen={this.state.openEnquiryModal}
                               toggle={this.toggleEnquiryModal}>
                            <ModalHeader toggle={this.toggleEnquiryModal}>
                                Enquiry
                            </ModalHeader>
                            <ModalBody className="price-breakdown-table">
                                <Container>
                                    <AvForm className="pol-full-line" onSubmit={this.addEnquiry}>
                                        <Row>
                                            <Col lg="4" sm="4">
                                                <AvGroup className="error-t-negative">
                                                    <label>Customer Name :</label>
                                                    <AvField name="customer_name" id="customer_name" type="text"
                                                             value={(user) ? user.first_name + ' ' + user.last_name : ""}
                                                             validate={{
                                                                 required: {
                                                                     value: true,
                                                                     errorMessage: 'Please enter Customer Name'
                                                                 }
                                                             }}/>
                                                </AvGroup>
                                            </Col>

                                            <Col lg="4" sm="4">
                                                <AvGroup className="error-t-negative">
                                                    <label>Tel Number :</label>
                                                    <AvField name="mobile" id="mobile" type="number"
                                                             value={user ? user.mobile : ""}
                                                             validate={{
                                                                 required: {
                                                                     value: true,
                                                                     errorMessage: 'Please enter Tel Number'
                                                                 }
                                                             }}/>
                                                </AvGroup>
                                            </Col>

                                            <AvField type="hidden" name="freight_terms"
                                                     value={this.state.freight_terms}/>

                                            <Col lg="4" sm="4">
                                                <AvGroup className="error-t-negative">
                                                    <label>EMail Id:</label>
                                                    <AvField name="email" id="email" type="text"
                                                             value={user ? user.email : ""}
                                                             validate={{
                                                                 required: {
                                                                     value: true,
                                                                     errorMessage: 'Please enter EMail Id'
                                                                 }
                                                             }}/>
                                                </AvGroup>
                                            </Col>

                                            <Col lg="4" sm="4">
                                                <AvGroup className="error-t-negative">
                                                    <label>Haz classes :</label>
                                                    <AvField name="dg_class" id="dg_class" type="textarea"
                                                             value={this.state.dgClass} readOnly
                                                    />
                                                </AvGroup>
                                            </Col>

                                            <Col lg="12" sm="12">
                                                <AvGroup className="error-t-negative">
                                                    <label>Remarks (if any)</label>
                                                    <AvField name="remarks" id="remarks" type="textarea"
                                                             value={""}
                                                    />
                                                </AvGroup>
                                            </Col>

                                            <Col lg="4" sm="4">
                                                <button className="add-exporter-btn" type="submit">Add</button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </Container>

                            </ModalBody>
                        </Modal>
                    </Fragment>
                }
                <NotificationContainer/>
            </Fragment>
        )
    }

}

const mapStateToProps = ({auth}) => {
    return {auth};
};
export default connect(
    mapStateToProps,
    {loginUserFailure, showAuthModal, fetchBookings}
)(ViewDeal);