import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {setActiveBooking} from "../../../redux/bookings/actions";

class NoBooking extends Component {

    componentDidMount() {
        this.props.setActiveBooking(0);
    }

    render() {
        //console.log(this.props.bookings);
        return (
            <Fragment>
                    {!this.props.bookings.is_loading ?
                        (this.props.bookings.list.length ? <div className="booking-history-heading no-booking">
                            Click on a booking to view details
                        </div> : <div className="booking-history-heading no-booking">
                            To add a new Enquiry <a color="primary" className="no_booking_btn_click_here" href="/add-enquiry">click here</a>
                        </div>)

                        : <div className="booking-history-heading no-booking">
                            Loading ...
                        </div>
                    }
            </Fragment>
        );
    }

}

const mapStateToProps = ({bookings}) => {
    return {bookings};
};

export default connect(
    mapStateToProps,
    {setActiveBooking}
)(NoBooking);