import React, {Component} from "react";
import {Col} from "reactstrap";
import {connect} from "react-redux";
import {withRouter, NavLink} from "react-router-dom";
import {AWS_S3_URL} from "../../../constants/apiConstants";

class ProfileSideNav extends Component {

    render() {
        let profileImageUrl = this.props.auth.user.photo ?
            AWS_S3_URL + this.props.auth.user.photo :
            "https://ui-avatars.com/api/?" +
            "background=00BBAF&color=fff&size=128&bold=true&font-size=0.5&name=" +
            this.props.auth.user.first_name + "+" +
            this.props.auth.user.last_name;
        return (
            <Col lg="3" sm="6">
                <div className="user-side-nav booking-left-address mt-10px">
                    <div className="profile-img">
                        <img src={profileImageUrl} alt="Profile" className={"user-profile-picture"}/>
                    </div>
                    <h3 className={"loading-text"}>
                        {this.props.auth.user.first_name} {this.props.auth.user.last_name}
                    </h3>
                    <h6>
                        {this.props.auth.user.company.name}
                    </h6>
                    <h6>
                        {this.props.auth.user.company.company_type === 1 ? 'Shipper' : 'Forwarder'}
                    </h6>

                    <ul type="none">
                        <li>
                            <NavLink to={"profile"}>Personal Details</NavLink>
                        </li>
                        <li>
                            <NavLink to={"company"}>Company Details</NavLink>
                        </li>
                        <li>
                            <NavLink to={"bank"}>Bank Details</NavLink>
                        </li>
                        <li>
                            <NavLink to={"address-book"}>Address Book</NavLink>
                        </li>
                        <li>
                            <NavLink to={"member"}>Team Members</NavLink>
                        </li>
                        <li>
                            <NavLink to={"invoice-configuration"}>Invoice Configuration Setting</NavLink>
                        </li>
                    </ul>
                </div>
            </Col>
        );
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(ProfileSideNav)
);