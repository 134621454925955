import React, {Component, Fragment} from "react";
import {Button} from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import MemberCard from "../../../components/member/MemberCard";
import {fetchMembers} from "../../../redux/member/actions";
import {connect} from "react-redux";
import MemberModal from "../../../components/member/MemberModal";
import Common from "../../../util/common.service";
import {NotificationManager} from 'react-notifications';
import Member from "../../../util/member.service";
import ReactTable from "react-table";
import {filterCaseInsensitive} from "../../../util/util.service";
import {AWS_S3_URL} from "../../../constants/apiConstants";

class MemberDetails extends Component {

    state = {
        countryList: [],
        stateList: [],
        cityList: [],
        country: null,
        modalOpen: false,
        modalLoading: false,
        member: [],
        action: true
    };

    componentDidMount() {
        this.props.fetchMembers();
        this.getCountryDetails();
    };

    getCountryDetails = () => {
        Common.getAllCountry()
            .then(response => {
                let country_list = response.data.data, countryList = [], selectedCountry = null;
                for (let country in country_list) {
                    if (country_list[country].id === 96) {
                        selectedCountry = {
                            label: country_list[country].name,
                            value: country_list[country].id,
                            key: country_list[country].id
                        }
                    }
                    countryList.push({
                        label: country_list[country].name,
                        value: country_list[country].id,
                        key: country_list[country].id
                    })
                }
                this.setState({countryList, country: selectedCountry}, this.getStateDetails);
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    getStateDetails = () => {
        let stateList = [];
        Common.stateByCountry(this.state.country.value)
            .then(response => {
                let state_list = response.data.data;
                for (let state in state_list) {
                    stateList.push({
                        label: state_list[state].name,
                        value: state_list[state].id,
                        key: state_list[state].id
                    })
                }

                this.setState({stateList});
            });
    }

    editMember = (id) => {
        let member = this.props.members.list.find(member => member.id === id);
        let stateList = [];
        Common.stateByCountry(member.country_id)
            .then(response => {
                let state_list = response.data.data;
                for (let state in state_list) {
                    stateList.push({
                        label: state_list[state].name,
                        value: state_list[state].id,
                        key: state_list[state].id
                    })
                }
                this.setState({});
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });

        let cityList = [];
        Common.cityByState(member.state_id)
            .then(response => {
                let city_list = response.data.data;
                for (let city in city_list) {
                    cityList.push({
                        label: city_list[city].name,
                        value: city_list[city].id,
                        key: city_list[city].id
                    })
                }
            });
        this.setState({ cityList, city: null,  member, stateList,modalOpen: !this.state.modalOpen, action: false});

    };

    toggleAddModal = () => {
        this.setState({modalOpen: !this.state.modalOpen, action: true, member: [], cityList:[]});
    };

    addMember = (data) => {
        if(this.state.modalLoading === false){
            this.setState({modalLoading: true});
            Member.add(data)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                        this.props.fetchMembers();
                        NotificationManager.success('Success message', response.data.message);
                    }else{
                        this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                        NotificationManager.warning('Warning message', response.data.message);
                    }
                })
                .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        }
    };

    updateMember = (data, id) => {
        this.setState({modalLoading: true});
        Member.update(id, data)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                    NotificationManager.success('Success message', response.data.message);
                    this.props.fetchMembers();
                }else{
                    this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                    NotificationManager.warning('Warning message', response.data.message);
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    removeMember = (id) => {
        if (window.confirm("Are you sure you want to delete this Team Member?")) {
        Member.delete(id)
            .then(response => {
                if (response.data.status === "success") {
                    this.props.fetchMembers();
                    NotificationManager.success('Success message', response.data.message);
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        }
    };

    render() {
        return (
            <Fragment>
                <div className="add-line-bg mt-10px mb-10px">
                    <h1>Team Members</h1>
                    <div className="add-btn-address-plus">
                        <Button onClick={() => this.toggleAddModal('add')}>
                            <i className="fa fa-plus"/> Add Member
                        </Button>
                    </div>

                </div>
                {this.state.modalOpen ?
                    <MemberModal
                        member={this.state.member}
                        countryList={this.state.countryList}
                        stateList={this.state.stateList}
                        country={this.state.country}
                        cityList={this.state.cityList}
                        modalOpen={this.state.modalOpen}
                        action={this.state.action}
                        modalLoading={this.state.modalLoading}
                        toggleAddModal={this.toggleAddModal}
                        addMember={this.addMember}
                        updateMember={this.updateMember}/>
                    : ""}
                <div className="table-view-add">
                    <ReactTable
                        data={this.props.members.list}
                        columns={
                            [
                                {
                                    Header: "Name",
                                    accessor: "first_name",
                                    Cell: props => <p className="text-muted text-wrap">{props.original.first_name +" "+props.original.last_name}</p>
                                },
                                {
                                    Header: "Mobile",
                                    accessor: "mobile",
                                    width: 90,
                                    Cell: props => <p className="text-muted">{props.value}</p>
                                },
                                {
                                    Header: "Email",
                                    accessor: "email",
                                    Cell: props => <p className="text-muted text-wrap">{props.value}</p>
                                },
                                {
                                    Header: "Country",
                                    accessor: "country",
                                    width: 80,
                                    Cell: props => <p
                                        className="text-muted">{props.original.country?.name}</p>
                                },
                                {
                                    Header: "state",
                                    accessor: "state",
                                    Cell: props => <p
                                        className="text-muted">{props.original.state?.name}</p>
                                },
                                {
                                    Header: "city",
                                    accessor: "city",
                                    Cell: props => <p
                                        className="text-muted">{props.original.city?.name}</p>
                                },
                                {
                                    Header: "Logo",
                                    accessor: "photo",
                                    filterable: false,
                                    sortable: false,
                                    width: 60,
                                    Cell: props => <p
                                        className="text-muted">{props.original.photo ?
                                        <img className={'w-40px h-40px'}
                                             src={AWS_S3_URL + props.original.photo}
                                             alt={"Logo"}/> : null}</p>
                                },
                                {
                                    Header: "Action",
                                    accessor: "action",
                                    filterable: false,
                                    sortable: false,
                                    width: 80,
                                    Cell: props => <p className="text-muted">
                                        <a onClick={() => this.editMember(props.original.id)}>
                                                    <span
                                                        className="edit_icon badge badge-pill badge-secondary handle mr-1">
                                                        <i className="fa fa-pencil-square-o" title="Edit Member"/>
                                                    </span>
                                        </a>
                                        &nbsp; &nbsp;
                                        <a onClick={() => this.removeMember(props.original.id)}
                                           title={"Delete Member"}>
                                                    <span
                                                        className="edit_icon badge badge-pill badge-secondary handle mr-1">
                                                            <i className="fa fa-trash" title="Delete Member"/>
                                                    </span>
                                        </a>
                                    </p>
                                }
                            ]
                        }
                        defaultPageSize={5}
                        filterable
                        showPageJump={true}
                        previousText={<i className="fa fa-chevron-left"/>}
                        nextText={<i className="fa fa-chevron-right"/>}
                        //PaginationComponent={DataTablePagination}
                        showPageSizeOptions={true}
                        defaultFilterMethod={filterCaseInsensitive}
                    />
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({members}) => {
    return {members};
}

export default connect(mapStateToProps, {fetchMembers})(MemberDetails);