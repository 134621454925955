import React, {Component, Fragment} from "react";
import {Button} from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import {fetchBanks} from "../../../redux/bank/actions";
import {connect} from "react-redux";
import {NotificationManager} from 'react-notifications';
import Bank from "../../../util/bank.service";
import BankModal from "../../../components/bank/BankModal";
import BankCard from "../../../components/bank/BankCard";
import ReactTable from "react-table";
import {filterCaseInsensitive} from "../../../util/util.service";

class BankDetails extends Component {

    state = {
        modalOpen: false,
        modalLoading: false,
        bank: [],
        action: true
    };

    componentDidMount() {
        this.props.fetchBanks();
    };


    editBank = (id) => {
        let bank = this.props.banks.list.find(bank => bank.id === id);
        this.setState({bank, modalOpen: !this.state.modalOpen, action: 'edit'});

    };

    toggleAddModal = (actionType) => {
        console.log({actionType});
        this.setState({modalOpen: !this.state.modalOpen, action: actionType, bank: []});
    };

    addBank = (data) => {
        if (this.state.modalLoading === false) {
            this.setState({modalLoading: true});
            Bank.add(data)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                        this.props.fetchBanks();
                        NotificationManager.success('Success message', response.data.message);
                    } else {
                        this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                        NotificationManager.warning('Warning message', response.data.message);
                    }
                })
                .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        }
    };

    updateBank = (data, id) => {
        this.setState({modalLoading: true});
        Bank.update(id, data)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                    NotificationManager.success('Success message', response.data.message);
                    this.props.fetchBanks();
                } else {
                    this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                    NotificationManager.warning('Warning message', response.data.message);
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    removeBank = (id) => {
        if (window.confirm("Are you sure you want to delete this Bank Detail ?")) {
        Bank.delete(id)
            .then(response => {
                if (response.data.status === "success") {
                    this.props.fetchBanks();
                    NotificationManager.success('Success message', response.data.message);
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        }
    };

    render() {
        return (
            <Fragment>
                <div className="add-line-bg mt-10px mb-10px">
                    <h1>Bank Details</h1>
                    <div className="add-btn-address-plus">
                        <Button onClick={() => this.toggleAddModal('add')}>
                            <i className="fa fa-plus"/> Add Bank
                        </Button>
                    </div>

                </div>
                {this.state.modalOpen ?
                    <BankModal
                        bankDetails={this.state.bank}
                        modalOpen={this.state.modalOpen}
                        action={this.state.action}
                        modalLoading={this.state.modalLoading}
                        toggleAddModal={this.toggleAddModal}
                        addBank={this.addBank}
                        updateBank={this.updateBank}/>
                    : ""}
                <div className="table-view-add">
                    <ReactTable
                        data={this.props.banks.list}
                        columns={
                            [
                                {
                                    Header: "Account No",
                                    accessor: "account_no",
                                    Cell: props => <p className="text-muted">{props.value}</p>
                                },
                                {
                                    Header: "IFSC Code",
                                    accessor: "ifsc_code",
                                    Cell: props => <p className="text-muted">{props.value}</p>
                                },
                                {
                                    Header: "Bank Name",
                                    accessor: "bank_name",
                                    Cell: props => <p
                                        className="text-muted">{props.value}</p>
                                },
                                {
                                    Header: "Branch",
                                    accessor: "branch",
                                    Cell: props => <p
                                        className="text-muted">{props.value}</p>
                                },
                                {
                                    Header: "Action",
                                    accessor: "action",
                                    filterable: false,
                                    sortable: false,
                                    width: 80,
                                    Cell: props => <p className="text-muted">
                                        <a onClick={() => this.editBank(props.original.id)}>
                                                    <span
                                                        className="edit_icon badge badge-pill badge-secondary handle mr-1">
                                                        <i className="fa fa-pencil-square-o" title="Edit Bank"/>
                                                    </span>
                                        </a>
                                        &nbsp; &nbsp;
                                        <a onClick={() => this.removeBank(props.original.id)}
                                           title={"Delete Bank"}>
                                                    <span
                                                        className="edit_icon badge badge-pill badge-secondary handle mr-1">
                                                            <i className="fa fa-trash" title="Delete Bank"/>
                                                    </span>
                                        </a>
                                    </p>
                                }
                            ]
                        }
                        defaultPageSize={5}
                        filterable
                        showPageJump={true}
                        previousText={<i className="fa fa-chevron-left"/>}
                        nextText={<i className="fa fa-chevron-right"/>}
                        //PaginationComponent={DataTablePagination}
                        showPageSizeOptions={true}
                        defaultFilterMethod={filterCaseInsensitive}
                    />
                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = ({banks}) => {
    return {banks};
}


export default connect(mapStateToProps, {fetchBanks})(BankDetails);