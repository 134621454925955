import React, {Component, Fragment} from "react";
import Profile from "../../../util/profile.service";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import ButtonLoader from "../../../util/ButtonLoader";
import {API_URL, AWS_S3_URL} from "../../../constants/apiConstants";
import Common from "../../../util/common.service";
import Select from "react-select";
import {NotificationManager} from 'react-notifications';
import {connect} from "react-redux";
import {loginUserSuccess} from "../../../redux/auth/actions";
import Auth from "../../../util/auth.service";
import Storage from "../../../util/storage.service";
import CustomFeedback from "../../../components/formValidations/CustomFeedback";
import {maxUploadFileSize} from "../../../constants/defaultValues";

class CompanyDetails extends Component {

    state = {
        isLoading: true,
        userDetails: [],
        existedFileName: null,
        countryList: [],
        country: null,
        countrySelectError: null,
        stateList: [],
        state: null,
        stateSelectError: null,
        cityList: [],
        companyLogo: null,
        city: null,
        citySelectError: null,
        companyLogoError: null,
        VGMauthorizationLetterName: 'Select File',
        VGMauthorizationLetterExistingFile: null,
        VGMauthorizationLetterDoc: null,
        VGMauthorizationLetterError: null
    };

    componentDidMount() {
        this.getProfileDetails();
        let storageMessage = Storage.getFlash('success_message');
        if (storageMessage) {
            NotificationManager.success('Success message', storageMessage);
        }
    }

    getProfileDetails = () => {
        Profile.all()
            .then(response => {
                if (response !== undefined) {
                    let state = null, city = null, country = null;
                    let userDetails = response.data.data;
                    this.props.loginUserSuccess(userDetails);
                    Auth.user = userDetails;
                    if (response.data.status === "success") {

                        if (userDetails.company.state) {
                            state = {
                                label: userDetails.company.state.name,
                                value: userDetails.company.state.id,
                                key: userDetails.company.state.id
                            };
                        }
                        if (userDetails.company.city) {
                            city = {
                                label: userDetails.company.city.name,
                                value: userDetails.company.city.id,
                                key: userDetails.company.city.id
                            };
                        }
                        if (userDetails.company.country) {
                            country = {
                                label: userDetails.company.country.name,
                                value: userDetails.company.country.id,
                                key: userDetails.company.country.id
                            };
                        }

                        this.setState({
                            isLoading: false,
                            userDetails,
                            existedFileName: userDetails.company.logo,
                            country,
                            state,
                            city
                        }, this.getCountryDetails);
                    }
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    getCountryDetails = () => {
        let selectedCountry = this.state.country;
        Common.getAllCountry()
            .then(response => {
                if (response !== undefined) {
                    let country_list = response.data.data, countryList = [];
                    for (let country in country_list) {
                        if (!this.state.country) {
                            if (country_list[country].id === 96) {
                                selectedCountry = {
                                    label: country_list[country].name,
                                    value: country_list[country].id,
                                    key: country_list[country].id
                                }
                            }
                        }
                        countryList.push({
                            label: country_list[country].name,
                            value: country_list[country].id,
                            key: country_list[country].id
                        })
                    }
                    this.setState({countryList, country: selectedCountry}, this.getStateDetails);
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    getStateDetails = () => {
        let stateList = [];
        Common.stateByCountry(this.state.country.value)
            .then(response => {
                if (response !== undefined) {
                    let state_list = response.data.data;
                    for (let state in state_list) {
                        stateList.push({
                            label: state_list[state].name,
                            value: state_list[state].id,
                            key: state_list[state].id
                        })
                    }
                }
            });

        let cityList = [];
        if (this.state.userDetails.company.state) {
            Common.cityByState(this.state.userDetails.company.state.id)
                .then(response => {
                    if (response !== undefined) {
                        let city_list = response.data.data;
                        for (let city in city_list) {
                            cityList.push({
                                label: city_list[city].name,
                                value: city_list[city].id,
                                key: city_list[city].id
                            })
                        }
                    }
                });
        }
        this.setState({stateList, cityList});
    }

    handleCompanyLogo = () => {
        this.setState({existedFileName: null});
        var imagefile = document.querySelector('#comp_logo');
        if (imagefile.files[0]) {
            let allowed_extensions = ['pdf', 'jpeg', 'jpg', 'png'];
            var parts = imagefile.files[0].name.split('.');
            if (allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1) {
                this.setState({
                    companyLogo: imagefile.files[0],
                    companyLogoError: null,
                    companyLogoName: imagefile.files[0].name
                });
            } else {
                this.setState({
                    companyLogoError: 'Please select valid image file',
                    companyLogoName: null,
                    companyLogo: null
                });
            }
        }
    };

    getFilename = (file, docName, errorName, fileVariable, existingFile) => {
        var imagefile = document.querySelector('#' + file);
        this.setState({VGMauthorizationLetterError: null});
        if (imagefile.files[0]) {
            var parts = imagefile.files[0].name.split('.');
            let allowed_extensions = ['pdf'];
            if (allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1) {
                if (imagefile.files[0].size <= maxUploadFileSize) {
                    this.setState({
                        [fileVariable]: imagefile.files[0] ? imagefile.files[0] : '',
                        [docName]: imagefile.files[0] ? imagefile.files[0].shortName(30) : 'Upload VGM',
                        [errorName]: null,
                        [existingFile]: null
                    });
                } else {
                    this.setState({[errorName]: "The uploaded file is exceeding the maximum file size of 2 MB."});
                }
            } else {
                this.setState({[errorName]: "Please select valid file."});
            }
        }
    };

    handleChange = (selected, type, error) => {
        if (type === "country") {
            let stateList = [];
            Common.stateByCountry(selected.value)
                .then(response => {
                    if (response !== undefined) {
                        let state_list = response.data.data;
                        for (let state in state_list) {
                            stateList.push({
                                label: state_list[state].name,
                                value: state_list[state].id,
                                key: state_list[state].id
                            })
                        }
                    }
                });
            this.setState({[type]: selected, stateList, state: null, city: null, [error]: null});
        } else if (type === "state") {
            let cityList = [];
            Common.cityByState(selected.value)
                .then(response => {
                    if (response !== undefined) {
                        let city_list = response.data.data;
                        for (let city in city_list) {
                            cityList.push({
                                label: city_list[city].name,
                                value: city_list[city].id,
                                key: city_list[city].id
                            })
                        }
                    }
                });
            this.setState({[type]: selected, cityList, city: null, [error]: null});
        } else {
            this.setState({[type]: selected, [error]: null});
        }
    };

    onSubmit = (event, errors, values) => {
        let countrySelectError = '',
            stateSelectError = '',
            citySelectError = '',
            companyLogoError = '',
            VGMauthorizationLetterError = null;

        if (this.state.country === null) {
            countrySelectError = 'Please select Country';
            errors.push(countrySelectError);
        }
        if (this.state.state === null) {
            stateSelectError = 'Please select State';
            errors.push(stateSelectError);
        }
        if (this.state.city === null) {
            citySelectError = 'Please select City';
            errors.push(citySelectError);
        }
        if (this.state.existedFileName === null && this.state.companyLogo === null) {
            companyLogoError = "Please select Company logo";
            errors.push(companyLogoError);
        }
        if (this.state.userDetails.company.company_type === 1 && this.state.VGMauthorizationLetterDoc === null && !this.state.userDetails.company.authorization_letter) {
            VGMauthorizationLetterError = "Please select Authorization letter";
            errors.push(VGMauthorizationLetterError);
        }

        this.setState({
            countrySelectError,
            stateSelectError,
            citySelectError,
            companyLogoError,
            VGMauthorizationLetterError
        });

        if (errors.length === 0) {
            let data = {
                ...values,
                company_id: this.state.userDetails.company_id,
                authorization_letter: this.state.VGMauthorizationLetterDoc,
                logo: this.state.companyLogo,
                country_id: this.state.country.value,
                state_id: this.state.state.value,
                city_id: this.state.city.value
            };

            Profile.update(this.state.userDetails.id, data)
                .then(response => {
                    if (response.data.status === "success") {
                        this.getProfileDetails();
                        NotificationManager.success('Success message', response.data.message);
                    }
                })
                .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        }
    };


    render() {

        return (!this.state.isLoading ?
                <Fragment>
                    <div className="booking-history-box">
                        {/* <div className="booking-history-heading">
                            <h6><b>Company Details</b></h6>
                        </div> */}
                        <div className="add-line-bg">
                            <h1>Company Details</h1>  <h5>
                            <a className="view-upload-file" target='_blank'
                               rel="noreferrer noopener" href={API_URL + 'uploads/FG ODEX AUTHORITY LETTER (1).docx'}>Show
                                File</a>
                        </h5>
                        </div>
                        <AvForm onSubmit={this.onSubmit}>
                            <div className="itembg">
                                <div className="company-all">
                                    <div className="row h-vgm">

                                        <div className="col-lg-4 col-12">
                                            <AvGroup className="error-t-negative">
                                                <label>Company Email</label>
                                                <AvField name="email" type="text"
                                                         value={this.state.userDetails.company.email}
                                                         readOnly/>
                                            </AvGroup>
                                        </div>

                                        <div className="col-lg-4 col-12">
                                            <AvGroup className="error-t-negative">
                                                <label>Company Name <span className="required">*</span></label>
                                                <AvField name="name" type="text"
                                                         value={this.state.userDetails.company.name}
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Company name'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>

                                        <div className="col-lg-4 col-12">
                                            <AvGroup className="error-t-negative">
                                                <label>Company Zipcode <span className="required">*</span></label>
                                                <AvField name="zipcode" type="text"
                                                         value={this.state.userDetails.company.zipcode}
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Zip Code'
                                                             },
                                                             pattern: {
                                                                 value: '^[0-9]+$',
                                                                 errorMessage: 'Please enter valid Zip Code.'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>

                                        <div className="col-lg-4 col-12">
                                            <AvGroup className="error-t-negative">
                                                <label>Registration Number <span className="required">*</span></label>
                                                <AvField name="reg_no" id="reg_no" type="text"
                                                         value={this.state.userDetails.company.reg_no}
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Company Registration Number'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>

                                        <div className="col-lg-4 col-12">
                                            <AvGroup className="error-t-negative">
                                                <label>Registration Type. <span className="required">*</span></label>
                                                <AvField type="select" id="reg_type"
                                                         name="reg_type"
                                                         value={this.state.userDetails.company.reg_type}
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Registration Type'
                                                             },
                                                         }}>
                                                    <option value="IEC No">IEC No</option>
                                                    <option value="CIN No">CIN No</option>
                                                    <option value="PAN No">PAN No</option>
                                                    <option value="Aadhaar No">Aadhaar No</option>
                                                    <option value="Passport No">Passport No</option>
                                                </AvField>
                                            </AvGroup>
                                        </div>

                                        <div className="col-lg-4 col-12">
                                            <AvGroup className="error-t-negative">
                                                <label>Company website </label>
                                                <AvField name="website" type="text"
                                                         value={this.state.userDetails.company.website}
                                                         validate={{
                                                             url: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter a valid URL'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <label>Country <span className="required">*</span></label>
                                            <Select
                                                className="home-select"
                                                name="country"
                                                value={this.state.country}
                                                placeholder="Select Country"
                                                noOptionsMessage={() => "No record found"}
                                                onChange={(selected) => this.handleChange(selected, 'country', 'countrySelectError')}
                                                options={this.state.countryList}
                                            />
                                            <CustomFeedback>{this.state.countrySelectError}</CustomFeedback>
                                        </div>

                                        <div className="col-lg-4 col-12">
                                            <label>State <span className="required">*</span></label>
                                            <Select
                                                className="home-select"
                                                name="state"
                                                value={this.state.state}
                                                placeholder="Select State"
                                                noOptionsMessage={() => "No record found"}
                                                onChange={(selected) => this.handleChange(selected, 'state', 'stateSelectError')}
                                                options={this.state.stateList}
                                            />
                                            <CustomFeedback>{this.state.stateSelectError}</CustomFeedback>
                                        </div>

                                        <div className="col-lg-4 col-12">
                                            <label>City <span className="required">*</span></label>
                                            <Select
                                                className="home-select"
                                                name="city"
                                                value={this.state.city}
                                                placeholder="Select City"
                                                noOptionsMessage={() => "No record found"}
                                                onChange={(selected) => this.handleChange(selected, 'city', 'citySelectError')}
                                                options={this.state.cityList}
                                            />
                                            <CustomFeedback>{this.state.citySelectError}</CustomFeedback>
                                        </div>

                                        <div className="col-lg-4 col-12">

                                            <div className="upload-2-files">
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className=" upload-btn-wrapper">
                                                            <label>Company Logo</label>
                                                            <button className="btn">
                                                                <i className="fa fa-file-alt"/> Select File
                                                            </button>
                                                            <input type="file" name="comp_logo"
                                                                   id="comp_logo"
                                                                   onChange={this.handleCompanyLogo}/>
                                                            <p>{this.state.companyLogoName}</p>
                                                            <CustomFeedback>{this.state.companyLogoError}</CustomFeedback>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-6">
                                                        <div className="company-logo-img">
                                                            {this.state.existedFileName != null ?
                                                                <img alt="Company Logo"
                                                                     className={'mx-w-150px mx-h-150px'}
                                                                     src={AWS_S3_URL + this.state.existedFileName}/> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.userDetails.company.company_type === 1 ?
                                            <div className="col-lg-8 col-12">

                                                <div className="upload-2-files">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-6">
                                                            <div className=" upload-btn-wrapper">
                                                                <label>Authorization letter by shipper</label>
                                                                <button className="btn">
                                                                    <i className="fa fa-file-alt"/> Select File
                                                                </button>
                                                                <input type="file" id="authorization_letter"
                                                                       name="authorization_letter"
                                                                       className="custom-file-input"
                                                                       onChange={() => this.getFilename('authorization_letter', 'VGMauthorizationLetterName', 'VGMauthorizationLetterError', 'VGMauthorizationLetterDoc', 'VGMauthorizationLetterExistingFile')}/>
                                                                <p>{this.state.VGMauthorizationLetterName}</p>
                                                                <CustomFeedback>{this.state.VGMauthorizationLetterError}</CustomFeedback>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-6">
                                                            <div className="company-logo-img">
                                                                {this.state.userDetails.company.authorization_letter != null ?
                                                                    <a className="view-upload-file" target='_blank'
                                                                       rel="noreferrer noopener"
                                                                       href={AWS_S3_URL + this.state.userDetails.company.authorization_letter}>Show
                                                                        File</a> : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                        }

                                        <div className="col-lg-12 col-12">
                                            <AvGroup className="error-t-negative">
                                                <label>Company Postal Address <span
                                                    className="required">*</span></label>
                                                <AvField name="address" type="textarea" className={'resize-none'}
                                                         value={this.state.userDetails.company.address}
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Company Address'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>
                                        <div className="col-lg-12 col-12">
                                            <div className="vgm_sub">
                                                <button className="btn primary-btn">
                                                    <ButtonLoader isLoading={this.state.loading} label={"Update"}/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AvForm>
                    </div>
                </Fragment> : <h2 className={"loading-text"}>Loading...</h2>
        );
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(
    mapStateToProps,
    {loginUserSuccess}
)(CompanyDetails);
