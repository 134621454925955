import Request from "./api.service";
import {API_URL} from "../constants/apiConstants";

export default class Contact {

    static all() {
        return Request.get(`${Request.base_url}user/address_book`);
    }

    static getContactByType(type) {
        return Request.get(`${Request.base_url}user/address_book/` + type);
    }

    static get(filters) {
        const queryString = new URLSearchParams(filters).toString();
        return Request.get(`${Request.base_url}user/address_book?${queryString}`);
    }

    static add(payload) {
        return Request.post(`${Request.base_url}user/address_book`, payload);
    }

    static update(id, data) {
        data = {...data, '_method': 'PUT'};
        return Request.post(API_URL + 'user/address_book/' + id, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static delete(id) {
        return Request.delete(API_URL + 'user/address_book/' + id);
    }
}