import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {
    Button,
    button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    UncontrolledAlert
} from "reactstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import {setActiveBooking, updateBooking} from "../../../redux/bookings/actions";
import bokingconfirmboat from "../../../assets/images/boking-confirm-boat.png";
import shipLineIcon from "../../../assets/images/shipline-icon.png";
import ClassIcon from "../../../assets/images/class-icon.png"; 
import PdfIcon from "../../../assets/images/pdf-icon.png";
import Booking from "../../../util/booking.service";
import {
    BOOKING_CANCELLED,
    BOOKING_REQUESTED,
    bookingStatus,
    CRO_RECEIVED,
    CRO_REQUESTED,
    PENDING_VGM,
    UPDATE_VGM, USER_ACCEPTED
} from "../../../constants/bookingStatus";
import Storage from "../../../util/storage.service";
import {API_URL, AWS_S3_URL} from "../../../constants/apiConstants";
import { formatContainer } from "../../../constants/containerUtils";
import axios from 'axios';
import Request from "../../../util/api.service";
import {NotificationManager, NotificationContainer} from 'react-notifications';

import {maxUploadFileSize} from "../../../constants/defaultValues";
import {REDIRECT_URL, SIGN_IN_MODE} from "../../../constants/actionTypes";
import {showAuthModal} from "../../../redux/auth/actions";

import ButtonLoader from "../../../util/ButtonLoader";
import VgmForm from "./vgm/VgmForm";
import VgmView from "./vgm/VgmView";
import {FLATRACK, OPENTOP, REFRIGERATED} from "../../../constants/containerTypes";
import {SHIPPER} from "../../../constants/contactType";
import Auth from "../../../util/auth.service";
import {AvField, AvGroup, AvForm} from "availity-reactstrap-validation";
import Documents from "../../../components/booking/Documents";
import {getFormattedDate} from "../../../util/util.service";

const VGM_MODE = 1;
const BL_MODE = 2;

class BookingDetails extends Component {

    state = {
        showModal: false,
        modalMode: BL_MODE, // 1-VGM details, 2-BL details
        loading: true,
        booking: [],
        booking_note: '',
        success_message: '',
        ShippingBillError: null,
        ShippingBillDoc: null,
        ShippingBillName: 'Only PDF, JPEG, PNG files accepted',
        hazDocumentName: 'Only PDF, JPEG, PNG files accepted',
        hazDocument: null,
        ShippingBillUploading: false,
        hazDocumentUploading: false,
        responseStatus: null,
        showVgmResponse: null,
        vgmBookingNote: null,
        openPriceBreakdownModal: false,
        show_prices: 1,
        packageType: null,
        showDraftBlLink: false,
        openCancelBookingModal: false,
        openForm13Modal: false,
        cancelModalLoading: false,
        openDocumentsModal: false,
        form13Details: [],
        showForm13: false,
        buttonForm13Loading: false,
        buttonDocumentsLoading: false,
        vgmButtonLoading: false,
        vgmSaveButtonLoading: false,
        form13DataButtonLoading: false,
        documentUploadButtonLoading:false,
        isPackageTypeLoading: false
    };

    componentDidMount() {
        if (!this.props.auth.is_authenticated) {
            this.props.showAuthModal(SIGN_IN_MODE);
            Storage.setFlash(REDIRECT_URL, '/booking/' + this.props.match.params.id + '/view');
            this.props.history.push("/");
        } else {
            this.getBookingDetails();
            this.getPackageType();
        }
        let success_message = Storage.getFlash('success_message');
        this.setState({success_message});
        setTimeout(() => {
            this.setState({
                success_message: null,
            });
        }, 5000);
        this.props.setActiveBooking(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.setActiveBooking(this.props.match.params.id);
            this.getBookingDetails();
        }
    }

    getBookingDetails = () => {
        Booking.details(this.props.match.params.id)
            .then((response) => {
                let showForm13 = false;
                let showDraftBlLink = false;
                let booking_note = '';
                let booking = response.data.data;
                let show_prices = 1
                let shippingBillStatus = true;
                let vgm_booking_note = null;
                    if (response !== undefined) {
                        let vgm_cut_off_label = "";
                        
                        vgm_booking_note = null;
                        
                        let checkBls = true;
                        

                        if (booking.containers) {
                            if (booking.containers) {
                                for (let container of booking.containers) {
                                    if (container.container_number !== null) {
                                        showForm13 = true;
                                    }
                                }
                            }

                            let pending_vgm = booking.containers.filter(container => !container.vgm_done_at).length;
                            for (let container of booking.containers) {
                                if (container.bl_id === null && booking.booking_status >= UPDATE_VGM) {
                                    showDraftBlLink = true;
                                }
                                if (container.bl_id === null) {
                                    checkBls = false;
                                }
                            }
                            /* if (booking.cro_details && booking.cro_details.vgm_cut_off) {
                                 vgm_cut_off_label = ' (Please Upload your VGM before ' + moment(booking.cro_details.vgm_cut_off, 'DD-MM-YYYY h:mm:ss').format('LLLL') + ')';
                                 if (moment(moment().format('YYYY-MM-DD HH:mm:ss')).isAfter(moment(moment(booking.cro_details.vgm_cut_off, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')), 'minute')) {
                                     vgm_cut_off_label = "( Your VGM cut off date has been expired, Kindly contact Admin.)";
                                 }
                             }*/
                            if (pending_vgm !== 0) {
                                vgm_booking_note = "VGM Pending( X " + pending_vgm + ")";
                            } else {
                                vgm_booking_note = "VGM uploaded, BL Draft can be started now, " + vgm_cut_off_label;
                            }
                        }


                        let show_prices = booking.pol_agent_company.settings && booking.pol_agent_company.settings.show_prices ? parseInt(booking.pol_agent_company.settings.show_prices) : 1;
                        booking['etd_pol'] = booking['etd_pol'] ? moment(booking['etd_pol']) : null;
                        booking['eta_pod'] = booking['eta_pod'] ? moment(booking['eta_pod']) : null;
                        booking['cut_off_date'] = booking['cut_off_date'] ? moment(booking['cut_off_date']) : null;
                        booking['created_at'] = moment(booking['created_at']);
                        
                        if (booking.booking_status === BOOKING_REQUESTED) {
                            booking_note = "Rate quotation has been received";
                        } else if (booking.booking_status === CRO_REQUESTED) {
                            booking_note = "CRO has been requested to the shipping line";
                        } else if (booking.booking_status === CRO_RECEIVED) {
                            booking_note = 'CRO Received for the booking, please update VGM for all containers';
                        } else if (booking.booking_status === UPDATE_VGM) {
                            booking_note = "VGM uploaded, From 13 can be started now.";
                            if (!checkBls) {
                                vgm_booking_note = "VGM uploaded, From 13 can be started now." + vgm_cut_off_label;
                            } else {
                                vgm_booking_note = "VGM uploaded";
                            }

                        } else if (booking.booking_status === PENDING_VGM) {
                            let pending_vgm = 0;
                            for (let container of booking.containers) {
                                if (!container.vgm_done_at) {
                                    pending_vgm++;
                                }
                            }
                            if (pending_vgm !== 0) {
                                booking_note = "VGM Pending( X " + pending_vgm + ")";
                                vgm_booking_note = "VGM Pending for " + pending_vgm + " container(s)" + vgm_cut_off_label;
                            }
                        }

                        
                        if (booking.cro_details && booking.cro_details.shipping_bill_cut_off) {
                            if (moment(moment().format('YYYY-MM-DD HH:mm:ss')).isAfter(moment(moment(booking.cro_details.shipping_bill_cut_off, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')), 'minute')) {
                                shippingBillStatus = false;
                            }
                        }
                        let vgmButtonLoading = this.state.vgmButtonLoading;
                        let showModal = this.state.showModal;
                        if (vgmButtonLoading === true) {
                            showModal = !this.state.showModal;
                        }

                        this.setState({
                            vgmButtonLoading: false,
                            vgmSaveButtonLoading: false,
                            showModal,
                            showForm13,
                            showDraftBlLink,
                            booking,
                            platform_fees: parseInt(booking.platform_fees.value),
                            shippingBillStatus,
                            booking_note,
                            vgmBookingNote: vgm_booking_note,
                            show_prices,
                            loading: false
                        }, this.getShipperList);
                    }
                    let vgmButtonLoading = this.state.vgmButtonLoading;
                    let showModal = this.state.showModal;
                    if (vgmButtonLoading === true) {
                        showModal = !this.state.showModal;
                    }

                    this.setState({
                        vgmButtonLoading: false,
                        vgmSaveButtonLoading: false,
                        showModal,
                        showForm13,
                        showDraftBlLink,
                        booking,
                        platform_fees: booking.platform_fees.value?parseInt(booking.platform_fees.value):0,
                        shippingBillStatus,
                        booking_note,
                        vgmBookingNote: vgm_booking_note,
                        show_prices,
                        loading: false
                    }, this.getShipperList);


                }
            ).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };


    getShipperList = () => {
        let user = Auth.user;
        Request.get(API_URL + 'user/address_book/' + SHIPPER).then((response) => {
            if (response !== undefined) {
                let shipperList = [];
                if (response.data.data) {
                    for (let shipper of response.data.data) {
                        shipperList.push({
                            label: shipper.company_name,
                            value: shipper.id,
                            key: shipper.id,
                            reg_no: shipper.registration_no
                        });
                    }
                    this.setState({shipperList});
                }
            }
        }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    getPackageType = () => {
        this.setState({isPackageTypeLoading : true});
        Request.get(API_URL + 'package-type')
            .then(response => {
                this.setState({
                    packageType: response.data.data,
                    containerId: this.props.containerId,
                    isPackageTypeLoading:false
                });
            }).catch(error => {
            this.setState({isPackageTypeLoading : false});
            console.log(error);
            NotificationManager.error('Something went wrong');
        });
    };

    deleteShippingBillDoc = (key) => {

        this.setState({responseStatus: null});
        if (window.confirm("Are you sure you want to delete this document?")) {
            Request.delete(API_URL + 'user/shipping-bill/' + this.state.booking.id + '/' + key)
                .then(response => {
                    this.setState({
                        responseStatus: <UncontrolledAlert className="small-alert">
                            Shipping Bill document deleted for{this.state.booking.book_id}
                        </UncontrolledAlert>
                    });
                    this.getBookingDetails();
                });
        }
    };

    getShippingBillFile = (name, docFile, docName) => {
        var file = document.querySelector('#' + name);
        if (file.files[0]) {
            let allowed_extensions = ['pdf', 'jpeg', 'jpg', 'png'];
            let parts = file.files[0].name.split('.');
            let file_extension = parts[parts.length - 1];
            let doc_name = "Only PDF, JPEG, PNG files accepted";
            let doc_file = null;
            if (allowed_extensions.indexOf(file_extension.toLowerCase()) !== -1) {
                if (file.files[0].size <= maxUploadFileSize) {
                    doc_file = file.files[0];
                    doc_name = file.files[0].name;
                } else {
                    NotificationManager.warning('Warning message', 'The uploaded file is exceeding the maximum file size of 5 MB.');
                }
            } else {
                NotificationManager.warning('Warning message', 'Please select a valid document file');
            }
            this.setState({[docName]: doc_name, [docFile]: doc_file});
        }
    };

    uploadShippingBill = (doc, files, docName, loading) => {
        if (this.state[files] !== null) {
            let body = new FormData();
            body.append(doc, this.state[files]);
            body.append('booking_id', this.state.booking.id);
            this.setState({[loading]: true});
            axios.post(API_URL + 'user/shipping-bill', body, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                this.setState({
                    [docName]: "Only PDF, JPEG, PNG files accepted",
                    [files]: null,
                    [loading]: false
                });
                NotificationManager.success('Warning message', response.data.message);
                this.getBookingDetails();
            }).catch((error) => {
                NotificationManager.error('Error found');
                console.log(error);
                this.setState({[loading]: false});
            })
        } else {
            NotificationManager.warning('Warning message', 'Please select a document file');
        }
    };

    scrollToShippingBill = () => {
        document.getElementById("shipping_bill_div").scrollIntoView();
        /* var elem = document.getElementById("shipping_bill_div");
        var topPos = elem.offsetTop;
        this.scrollTo(document.getElementById('shipping_bill_div'), topPos - 30, 600);*/
    };

    scrollToUpdateVgm = () => {
        document.getElementById("upload_vgm_div").scrollIntoView();
    };

    scrollTo = (element, to, duration) => {
        let start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        let animateScroll = function () {
            currentTime += increment;
            element.scrollTop = Math.easeInOutQuad(currentTime, start, change, duration);
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };

    toggleVgmModal = (container, key) => {
        this.setState({
            showModal: !this.state.showModal,
            containerId: container.id,
            selectedContainer: container,
            showVgmResponse: null,
            modalTitle: "Container " + (parseInt(key) + 1) + " - " + formatContainer(parseInt(this.state.booking.container_type_id),container.container_size),
            modalMode: VGM_MODE
        });
    };

    downloadVgmPdf = (container, containerNumber) => {
        if (!this.props.auth.user.company.logo || !this.props.auth.user.company.address) {
            this.setState({
                showVgmResponse: <UncontrolledAlert className="small-alert">Cannot download the VGM .<a
                    href="/user/company">Please Click</a> here to fill company details</UncontrolledAlert>
            });
        } else if (container.id) {
            Request.downloadFile(API_URL + 'user/vgm-pdf/' + container.id, 'VGM_PDF_' + containerNumber + '.pdf');
        }
    };

    handleBlPdf = (booking) => {
        return axios.get(API_URL + 'user/bl-pdf/' + booking.id + '/' + booking.bl_id, {responseType: 'blob'})
            .then((response) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('download', 'BL_PDF_' + booking.id + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    handleInvoicePdf = (booking) => {
        let book_id = booking.book_id;
        let fileString = book_id.replace('/', '-');
        fileString = fileString.replace('/', '-');
        return axios.get(API_URL + 'user/invoice-pdf/' + booking.invoice.id, {responseType: 'blob'})
            .then((response) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('download', 'Invoice-' + fileString + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    onVgmSubmit = (id, data) => {
        let vgmButtonLoading = false, vgmSaveButtonLoading = false;

        if (data.submitType === 0) {
            vgmSaveButtonLoading = true;
        }
        if (data.submitType === 1) {
            vgmButtonLoading = true;
        }
        this.setState({vgmButtonLoading, vgmSaveButtonLoading});

        Booking.updateVgm(id, data)
            .then((response) => {
                if (response.data.status === 'success') {
                    this.props.updateBooking(response.data.data);
                    NotificationManager.success('Success message', response.data.message);
                    this.getBookingDetails();
                } else {
                    NotificationManager.warning('Warning message', response.data.message);
                }

            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });

    };

    toggleopenPriceBreakdownModal = () => {
        this.setState({
            openPriceBreakdownModal: !this.state.openPriceBreakdownModal
        });
    };

    toggleForm13Modal = () => {
        this.props.history.push('/booking/' + this.state.booking.id + '/form13');
        /*if (!this.state.openForm13Modal) {
            this.getForm13BookingDetails();
        } else {
            this.setState({openForm13Modal: !this.state.openForm13Modal});
        }*/
    };

    toggleDocumentsModal = () => {
        this.setState({openDocumentsModal: !this.state.openDocumentsModal});
    };

    getForm13BookingDetails = () => {
        this.setState({form13DataButtonLoading: true});
        Request.get(API_URL + '/user/form-13/booking/' + this.state.booking.id).then((response) => {
            let form13Details = [];
            let openForm13Modal = this.state.openForm13Modal
            if (response.data.status === "success") {
                form13Details = response.data;
                openForm13Modal = !this.state.openForm13Modal;
            } else {
                NotificationManager.warning('Warning message', response.data.message);
            }
            this.setState({form13Details, openForm13Modal, form13DataButtonLoading: false})
        }).then(error => error);
    }

    cancelBooking = (event, errors, values) => {
        if (errors.length === 0) {
            this.setState({cancelModalLoading: true});
            let data = {
                id: this.state.booking.id,
                cancellation_remark: values.cancellation_remark
            }
            Booking.cancel_booking(data).then(response => {
                if (response.data.status === "success") {
                    this.setState({
                        openCancelBookingModal: !this.state.openCancelBookingModal,
                        cancelModalLoading: false
                    });
                    NotificationManager.success('Success message', response.data.message);
                    this.props.updateBooking(response.data.data);
                    this.getBookingDetails();
                }
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        }
    };

    /* onForm13Submit = (data) => {
         this.setState({buttonForm13Loading: true});
         Request.post(API_URL + '/user/form13', data).then(response => {
             if (response.data.status === "success") {
                 this.setState({buttonForm13Loading: false});
                 this.getBookingDetails();
                 NotificationManager.success('Success message', response.data.message);
             } else {
                 NotificationManager.warning('Warning message', response.data.message);
             }
             this.setState({buttonForm13Loading: false, openForm13Modal: !this.state.openForm13Modal});
         }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
     };*/

    sendToShipline = (data) => {
        this.setState({buttonDocumentsLoading: true});
        Booking.sendToShipLine(data)
            .then((response) => {
                if (response.data.status === "success") {
                    NotificationManager.success('Success message', response.data.message);
                    window.scrollTo(0, 0);
                }
                this.setState({buttonDocumentsLoading: false, openDocumentsModal: !this.state.openDocumentsModal});
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    toggleCancelBooking = () => {
        this.setState({openCancelBookingModal: !this.state.openCancelBookingModal});
    };

    upload_documents = (data) => {
        this.setState({documentUploadButtonLoading: true});
        Booking.upload_documents(data)
            .then((response) => {
                if (response.data.status === "success") {
                    this.getBookingDetails();
                    this.setState({documentUploadButtonLoading: false});
                    NotificationManager.success('Success message', response.data.message);
                    window.scrollTo(0, 0);
                }
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    }

    deleteDoc = (id, doc, key) => {
        this.setState({responseStatus: null});
        Booking.document_delete(id, doc, key)
            .then(response => {
                if (response.data.status === 'success') {
                    this.getBookingDetails();
                    NotificationManager.success('Success message', response.data.message);
                    this.setState({responseStatus: response.data.message});

                }
                window.scrollTo(0, 0);
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };



    render() {
        let local_charges = [], charge = "", count = 0, misc_charge = 0;
        if (this.state.booking) {
            if (this.state.booking.local_charges) {
                for (let local of this.state.booking.local_charges) {
                    count++;
                    if (local.group_as_misc === 1 && local.currency === "INR") {
                        if (local.applicability === "C") {
                            misc_charge += parseFloat(local.charge_20) * parseFloat(this.state.booking.container_20ft);
                            misc_charge += parseFloat(local.charge_40) * parseFloat(this.state.booking.container_40ft);
                            misc_charge += parseFloat(local.charge_40hc) * parseFloat(this.state.booking.container_40hc);
                        } else {
                            misc_charge += local.charge_per_booking;
                        }
                    } else {
                        if (local.applicability === "C") {
                            charge = <Fragment key={count}>
                                {this.state.booking.container_20ft > 0 && local.show_on_quotation == true ?
                                    <tr>
                                        <td>{local.title ? local.title.name : ""}</td>
                                        <td>Per Container</td>
                                        <td>{this.state.booking.container_type_id === REFRIGERATED ? "20 RF" : this.state.booking.container_type_id === OPENTOP ? "20 OT" : this.state.booking.container_type_id === FLATRACK ? "20 FR": "20 FT"}</td>
                                        <td>{this.state.booking.container_20ft}</td>
                                        <td>{local.currency === "INR" ? "(₹) " : "$ "}{parseInt(local.charge_20) > 0 ? local.charge_20 : 0}</td>
                                        <td>{local.currency === "USD" ? "$ " + (parseInt(local.charge_20) > 0 ? parseInt(this.state.booking.container_20ft) * parseInt(local.charge_20) : 0) : ""}</td>
                                        <td>{local.currency === "INR" ? "(₹) " + (parseInt(local.charge_20) > 0 ? parseInt(this.state.booking.container_20ft) * parseInt(local.charge_20) : 0) : ""}</td>
                                    </tr>
                                    : ""}
                                {this.state.booking.container_40ft > 0 && local.show_on_quotation == true ?
                                    <tr>
                                        <td>{(!this.state.booking.container_20ft > 0) ? (local.title ? local.title.name : "") : ""}</td>
                                        <td>Per Container</td>
                                        <td> {this.state.booking.container_type_id === OPENTOP ? "40 OT" : this.state.booking.container_type_id === FLATRACK ? "40 FR": "40FT" }</td>
                                        <td>{this.state.booking.container_40ft}</td>
                                        <td>{local.currency === "INR" ? "(₹) " : "$ "}{parseInt(local.charge_40) > 0 ? local.charge_40 : 0}</td>
                                        <td>{local.currency === "USD" ? "$ " + (parseInt(local.charge_40) > 0 ? parseInt(this.state.booking.container_40ft) * parseInt(local.charge_40) : 0) : ""}</td>
                                        <td>{local.currency === "INR" ? "(₹) " + (parseInt(local.charge_40) > 0 ? parseInt(this.state.booking.container_40ft) * parseInt(local.charge_40) : 0) : ""}</td>
                                    </tr> : ""}
                                {this.state.booking.container_40hc > 0 && local.show_on_quotation == true ?
                                    <tr>
                                        <td>{(this.state.booking.container_20ft <= 0) && (this.state.booking.container_40ft <= 0) ? (local.title ? local.title.name : "") : ""}</td>
                                        <td>Per Container</td>
                                        <td>{this.state.booking.container_type_id === REFRIGERATED ? "40 RF HC" : this.state.booking.container_type_id === OPENTOP ? "40 OT HC" : this.state.booking.container_type_id === FLATRACK ? "40 FL HC" : "40 HC"}</td>
                                        <td>{this.state.booking.container_40hc}</td>
                                        <td>{local.currency === "INR" ? "(₹) " : "$ "}{parseInt(local.charge_40hc) > 0 ? local.charge_40hc : 0}</td>
                                        <td>{local.currency === "USD" ? "$ " + (parseInt(local.charge_40hc) > 0 ? parseInt(this.state.booking.container_40hc) * parseInt(local.charge_40hc) : 0) : ""}</td>
                                        <td>{local.currency === "INR" ? "(₹) " + (parseInt(local.charge_40hc) > 0 ? parseInt(this.state.booking.container_40hc) * parseInt(local.charge_40hc) : 0) : ""}</td>
                                    </tr> : ""}

                                    {this.state.booking.container_40sr > 0 && local.show_on_quotation == true ?
                                    <tr>
                                        <td>{(this.state.booking.container_20ft <= 0) && (this.state.booking.container_40ft <= 0) ? (local.title ? local.title.name : "") : ""}</td>
                                        <td>Per Container</td>
                                        <td>40SR</td>
                                        <td>{this.state.booking.container_40sr}</td>
                                        <td>{local.currency === "INR" ? "(₹) " : "$ "}{parseInt(local.charge_40sr) > 0 ? local.charge_40sr : 0}</td>
                                        <td>{local.currency === "USD" ? "$ " + (parseInt(local.charge_40sr) > 0 ? parseInt(this.state.booking.container_40sr) * parseInt(local.charge_40sr) : 0) : ""}</td>
                                        <td>{local.currency === "INR" ? "(₹) " + (parseInt(local.charge_40sr) > 0 ? parseInt(this.state.booking.container_40sr) * parseInt(local.charge_40sr) : 0) : ""}</td>
                                    </tr> : ""}
                            </Fragment>;
                            local_charges.push(charge);

                        } else {
                            if(local.show_on_quotation == true){
                            
                                charge = <tr key={count}>
                                    <td><b>{local.title ? local.title.name : ""}</b></td>
                                    <td>Per Document</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td>{local.currency === "USD" ? "$ " + local.charge_per_booking_sales : ""}</td>
                                    <td>{local.currency === "INR" ? (<i className="fa fa-rupee lh-32px"/>) : ""}{local.currency === "INR" ? local.charge_per_booking_sales : ""}</td>
                                </tr>;
                                local_charges.push(charge);
                            }
                        }
                    }
                }
            }
            local_charges.push(<tr>
                    <td><b>Miscellaneous </b></td>
                    <td>Per Document</td>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td><i className="fa fa-rupee lh-32px"/>{misc_charge}</td>
                </tr>
            );
        }

        let freightCharges = [];

        let freight_charges = {
            'frt_': 'Freight Charges',
            'caf_': 'CAF Charges',
            'baf_': 'BAF Charges',
            'pss_': 'PSS Charges',
            'ams_': 'AMS Charges',
            'gri_': 'GRI Charges',
            'imp_': 'Inland Haulage Import/charges',
            'isps_': 'ISPS Charges',
            'eff_': 'EFF Charges'
        };
        let document_freight_charges = {'ens': 'ENS Charges'};

        if (this.state.booking) {
            for (let freight_charge in freight_charges) {

                if (this.state.booking.container_20ft > 0 && parseFloat(this.state.booking.freight_charges[freight_charge + '20']) > 0) {
                    freightCharges.push(<tr>
                        <td><b>{freight_charges[freight_charge]}</b></td>
                        <td>Per container</td>
                        <td>{this.state.booking.container_type_id === REFRIGERATED ? "20 RF" : "20 FT"}</td>
                        <td>{this.state.booking.container_20ft}</td>
                        {freight_charge === "frt_" ?
                            <td>$ {parseFloat(this.state.booking.freight_charges[freight_charge + '20_sales']) + parseFloat(this.state.booking.freight_charges['misc_20'])}</td>
                            :
                            <td>$ {parseFloat(this.state.booking.freight_charges[freight_charge + '20_sales'])}</td>
                        }
                        {freight_charge === "frt_" ?
                            <td> $ {(parseFloat(this.state.booking.freight_charges[freight_charge + '20_sales']) + parseFloat(this.state.booking.freight_charges['misc_20'])) * parseInt(this.state.booking.container_20ft)}</td>
                            :
                            <td> $ {(parseFloat(this.state.booking.freight_charges[freight_charge + '20_sales'])) * parseInt(this.state.booking.container_20ft)}</td>
                        }
                        <td className="change-td"/>
                    </tr>)
                }

                if (this.state.booking.container_40ft > 0 && parseFloat(this.state.booking.freight_charges[freight_charge + '40']) > 0) {
                    freightCharges.push(<tr>
                        <td>
                            <b>{(this.state.booking.container_20ft > 0 && parseFloat(this.state.booking.freight_charges[freight_charge + '20']) > 0) ? '' : freight_charges[freight_charge]}</b>
                        </td>
                        <td>Per container</td>
                        <td>40FT</td>
                        <td>{this.state.booking.container_40ft}</td>
                        {freight_charge === "frt_" ?
                            <td>$ {parseFloat(this.state.booking.freight_charges[freight_charge + '40_sales']) + parseFloat(this.state.booking.freight_charges['misc_40'])}</td>
                            :
                            <td>$ {parseFloat(this.state.booking.freight_charges[freight_charge + '40_sales'])}</td>
                        }
                        {freight_charge === "frt_" ?
                            <td> $ {(parseFloat(this.state.booking.freight_charges[freight_charge + '40_sales']) + parseFloat(this.state.booking.freight_charges['misc_40'])) * parseInt(this.state.booking.container_40ft)}</td>
                            :
                            <td> $ {(parseFloat(this.state.booking.freight_charges[freight_charge + '40_sales'])) * parseInt(this.state.booking.container_40ft)}</td>
                        }
                        <td className="change-td"/>
                    </tr>)
                }
                if (this.state.booking.container_40hc > 0 && parseFloat(this.state.booking.freight_charges[freight_charge + '40hc']) > 0) {
                    freightCharges.push(<tr>
                        <td>
                            <b>{(this.state.booking.container_20ft > 0 && parseFloat(this.state.booking.freight_charges[freight_charge + '20']) > 0) || (this.state.booking.container_40ft > 0 && parseFloat(this.state.booking.freight_charges[freight_charge + '40']) > 0) ? "" : freight_charges[freight_charge]}</b>
                        </td>
                        <td>Per container</td>
                        <td>{this.state.booking.container_type_id === REFRIGERATED ? "40 RF HC" : "40 HC"}</td>
                        <td>{this.state.booking.container_40hc}</td>
                        {freight_charge === "frt_" ?
                            <td>$ {parseFloat(this.state.booking.freight_charges[freight_charge + '40hc_sales']) + parseFloat(this.state.booking.freight_charges['misc_40hc'])}</td>
                            :
                            <td>$ {parseFloat(this.state.booking.freight_charges[freight_charge + '40hc_sales'])}</td>
                        }
                        {freight_charge === "frt_" ?
                            <td> $ {(parseFloat(this.state.booking.freight_charges[freight_charge + '40hc_sales']) + parseFloat(this.state.booking.freight_charges['misc_40hc'])) * parseInt(this.state.booking.container_40hc)}</td>
                            :
                            <td> $ {(parseFloat(this.state.booking.freight_charges[freight_charge + '40hc_sales'])) * parseInt(this.state.booking.container_40hc)}</td>
                        }
                        <td className="change-td"/>
                    </tr>)
                }
                if (this.state.booking.container_40sr > 0 && parseFloat(this.state.booking.freight_charges[freight_charge + '40sr']) > 0) {
                    freightCharges.push(<tr>
                        <td>
                            <b>{(this.state.booking.container_20ft > 0 && parseFloat(this.state.booking.freight_charges[freight_charge + '20']) > 0) || (this.state.booking.container_40ft > 0 && parseFloat(this.state.booking.freight_charges[freight_charge + '40']) > 0) ? "" : freight_charges[freight_charge]}</b>
                        </td>
                        <td>Per container</td>
                        <td>40 SR</td>
                        <td>{this.state.booking.container_40sr}</td>
                        {freight_charge === "frt_" ?
                            <td>$ {parseFloat(this.state.booking.freight_charges[freight_charge + '40sr_sales']) + parseFloat(this.state.booking.freight_charges['misc_40hc'])}</td>
                            :
                            <td>$ {parseFloat(this.state.booking.freight_charges[freight_charge + '40sr_sales'])}</td>
                        }
                        {freight_charge === "frt_" ?
                            <td> $ {(parseFloat(this.state.booking.freight_charges[freight_charge + '40sr_sales']) + parseFloat(this.state.booking.freight_charges['misc_40hc'])) * parseInt(this.state.booking.container_40sr)}</td>
                            :
                            <td> $ {(parseFloat(this.state.booking.freight_charges[freight_charge + '40sr_sales'])) * parseInt(this.state.booking.container_40sr)}</td>
                        }
                        <td className="change-td"/>
                    </tr>)
                }
            }
            for (let document_freight_charge in document_freight_charges) {
                if (this.state.booking.freight_charges && this.state.booking.freight_charges[document_freight_charge]) {
                    freightCharges.push(
                        <tr>
                            <td>
                                <b>{document_freight_charges[document_freight_charge]}</b>
                            </td>
                            <td>Per Document</td>
                            <td/>
                            <td/>
                            <td/>
                            <td>$ {this.state.booking.freight_charges[document_freight_charge]}</td>
                            <td/>
                        </tr>
                    );
                }
            }
        }

        const croFiles = [];
        let documents = this.state.booking ? this.state.booking.documents : null;
        let haz_documents = this.state.booking ? this.state.booking.haz_documents : null;
        if (documents != null && documents.cro) {
            for (let key in this.state.booking.documents.cro) {
                var croFileName = documents.cro[key].split("/");
                croFiles.push(
                    <li key={key}>
                        <a href={AWS_S3_URL + documents.cro[key]} target="_blank" rel="noopener noreferrer">
                            <img src={PdfIcon} alt={""}/>{croFileName[croFileName.length - 1]}
                        </a>
                    </li>);
            }
        }

        if (documents != null && documents.other) {
            for (let key in this.state.booking.documents.other) {
                var croFileName = documents.other[key].split("/");
                croFiles.push(
                    <li key={key}>
                        <a href={AWS_S3_URL + documents.other[key]} target="_blank" rel="noopener noreferrer">
                            <img src={PdfIcon} alt={""}/>{croFileName[croFileName.length - 1]}
                        </a>
                    </li>);
            }
        }
        const shippingFiles = [];
        if (documents != null && documents.shipping_bill) {
            for (let key in this.state.booking.documents.shipping_bill) {
                var shippingFileName = documents.shipping_bill[key].split("/");
                shippingFiles.push(
                    <li key={key}>
                        <a href={AWS_S3_URL + documents.shipping_bill[key]} target="_blank" rel="noopener noreferrer">
                            <img src={PdfIcon} alt={""}/>{shippingFileName[shippingFileName.length - 1]}
                        </a>
                        <i onClick={() => this.deleteShippingBillDoc(key)}
                           className="fa fa-times color-changes"/>
                    </li>
                );
            }
        }
        const hazFiles = [];
        if (haz_documents != null) {
            for (let key in haz_documents) {
                var hazFileName = haz_documents[key].split("/");
                hazFiles.push(
                    <li key={key}>
                        <a href={AWS_S3_URL + haz_documents[key]} target="_blank" rel="noopener noreferrer">
                            <img src={PdfIcon} alt={""}/>{hazFileName[hazFileName.length - 1]}
                        </a>
                    </li>
                );
            }
        }




        return (
            <Fragment>
                {(this.state.loading || this.state.isPackageTypeLoading) ?
                    <Fragment>
                        <div className="booking-history-heading no-booking">
                            Loading ...
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <Modal modalClassName="full-screen-modal" isOpen={this.state.showModal}
                               toggle={this.toggleVgmModal}>

                            <ModalHeader className="vgm-modal-title"
                                         toggle={this.toggleVgmModal}>{this.state.modalTitle}
                            </ModalHeader>
                            <ModalBody>

                                {this.state.modalMode === VGM_MODE ?

                                    (this.state.selectedContainer && this.state.selectedContainer.bl && this.state.selectedContainer.bl.bl_issued === 1) ?

                                        <VgmView booking={this.state.booking}
                                                 containerId={this.state.containerId}
                                                 packageType={this.state.packageType}/> :
                                        <VgmForm booking={this.state.booking}
                                                 auth={this.props.auth}
                                                 containerId={this.state.containerId}
                                                 packageType={this.state.packageType}
                                                 shipperList={this.state.shipperList}
                                                 updateBooking={this.updateBooking}
                                                 onVgmSubmit={this.onVgmSubmit}
                                                 vgmButtonLoading={this.state.vgmButtonLoading}
                                                 vgmSaveButtonLoading={this.state.vgmSaveButtonLoading}
                                                 downloadVgmPdf={this.downloadVgmPdf}/>
                                    : null}

                            </ModalBody>
                        </Modal>
                        {this.state.success_message ?
                            <UncontrolledAlert color="info" className={'mt-20px'}>
                                {this.state.success_message}
                            </UncontrolledAlert>
                            :
                            null}

                        <div className="booking-history-box">
                            <div className="booking-history-heading">
                                <h6>Shipment Ref No <b>{this.state.booking.book_id}</b></h6>
                                <h5>{this.state.booking_note}</h5>
                            </div>
                        </div>
                        <div className="full-list-booking p-0px">
                            <div className="update-booking">
                                <Row>
                                    <Col lg="3" sm="12">
                                        <div className="booing-status">
                                            <ul>
                                                <li>
                                                    <p>Booking Status</p>
                                                    <b>{bookingStatus[this.state.booking.booking_status]}</b>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col lg="9" sm="12">
                                        <div className="bl-btn">
                                            <ul>
                                                {(this.state.booking.booking_status >= CRO_RECEIVED && this.state.booking.booking_status < USER_ACCEPTED) ?
                                                    <li>
                                                        <button onClick={this.scrollToUpdateVgm}>Update VGM</button>
                                                    </li> : null}
                                                {(this.state.booking.booking_status >= CRO_RECEIVED && this.state.booking.booking_status < USER_ACCEPTED) ?
                                                    <li>
                                                        <button onClick={this.scrollToShippingBill}>
                                                            Shipping Bill
                                                        </button>
                                                    </li> : null}

                                                {this.state.show_prices === 1 ?
                                                    <li>
                                                        <button onClick={this.toggleopenPriceBreakdownModal}>
                                                            View
                                                            Price
                                                            Breakdown
                                                        </button>
                                                    </li> : null}

                                                {this.state.showForm13 ?
                                                    <li>
                                                        <button onClick={this.toggleForm13Modal}>
                                                            <ButtonLoader
                                                                isLoading={this.state.form13DataButtonLoading}
                                                                label={"Form 13"}/>
                                                        </button>
                                                    </li> : null}

                                                <li>
                                                    <button onClick={this.toggleDocumentsModal}>
                                                        Documents
                                                    </button>
                                                </li>

                                                {(this.state.booking.booking_status <= CRO_RECEIVED && this.state.booking.booking_status >= BOOKING_REQUESTED) ?
                                                    <li>
                                                        <button className="cancel-booking"
                                                                onClick={this.toggleCancelBooking}>
                                                            Cancel Booking
                                                        </button>
                                                    </li> : null
                                                }

                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="table-booking-list">
                                <Row>
                                    <Col lg="12" sm="12">
                                        <div className="confirm-booking-table mt-0px">
                                            <Table>
                                                <thead>
                                                <tr>
                                                    <th>Departure</th>
                                                    <th/>
                                                    <th>Arrival</th>
                                                    <th/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr className="tr-border-btm">
                                                    <td width="26%">
                                                        <span>{this.state.booking.pol ? this.state.booking.pol.country.name : ""}</span>
                                                        <p className="port-name-chng">
                                                            {(this.state.booking.pol) ? this.state.booking.pol.un_code : ""}&nbsp;-&nbsp;
                                                            {(this.state.booking.pol) ? this.state.booking.pol.port_name : ""}
                                                        </p>
                                                    </td>
                                                    <td width="15%">
                                                        <img src={bokingconfirmboat} alt="transit-time"/>
                                                        <p>{this.state.booking.transit_time ? this.state.booking.transit_time + ' Days' : ""} </p>
                                                    </td>
                                                    <td width="26%">
                                                        <span>{(this.state.booking.pod) ? this.state.booking.pod.country.name : ""}</span>
                                                        <p className="port-name-chng">
                                                            {(this.state.booking.pod) ? this.state.booking.pod.un_code : ""}&nbsp;-&nbsp;
                                                            {(this.state.booking.pod) ? this.state.booking.pod.port_name : ""}
                                                        </p>
                                                    </td>
                                                    <td width="30%" rowSpan="2" className="bookoing-right-table">
                                                        <table className="booking-date-table">
                                                            <tbody>
                                                            <tr>
                                                                <td>Rate Quotation Date</td>
                                                                <td className="date-right">
                                                                    {/* {this.state.booking.created_at.format('HH:mm')},&nbsp;*/}
                                                                    <b>{this.state.booking.created_at.format('DD MMM Y')}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="cut-off">Cutoff/ETA at POL</td>
                                                                <td className="date-right">
                                                                    {this.state.booking.cut_off_date ? this.state.booking.cut_off_date.format('dddd') : ""}
                                                                    <b>{this.state.booking.cut_off_date ? this.state.booking.cut_off_date.format('DD MMM Y') : "NA"}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>ETD at POL</td>
                                                                <td className="date-right">
                                                                    <b>{getFormattedDate(this.state.booking.etd_pol,"dddd, DD MMM Y")}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>ETA at POD</td>
                                                                <td className="date-right">
                                                                    <b>{getFormattedDate(this.state.booking.eta_pod,"dddd, DD MMM Y")}</b>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>

                                                    </td>
                                                </tr>
                                                <tr className="tr-border-btm">
                                                    <td colSpan="3">
                                                        <div className="images-fix">
                                                            <img src={shipLineIcon} alt=""/>
                                                        </div>
                                                        <p>{this.state.booking.ship_line ? this.state.booking.ship_line.carrier_name : ""}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4" className="tr-new-table">
                                                        <table className="table-booking-inn">
                                                            <tbody>

                                                            {this.state.booking.container_20ft > 0 ?
                                                                <tr className="tr-border-btm">
                                                                    <td className="ft-count">
                                                                        <b>{this.state.booking.container_type_id === REFRIGERATED ? '20 RF' : this.state.booking.container_type_id === OPENTOP ? '20 OT' : this.state.booking.container_type_id === FLATRACK ? '20 FR' : '20 FT'}</b>
                                                                    </td>
                                                                    <td colSpan="4">
                                                                        <i className="fa fa-times color-changes"/>
                                                                        <b>{this.state.booking.container_20ft}</b>
                                                                    </td>
                                                                    {this.state.show_prices === 1 ?
                                                                        <td>USD { this.state.booking.freight_charges.frt_20_sales == undefined ? this.state.booking.freight_charges.frt_20 : this.state.booking.freight_charges.frt_20_sales }</td> :
                                                                        <td/>}

                                                                </tr> : null}
                                                            
                                                            {this.state.booking.container_40ft > 0 ?
                                                                <tr className="tr-border-btm">
                                                                    <td className="ft-count">
                                                                        <b>{ this.state.booking.container_type_id === OPENTOP ? '40 OT' : this.state.booking.container_type_id === FLATRACK ? '40 FR' : '40 FT' }</b>
                                                                    </td>
                                                                    <td colSpan="4">
                                                                        <i className="fa fa-times color-changes"/>
                                                                        <b>{this.state.booking.container_40ft}</b>
                                                                    </td>
                                                                    {this.state.show_prices === 1 ?
                                                                        <td>USD { this.state.booking.freight_charges.frt_40_sales == undefined ? this.state.booking.freight_charges.frt_40 : this.state.booking.freight_charges.frt_40_sales }</td> :
                                                                        <td/>}
                                                                </tr>
                                                                : null}

                                                            {this.state.booking.container_40hc > 0 ?
                                                                <tr className="tr-border-btm">
                                                                    <td className="ft-count">
                                                                        <b>{this.state.booking.container_type_id === REFRIGERATED ? '40 RF HC' : this.state.booking.container_type_id === OPENTOP ? '40 OT HC' : this.state.booking.container_type_id === FLATRACK ? '40 FL HC ' : '40 HC'}</b>
                                                                    </td>
                                                                    <td colSpan="4">
                                                                        <i className="fa fa-times color-changes"/>
                                                                        <b>{this.state.booking.container_40hc}</b>
                                                                    </td>
                                                                    {this.state.show_prices === 1 ?
                                                                        <td>USD { this.state.booking.freight_charges.frt_40hc_sales == undefined ? this.state.booking.freight_charges.frt_40hc : this.state.booking.freight_charges.frt_40hc_sales }</td> :
                                                                        <td/>}

                                                                </tr> : null}
                                                                { console.log('sssssq') }    
                                                            { console.log(this.state.booking) }
                                                            {this.state.booking.container_40sr > 0 ?
                                                            <tr className="tr-border-btm">
                                                                <td className="ft-count">
                                                                    <b>40 SR</b>
                                                                </td>
                                                                <td colSpan="4">
                                                                    <i className="fa fa-times color-changes"/>
                                                                    <b>{this.state.booking.container_40sr}</b>
                                                                </td>
                                                                {this.state.show_prices === 1 ?
                                                                    <td>USD { this.state.booking.freight_charges.frt_40sr_sales == undefined ? this.state.booking.freight_charges.frt_40sr : this.state.booking.freight_charges.frt_40sr_sales }</td> :
                                                                    <td/>}

                                                            </tr> : null}

                                                            {(this.state.booking.is_haz) ?
                                                                <tr className="tr-border-btm">
                                                                    <td colSpan="2" className="hozara-dous">
                                                                        <b>Hazardous / Dangerous Goods
                                                                        </b>
                                                                    </td>
                                                                    <td colSpan="3" className="class-select">
                                                                        <p className="class-new-p">
                                                                            <img className="class-icon" src={ClassIcon}
                                                                                 alt={""}/>
                                                                            Class <b>{this.state.booking && this.state.booking.imo_class ? "(" + this.state.booking.imo_class.value + ") " + this.state.booking.imo_class.description : ""}</b>
                                                                        </p>
                                                                    </td>
                                                                    {this.state.show_prices === 1 ?
                                                                        <td>
                                                                            USD {this.state.booking.haz_charges}
                                                                        </td> : <td/>}
                                                                </tr> : null
                                                            }
                                                            {this.state.booking.promocode_charges && this.state.show_prices === 1 ?
                                                                <Fragment>
                                                                    <tr className="tr-border-btm">
                                                                        <td colSpan="2" className="total">
                                                                            <b>Applied Promo Code
                                                                            </b>
                                                                        </td>
                                                                        <td className="new-table-td" colSpan="3"/>
                                                                        <td className="total-amount">
                                                                            {this.state.booking.promocode_charges ? this.state.booking.promocode_charges.title : ""}
                                                                        </td>
                                                                    </tr>

                                                                    {parseInt(this.state.booking.discount) > 0 ?
                                                                        <tr>
                                                                            <td colSpan="2" className="discount">
                                                                                <b>Discount
                                                                                </b>
                                                                            </td>
                                                                            <td className="new-table-td" colSpan="3"/>
                                                                            <td className="discount-amount">
                                                                                USD {this.state.booking.discount}
                                                                            </td>
                                                                        </tr> : null
                                                                    }
                                                                </Fragment> : null}

                                                            {this.state.show_prices === 1 ?
                                                                <tr className="tr-border-btm">
                                                                    <td colSpan="2" className="total">
                                                                        <b>Total Charges
                                                                        </b>
                                                                    </td>
                                                                    <td className="new-table-td" colSpan="3"/>
                                                                    <td className="total-amount">
                                                                        USD <b>{
                                                                        ((this.state.booking.container_20ft > 0) ? (this.state.booking.freight_charges['frt_20_sales'] == undefined ? this.state.booking.freight_charges['frt_20'] * this.state.booking.container_20ft : this.state.booking.freight_charges['frt_20_sales'] * this.state.booking.container_20ft ) : 0 ) +
                                                                        ((this.state.booking.container_40ft > 0) ? (this.state.booking.freight_charges['frt_40_sales'] == undefined ? this.state.booking.freight_charges['frt_40'] * this.state.booking.container_40ft : this.state.booking.freight_charges['frt_40_sales'] * this.state.booking.container_40ft ) : 0 ) + 
                                                                        ((this.state.booking.container_40hc > 0) ? (this.state.booking.freight_charges['frt_40hc_sales'] == undefined ? this.state.booking.freight_charges['frt_40hc'] * this.state.booking.container_40hc : this.state.booking.freight_charges['frt_40hc_sales'] * this.state.booking.container_40hc ) : 0 ) +
                                                                        ((this.state.booking.container_40sr > 0) ? (this.state.booking.freight_charges['frt_40sr_sales'] == undefined ? this.state.booking.freight_charges['frt_40sr'] * this.state.booking.container_40sr : this.state.booking.freight_charges['frt_40sr_sales'] * this.state.booking.container_40sr ) : 0 ) - parseFloat(this.state.booking.discount)}</b>
                                                                    </td>
                                                                </tr> : <tr/>}

                                                            <tr className="tr-border-btm">
                                                                <td colSpan="2" className="total">
                                                                    <b>Freight Term
                                                                    </b>
                                                                </td>
                                                                <td className="new-table-td" colSpan="3"/>
                                                                <td className="discount-amount new-table-td">
                                                                    {this.state.booking.freight_terms === 1 ? 'Prepaid' : 'Collect'}
                                                                </td>
                                                            </tr>
                                                            <tr className="tr-border-btm">
                                                                <td colSpan="2" className="total">
                                                                    <b>Commodity
                                                                    </b>
                                                                </td>
                                                                <td className="new-table-td" colSpan="3"/>
                                                                <td className="discount-amount new-table-td">
                                                                    {this.state.booking.commodity.title}
                                                                </td>
                                                            </tr>
                                                            {(this.state.booking.goods_description !== null && this.state.booking.goods_description !== "") ?
                                                                <tr>
                                                                    <td colSpan="6"
                                                                        className="goods_description-one-full">
                                                                        <table>
                                                                            <tbody>
                                                                            <tr>
                                                                                <td colSpan="1"
                                                                                    className="goods_description-one">
                                                                                    <b>Goods Description
                                                                                    </b>
                                                                                </td>
                                                                                <td colSpan="1"
                                                                                    className="goods_description">
                                                                                    {this.state.booking.goods_description}
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>

                                                                </tr> : null
                                                            }

                                                            {this.state.booking && this.state.booking.remarks ?
                                                                <tr>
                                                                    <td colSpan="6"
                                                                        className="goods_description-one-full">
                                                                        <table>
                                                                            <tbody>
                                                                            <tr>
                                                                                <td colSpan="1"
                                                                                    className="goods_description-one">
                                                                                    <b>Remarks
                                                                                    </b>
                                                                                </td>
                                                                                <td colSpan="1"
                                                                                    className="goods_description">
                                                                                    {this.state.booking.remarks}
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>

                                                                </tr> : null

                                                            }

                                                            {(this.state.booking.cancellation_remark !== null && this.state.booking.cancellation_remark !== "") ?
                                                                <tr>
                                                                    <td colSpan="6"
                                                                        className="goods_description-one-full">
                                                                        <table>
                                                                            <tbody>
                                                                            <tr>
                                                                                <td colSpan="1"
                                                                                    className="goods_description-one">
                                                                                    <b>Cancellation Remark
                                                                                    </b>
                                                                                </td>
                                                                                <td colSpan="1"
                                                                                    className="goods_description">
                                                                                    {this.state.booking.cancellation_remark}
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>

                                                                </tr> : null
                                                            }

                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="quote-includes">
                                <Link to="#">Quote Includes</Link>
                                <ul>
                                    <li><i className="fa fa-check"/> Sea Freight.</li>
                                    <li><i className="fa fa-check"/>Bill of Lading Fees, AMS, ENS, POL THC
                                        etc.
                                    </li>
                                    {(this.state.booking.is_haz) ?
                                        <li>
                                            <i className="fa fa-check"/>Hazardous / Dangerous
                                        </li> : ""}
                                </ul>
                            </div>
                            <div className="quote-excludes">
                                <Link to="#">Quote Excludes</Link>
                                <ul>
                                    {(!this.state.booking.is_haz) ?
                                        <li>
                                            <i className="fa fa-times color-changes"/>Hazardous / Dangerous Goods
                                        </li> : ""
                                    }
                                    <li><i className="fa fa-times color-changes"/>BL Telex Fees</li>
                                    <li><i className="fa fa-times color-changes"/>Cargo Insurance</li>
                                    <li><i className="fa fa-times color-changes"/>All detention/storage/demurrage
                                        charges at port(s)
                                    </li>
                                    <li><i className="fa fa-times color-changes"/>Any Customs Clearence charges (at POL
                                        & POD)
                                    </li>
                                    <li><i className="fa fa-times color-changes"/>All Ex-Works Logistics Charges at POL
                                        on FOT Terms.
                                    </li>

                                    <li><i className="fa fa-times color-changes"/>POD Local Charges (Chargeable to
                                        Consignee)
                                    </li>
                                </ul>
                            </div>

                            {this.state.booking.cro_details && this.state.booking.booking_status > CRO_REQUESTED ?
                                <div className="cro-dive">
                                    <h5>cro</h5>

                                    <div className="cro-dive-box">
                                        <h1>Your booking has been approved by shipline.</h1>
                                    </div>
                                    <div className="cro-dive-box-full">
                                        <div className="cro-dive-box">
                                            <h2>Details</h2>
                                        </div>
                                        <Row>
                                            <Col lg="4" sm="4">
                                                <div className="cro-dive-box-details">
                                                    <p>Vessel</p>
                                                    <b>{this.state.booking ? this.state.booking.vessel : ""}</b>
                                                </div>
                                            </Col>
                                            <Col lg="4" sm="4">
                                                <div className="cro-dive-box-details">
                                                    <p>Voyage</p>
                                                    <b>{this.state.booking ? this.state.booking.voyage : ""}</b>
                                                </div>
                                            </Col>

                                            <Col lg="4" sm="4">
                                                <div className="cro-dive-box-details">
                                                    <p>Likely Gate Opening</p>
                                                    {/*{console.log('dfdf',this.state.booking.cro_details.gate_opening, getFormattedDate(this.state.booking.cro_details.gate_opening, 'DD-MM-YYYY h:mm:ss'))}*/}
                                                    <b>{getFormattedDate(this.state.booking.cro_details.gate_opening, 'DD-MM-YYYY h:mm:ss')}</b>
                                                </div>
                                            </Col>

                                            <Col lg="4" sm="4">
                                                <div className="cro-dive-box-details new-mt">
                                                    <p>Terminal</p>
                                                    <b>{(this.state.booking && this.state.booking.terminal ? this.state.booking.terminal.name : "")}</b>
                                                </div>
                                            </Col>


                                            <Col lg="8" sm="8">
                                                <div className="cro-dive-box-details new-mt">
                                                    <p>Carrier</p>
                                                    <b>{(this.state.booking.cro_details ? this.state.booking.cro_details.carrier : "")}</b>
                                                </div>
                                            </Col>
                                        </Row>
                                        <ul className="cro-dive-box-view-date"> 
                                            <li>SI/ACD Cut 
                                                Off <b>{(this.state.booking.cro_details.si_acd_cut_off && this.state.booking.cro_details.si_acd_cut_off != "null") ? moment(this.state.booking.cro_details.si_acd_cut_off, 'DD-MM-YYYY h:mm:ss').format('DD-MM-YYYY h:mm:ss') : ""}</b>
                                            </li>
                                            <li> Port cut
                                                Off <b>{(this.state.booking.cro_details.port_cut_off && this.state.booking.cro_details.port_cut_off != "null") ? moment(this.state.booking.cro_details.port_cut_off, 'DD-MM-YYYY h:mm:ss').format('DD-MM-YYYY h:mm:ss') : ""} </b>
                                            </li>
                                            <li>Shipping Bill Cut
                                                off <b>{(this.state.booking.cro_details.shipping_bill_cut_off && this.state.booking.cro_details.shipping_bill_cut_off != "null" ) ? moment(this.state.booking.cro_details.shipping_bill_cut_off, 'DD-MM-YYYY h:mm:ss').format('DD-MM-YYYY h:mm:ss') : ""}</b>
                                            </li>
                                            <li>VGM Cut
                                                off <b>{(this.state.booking.cro_details.vgm_cut_off && this.state.booking.cro_details.vgm_cut_off != "null" ) ? moment(this.state.booking.cro_details.vgm_cut_off, 'DD-MM-YYYY h:mm:ss').format('DD-MM-YYYY h:mm:ss') : ""}</b>
                                            </li>
                                        </ul>

                                        <div className="documents">
                                            <h1>CRO Documents (Max upload size is 6 MB)</h1>

                                            <ul>
                                                {croFiles}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                :
                                ""}

                            {this.state.booking.booking_status >= 3 && this.state.booking.booking_status < USER_ACCEPTED ?
                                <Fragment>
                                    <div className="cro-dive vgm-div" id="upload_vgm_div">
                                        <h5>VGM</h5>
                                        <p className={'text-red'}> {this.state.showVgmResponse}</p>
                                        <div className="cro-dive-box">
                                            <h1>{this.state.vgmBookingNote}</h1>
                                        </div>
                                        <div className="cro-dive-box-full">
                                            {this.state.booking.containers.map((item, key) =>

                                                <Row key={key}>
                                                    <Col lg="5" sm="5">
                                                        <div className="vgm-div-box-left">
                                                            <h2>
                                                                {/* Container {key + 1} - <b>{(item.container_size === "40hc") ? (this.state.booking.container_type_id === REFRIGERATED ? '40 RF HC' : this.state.booking.container_type_id === OPENTOP ? '40 OT' : this.state.booking.container_type_id === FLATRACK ? '40 FL HC' :'40 HC') : item.container_size + (this.state.booking.container_type_id === REFRIGERATED ? ' RF' : ' FT')}</b> {item.container_number ? '- ' + item.container_number : ""} */}
                                                                Container {key + 1} - 
                                                                <b>  
                                                                    {
                                                                        (item.container_size === "40hc") ? 
                                                                            (this.state.booking.container_type_id === REFRIGERATED ? 
                                                                                '40 RF HC' : 
                                                                                this.state.booking.container_type_id === OPENTOP ?
                                                                                 '40 OT HC' :
                                                                                  this.state.booking.container_type_id === FLATRACK ?
                                                                                   '40 FL HC' :
                                                                                   '40 HC') : 
                                                                                   (item.container_size === "40") ? 
                                                                                    (this.state.booking.container_type_id === OPENTOP ? 
                                                                                        '40 OT' : 
                                                                                        this.state.booking.container_type_id === FLATRACK ?
                                                                                         '40 FR' :
                                                                                          '40 FT') : 
                                                                                          (item.container_size === "20") ?
                                                                                          (this.state.booking.container_type_id === REFRIGERATED ?
                                                                                            '20 RF':
                                                                                            this.state.booking.container_type_id === OPENTOP ? 
                                                                                            '20 OT' : 
                                                                                            this.state.booking.container_type_id === FLATRACK ?
                                                                                             '20 FR' :
                                                                                              '20 FT'):
                                                                                              (item.container_size === "40sr") ? '40 SR' : '' }</b> {item.container_number ? '- ' + item.container_number : ""}
                                                            </h2>
                                                            {item.odex_vgm_container_status}
                                                        </div>

                                                    </Col>
                                                    <Col lg="7" sm="7">
                                                        <div className="vgm-div-box-right">
                                                            <ul>
                                                                {(item.vgm_done_at !== null) ?
                                                                    <li>
                                                                        <a onClick={() => this.downloadVgmPdf(item, item.container_number)}><img
                                                                            src={PdfIcon} alt={""}/>Download VGM PDF</a>
                                                                    </li> : ""
                                                                }
                                                                <li className="border-vgm-next">
                                                                    <button
                                                                        onClick={() => this.toggleVgmModal(item, key)}>{(item.bl && item.bl.bl_issued === 1) ? "View" : "Upload"}
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            )}
                                        </div>
                                    </div>
                                    {this.state.showDraftBlLink ?
                                        <div className="kindly-text">
                                            <p>Kindly <button className={'btn btn-xs btn-primary brd-r-25px-p-4-10px'} onClick={()=>this.props.history.push('/bl/draft-bl', {
                                                container_type: this.state.booking.container_type_id,
                                                // vendor: this.state.booking.pol_agent_company_id,
                                                pol: this.state.booking.pol,
                                                pod: this.state.booking.pod,
                                                shipline: this.state.booking.cro_details ? this.state.booking.cro_details.carrier : '',
                                                commodity: this.state.booking.commodity,
                                                freight_terms: this.state.booking.freight_terms
                                            })}>Click here</button> to Draft BL</p>
                                        </div> : null
                                    }

                                </Fragment> : ""
                            }

                            {this.state.booking.booking_status >= CRO_RECEIVED && this.state.booking.booking_status !== BOOKING_CANCELLED ?
                                <div className="cro-dive shipping-bill" id="shipping_bill_div">
                                    <h5>Shipping Bill </h5>
                                    <div
                                        className={(!this.state.shippingBillStatus ? " rounded " : "") + " cro-dive-box"}>
                                        {/*<h1>
                                            {(this.state.shippingBillStatus && this.state.booking.cro_details) ?
                                                "Please upload the Shipping Bill by " + moment(this.state.booking.cro_details.shipping_bill_cut_off, 'DD-MM-YYYY h:mm:ss').format('LLLL') :
                                                'Shipping Bill cut off date has been expired'}
                                        </h1>*/}
                                    </div>
                                    <div className="cro-dive-box-full">
                                        {this.state.responseStatus}
                                        {this.state.ShippingBillError}
                                        <div className="vgm-div-box-right">
                                            <Row>
                                                <Col lg="8" sm="8">
                                                    <ul>
                                                        {shippingFiles}
                                                    </ul>
                                                </Col>
                                                <Col lg="4" sm="4" className={'border-left-1px-lightblue'}>
                                                    <div className="upload-2-files">
                                                        <div className="upload-btn-wrapper">
                                                            <button className="btn">
                                                                <i className="fa fa-file-alt"/> Select File 
                                                                <span> (Max upload file size is 6 MB)</span>
                                                            </button>
                                                            <input type="file" name="shipping_bill"
                                                                   id="shipping_bill"
                                                                   onChange={() => this.getShippingBillFile('shipping_bill', 'ShippingBillDoc', 'ShippingBillName')}/>
                                                            <p className={'fs-13px'}>{this.state.ShippingBillName}</p>
                                                        </div>
                                                        <button className="upload-button"
                                                                onClick={() => this.uploadShippingBill('shipping_bill', 'ShippingBillDoc', 'ShippingBillName', 'ShippingBillUploading')}>
                                                            <ButtonLoader
                                                                isLoading={this.state.ShippingBillUploading}
                                                                label={"Upload"}/> {/* <i className="fa fa-upload white"/> */}

                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                : ''}

                            <br/>

                            {(this.state.booking.is_haz) ?
                                <div className="cro-dive shipping-bill" id="shipping_bill_div">
                                    <h5>Haz Documents</h5>
                                    <div
                                        className={"cro-dive-box"}>
                                        <h1>
                                        </h1>
                                    </div>
                                    <div className="cro-dive-box-full">
                                        <div className="vgm-div-box-right">
                                            <Row>
                                                <Col lg="8" sm="8">
                                                    <ul>
                                                        {hazFiles}
                                                    </ul>
                                                </Col>
                                                <Col lg="4" sm="4" className={'border-left-1px-lightblue'}>
                                                    <div className="upload-2-files">
                                                        <div className="upload-btn-wrapper">
                                                            <button className="btn">
                                                                <i className="fa fa-file-alt"/> Select File
                                                            </button>
                                                            <input type="file" name="haz_document"
                                                                   id="haz_document"
                                                                   onChange={() => this.getShippingBillFile('haz_document', 'hazDocument', 'hazDocumentName')}/>
                                                            <p className={'fs-13px'}>{this.state.hazDocumentName}</p>
                                                        </div>
                                                        <button className="upload-button"
                                                                onClick={() => this.uploadShippingBill('haz_document', 'hazDocument', 'hazDocumentName', 'hazDocumentUploading')}>
                                                            <ButtonLoader
                                                                isLoading={this.state.hazDocumentUploading}
                                                                label={"Upload"}/> {/* <i className="fa fa-upload white"/> */}

                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div> : null
                            }

                            <br/>
                            {this.state.booking.form13 && this.state.booking.containers.length > 0 ?
                                <Fragment>
                                    <div className="cro-dive vgm-div" id="upload_vgm_div">
                                        <h5>Form 13</h5>
                                        <div className={"cro-dive-box"}>
                                        </div>
                                        <div className="cro-dive-box-full">
                                            {this.state.booking.containers.map((container, key) =>
                                                (container.odex_form13_container_status ?
                                                    <Row key={key}>
                                                        <Col lg="5" sm="5">
                                                            <div className="vgm-div-box-left">
                                                                <h2>
                                                                    {container.container_number}
                                                                </h2>
                                                            </div>

                                                        </Col>
                                                        <Col lg="7" sm="7">
                                                            <div className="vgm-div-box-right">
                                                                <ul>
                                                                    {(container.form13_documents !== null) ?
                                                                        container.form13_documents.map((doc) =>
                                                                            <li>
                                                                                <a href={AWS_S3_URL + doc.file}><img
                                                                                    src={PdfIcon} alt={""}
                                                                                    target="_blank"/>Download
                                                                                    Form13 PDF</a>
                                                                            </li>
                                                                        )
                                                                        : ""
                                                                    }
                                                                    <li>
                                                                        {container.odex_form13_container_status}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                    </Row> : null)
                                            )}
                                        </div>
                                    </div>

                                </Fragment> : ""
                            }


                            {/*<div className="cro-dive shipping-bill">
                                                            <h5>BL</h5>
                                                            <div className="cro-dive-box">
                                                            <h1><b>Draft Mode</b> - Please dcomplete the BL process by 06 Dec 2019, Saunday</h1>
                                                            </div>
                                                            <div className="cro-dive-box-full">
                                                            <div className="vgm-div-box-right">
                                                            <ul>
                                                            <li><Link to=""><img src={PdfIcon}/>cc4321_251119025912.pdf <i
                                                            className="far fa-edit"/></Link></li>
                                                            </ul>
                                                            </div>
                                                            </div>
                                                            </div>*/}


                            <div className="bookin-gquote-heading">
                                <hr/>
                                <h1>Terms & Conditions</h1>
                            </div>

                            <div className="term-conditions">
                                <h3>Quote Validity :</h3>
                                <p>The quotation is valid only for this transaction. It cannot be utilized for
                                    any
                                    other transaction or vessel date for the same port pair.</p>
                                <h3>Payment :</h3>
                                <p>On completion of the transaction, all charges to be paid in full on receipt
                                    of
                                    the invoice. The bill of lading would be released only thereafter. All
                                    prices
                                    are in USD and local currency as mentioned in the quotes should be credited to
                                    Freightgain Vendor account in
                                    full
                                    without deduction of any sender or receiver bank charges.</p>
                                {/*<h3>All-Inclusive Rates :</h3>*/}
                                {/*<p>All published rates are all-inclusive in nature for the services that have*/}
                                {/*    been*/}
                                {/*    chosen, otherwise, they are subject to additional cost for services*/}
                                {/*    additionally*/}
                                {/*    required.</p>*/}
                                <h3> Vessel and Cut-Off Schedule :</h3>
                                <p className="term-conditions-text">1. Vessel ETD, ETA are indicative in nature
                                    and
                                    subject to change without notice.</p>
                                <p>2. Cut-Off Dates to be met by the shipper as published unless otherwise
                                    communicated.</p>

                                <h3> Booking Terms :</h3>

                                <p className="term-conditions-text">
                                    # Please stuff cargo only as per the weight mentioned in the quotation. Please do
                                    not exceed payload.</p>
                                <p className="term-conditions-text">1. Hazardous / Dangerous Goods booking
                                    confirmation and loading is subject to approval by carrier.</p>
                                <p className="term-conditions-text"> # For Hazardous Cargo, Please ensure to
                                    paste
                                    stickers on all sides of the containers as per IMD guidelines.</p>
                                <p className="term-conditions-text"># For perishable cargo, Please ensure receiver is
                                    ready in all aspects to take the delivery of goods on arrival. Booking party |
                                    Shipper | Consignee orJointly and severally shall be responsible for delivery and
                                    consequences.</p>
                                <p className="term-conditions-text"># Palletized cargo or where wooden packing
                                    material is used needs to be fumigated as per Importing Country Specific
                                    Requirements.</p>
                                {/* <p className="term-conditions-text">2. All "Port" (POL & POD) quotations are
                                            subject
                                            to local charges, government taxes & documentation fees as prevalent.</p> */}

                                <p className="term-conditions-text"> 2. Please cover adequate insurance, bill of lading
                                    that covers protection of the cargo only at sea.</p>

                                <p className="term-conditions-text">3. All quotations are subject to
                                    local
                                    government taxes.</p>

                                <p className="term-conditions-text">4. Container detention free time is not
                                    mandatory and needs to be applied for and approved by the shipping line for
                                    each
                                    booking individually.</p>
                                {/* <p>2. Cut-Off Dates to be met by the shipper as
                                            published unless otherwise communicated.</p> */}


                                {/*<h3> Booking Terms :</h3>*/}
                                {/*<p className="term-conditions-text">1. Only one promo code can be applied per*/}
                                {/*    transaction and will only be valid for*/}
                                {/*    the booking being made.</p>*/}
                                {/*<p className="term-conditions-text">2. Promotional discounts will be applied in*/}
                                {/*    their entirety to the Invoice and no*/}
                                {/*    refund for unused amounts will be made.</p>*/}
                                {/*<p>3. Promo code will be considered as used even if the Shipmentis canceled*/}
                                {/*    post-booking.</p>*/}
                            </div>
                        </div>
                    </Fragment>

                }
                {console.log(this.state.booking)}
                {this.state.booking ?
                
                
                    <Modal className="price-breackdown-popup" isOpen={this.state.openPriceBreakdownModal}
                           toggle={this.toggleopenPriceBreakdownModal}>
                        <ModalHeader toggle={this.toggleopenPriceBreakdownModal}>
                            Price Breakdown ( India Specific )
                        </ModalHeader>
                        <ModalBody className="price-breakdown-table">
                            <Container>
                                <div className="pol-full-line">
                                    <Row>
                                        <Col lg="12" sm="12">
                                            <div className="table-full-price">
                                                <p className="table-full-price-note"><b>NOTE: </b> GST applicable are
                                                    applicable as per law.
                                                    <br/>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Local charges are
                                                    subject to changes if any; due to change in load port terminal.
                                                </p>
                                                <Table className="">
                                                    <thead>
                                                    <tr>
                                                        <th>Heads</th>
                                                        <th>Basis</th>
                                                        <th>Type</th>
                                                        <th>Qty</th>
                                                        <th>Per container Price</th>
                                                        <th>usd</th>
                                                        <th>inr</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="border-none">
                                                        <td colSpan="4" className="local-area color-one">
                                                            <a href={"#"}>international</a>
                                                        </td>
                                                    </tr>

                                                    {freightCharges}

                                                    {this.state.booking.is_haz ?
                                                        <Fragment>
                                                            {this.state.booking.container_20ft > 0 ?
                                                                <tr>
                                                                    <td><b>Hazardous</b></td>
                                                                    <td>Per container</td>
                                                                    <td>20FT</td>
                                                                    <td>{this.state.booking.container_20ft}</td>
                                                                    <td>$ {this.state.booking.freight_charges['haz_20']}</td>
                                                                    <td>$ {this.state.booking.freight_charges['haz_20'] * this.state.booking.container_20ft}</td>
                                                                    <td className="change-td"/>
                                                                </tr> : ""}
                                                            {this.state.booking.container_40ft > 0 ?
                                                                <tr>
                                                                    <td>{(!this.state.booking.container_20ft > 0) ? "Hazardous" : ""}</td>
                                                                    <td>Per container</td>
                                                                    <td>40FT</td>
                                                                    <td>{this.state.booking.container_40ft}</td>
                                                                    <td>$ {this.state.booking.freight_charges['haz_40']}</td>
                                                                    <td>$ {this.state.booking.freight_charges['haz_40'] * this.state.booking.container_40ft}</td>
                                                                    <td className="change-td"/>
                                                                </tr> : ""}
                                                            {this.state.booking.container_40hc > 0 ?
                                                                <tr>
                                                                    <td>{(this.state.booking.container_20ft <= 0) && (this.state.booking.container_40ft <= 0) ? "Hazardous" : ""}</td>
                                                                    <td>Per container</td>
                                                                    <td>40HC</td>
                                                                    <td>{this.state.booking.container_40hc}</td>
                                                                    <td>$ {this.state.booking.freight_charges['haz_40hc']}</td>
                                                                    <td>$ {this.state.booking.freight_charges['haz_40hc'] * this.state.booking.container_40hc}</td>
                                                                    <td className="change-td"/>
                                                                </tr> : ""}
                                                        </Fragment> : ""}

                                                    <tr/>
                                                    {parseInt(this.state.booking.discount) > 0 ?
                                                        <tr>
                                                            <td><b>Discount</b></td>
                                                            <td/>
                                                            <td/>
                                                            <td/>
                                                            <td/>
                                                            <td>$ {this.state.booking.discount}</td>
                                                            <td/>
                                                        </tr> : null}

                                                    <tr className="border-none">
                                                        <td colSpan="6" className="local-area">
                                                            <a href={"#"}>Local</a>
                                                        </td>
                                                    </tr>
                                                    {local_charges}
                                                    <tr>
                                                        <td><b>Platform fee</b></td>
                                                        <td>Per Document</td>
                                                        <td/>
                                                        <td/>
                                                        <td/>
                                                        <td>$ {this.state.platform_fees}</td>
                                                        <td/>
                                                    </tr>
                                                    <tr>
                                                        <td><b className="price-breakdown-table">Total Charges</b>
                                                        </td>
                                                        <td className="change-td total-charge-last-one"
                                                            colSpan="5">USD
                                                            ($) {this.state.booking && this.state.booking.total_amount ? (parseFloat(this.state.booking.total_amount['total_amount_usd_sales']) - parseFloat(this.state.booking.discount)) : 0}</td>
                                                        <td className="change-td total-charge-last">INR
                                                            ( <i className="fa fa-rupee lh-32px"/> ) {this.state.booking && this.state.booking.total_amount ? this.state.booking.total_amount['total_amount_inr_sales'] : 0}
                                                        </td>
                                                    </tr>

                                                    </tbody>

                                                </Table>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </Container>

                        </ModalBody>
                    </Modal>
                    : null}

                <Modal className="price-breackdown-popup cancel_booking-popup"
                       isOpen={this.state.openCancelBookingModal}
                       toggle={this.toggleCancelBooking}>
                    <ModalHeader toggle={this.toggleCancelBooking}>
                        Cancel Booking
                    </ModalHeader>
                    <AvForm onSubmit={this.cancelBooking}>
                        <ModalBody className="price-breakdown-table cancel_booking-popup">
                            <div className="col-12 col-sm-12 col-lg-12">
                                <div className="form-group">
                                    <AvGroup>
                                        <label>Remark <span className="required">*</span></label>
                                        <AvField type="textarea" id="cancellation_remark" name="cancellation_remark"
                                                 value=""
                                                 validate={{
                                                     required: {
                                                         value: true,
                                                         errorMessage: 'Please enter Remark'
                                                     },
                                                 }}/>
                                    </AvGroup>
                                </div>
                            </div>

                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary">
                                <ButtonLoader isLoading={this.state.cancelModalLoading} label={"Submit"}/>
                            </Button>
                            <Button color="secondary" outline onClick={this.toggleCancelBooking}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </Modal>
                <Modal className="price-breackdown-popup"
                       isOpen={this.state.openDocumentsModal}
                       toggle={this.toggleDocumentsModal}>
                    <ModalHeader toggle={this.toggleDocumentsModal}>
                        <h4>Booking Documents</h4>
                    </ModalHeader>
                    <ModalBody>
                        <Documents bookingDetails={this.state.booking}
                                   sendToShipline={this.sendToShipline}
                                   buttonDocumentsLoading={this.state.buttonDocumentsLoading}
                                   upload_documents={this.upload_documents}
                                   deleteDoc={this.deleteDoc}
                        />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>


                <NotificationContainer/>
            </Fragment>
        );
    }

}

const mapStateToProps = ({auth, bookings}) => {
    return {auth, bookings};
};


export default connect(mapStateToProps, {
    setActiveBooking, showAuthModal, updateBooking
})(BookingDetails);
