import React, {Component, Fragment} from "react";
import {Button, CardBody} from "reactstrap";
import {AWS_S3_URL} from "../../constants/apiConstants";
import Auth from "../../util/auth.service";

class MemberCard extends Component {

    state = {
        memberDetails: [],
        isLoading: true
    };

    componentDidMount() {
        this.setState({memberDetails: this.props.member, isLoading: false});
    };

    editMember = (id) => {
        this.props.editMember(id);
    };

    removeMember = (id) => {
        this.props.removeMember(id);
    };

    render() {
        let user = Auth.user;
        return !this.state.isLoading ?
            <Fragment>
                <div className="col-md-4">
                    <CardBody>
                        <h4>{this.state.memberDetails.first_name + " " + this.state.memberDetails.last_name}</h4>
                        <p>{this.state.memberDetails.email}</p>
                        <p className="color-chng">{this.state.memberDetails.mobile}</p>
                        <p className="color-chng">{this.state.memberDetails.country.name}</p>
                        <p className="color-chng">{this.state.memberDetails.state ? this.state.memberDetails.state.name : ""}</p>
                        <p className="color-chng">{this.state.memberDetails.city ? this.state.memberDetails.city.name : ""}</p>
                        <div>
                            {this.state.memberDetails.photo ?
                                <img className={'w-50px h-50px'}
                                     src={AWS_S3_URL + this.state.memberDetails.photo}
                                     alt={"Logo"}/> : null}
                        </div>
                        <div className="btn-end-card">
                            <Button color="primary" onClick={() => this.editMember(this.state.memberDetails.id)}>
                                <i className="fa fa-edit"/>
                            </Button>
                            &nbsp;
                            {user.id !== this.state.memberDetails.id ?
                                <Button color="primary" onClick={() => this.removeMember(this.state.memberDetails.id)}>
                                    <i className="fa fa-trash"/>
                                </Button> : null}
                        </div>
                    </CardBody>
                </div>
            </Fragment>
            : <Fragment/>
    }
}

export default MemberCard;