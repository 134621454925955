import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Col, Row} from "reactstrap";
import Auth from "../../../util/auth.service";
import {customDebounce} from "../../../util/util.service";

class ResetPassword extends Component {

    constructor() {
        super();
        this.state = {
            token: '',
            reset_success: false,
            isLoading: false,
            isErrorFound: false,
            isShowPassword: false,
            isShowConfirmPassword: false,
            password_error_message: '',
            password_confirmation_error_message: '',
            password: '',
            password_confirmation: ''
        };
    };

    componentDidMount() {
        let {params} = this.props.match;
        this.setState({token: params.token});
    }

    componentWillUnmount() {

    };

    handleInputChange = (name, value) => {
        let errorMsg = '';
        if (name !== 'password_confirmation') {
            let lengthRegex = /^(?!.*\s).{6,}/gm;
            let regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_])(?!.*\s).{6,}/gm;
            if (!lengthRegex.test(value)) {
                errorMsg = 'Password must be at least 6 characters';
            } else if (!regex.test(value)) {
                errorMsg = 'password should contains at least one - upper case letter, lower case letter, number and special character and minimum 6 character required';
            } else if (this.state.password_confirmation !== '') {
                let errorMsg2 = '';
                if (this.state.password_confirmation !== value) {
                    errorMsg2 = 'Confirm Password does not matched with New Password';
                }
                this.setState({
                    ['password_confirmation_error_message']: errorMsg2,
                    ['password_error_message']: errorMsg,
                    [name]: value
                })
                return false;
            }
        } else {
            if (this.state.password !== value) {
                errorMsg = 'Confirm Password does not matched with New Password';
            }
        }
        this.setState({
            [name + '_error_message']: errorMsg,
            [name]: value
        })
    }
;

    resetPassword = () => {
        if (this.state.password_error_message !== '' || this.state.password_confirmation_error_message !== '') {
            return false;
        } else if (this.state.password !== this.state.password_confirmation) {
            this.setState({password_confirmation_error_message: 'Confirm Password does not matched with New Password'});
            return false;
        }
        console.log('asdasdas');
        return false;
        this.setState({error_message: ''});
        let {password, password_confirmation, token} = this.state;
        Auth.resetPassword({password, password_confirmation, token})
            .then(response => {
                return response.data
            })
            .then(response => {
                // console.log(response);
                if (response.status === 'success') {
                    this.setState({reset_success: true});
                } else {
                    this.setState({error_message: response.message});
                }
            })
            .catch(err => err);
    };
    togglePassword = (key) => {
        this.setState({[key]: !this.state[key]})
    }


    render() {
        let success_message = this.state.reset_success ?
            (<div className={'fs-14px text-green'}>
                <div>Password reset successfully, click <Link to='/'>here</Link> to login</div>
                <br/>
            </div>) : '';

        let error_message = this.state.error_message ?
            (<div className={'fs-12px text-red'}>
                {this.state.error_message}
            </div>) : '';

        return (
            <div className="container-fluid">
                <Row>
                    <Col lg="3" sm="3" className="reset-password form-model">
                        {success_message}
                        {error_message}
                        <h1>Reset <span>Password</span></h1>
                        <span className="input input--nao">
                            <input className="input__field input__field--nao"
                                   type={this.state.isShowPassword ? "text" : "password"} name="password"
                                   id="input-1"
                                   onBlur={(e) => this.handleInputChange(e.target.name, e.target.value)}/>
                            <label className="input__label input__label--nao" htmlFor="input-1">
                                <span className="input__label-content input__label-content--nao">
                                    Password
                                </span>
                            </label>
                        <i className={this.state.isShowPassword ? "fa fa-eye password-eye" : "fa fa-eye-slash password-eye"}
                           onClick={() => this.togglePassword('isShowPassword')}/>
                            <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                                 preserveAspectRatio="none">
                                <path
                                    d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                            </svg>
                        </span>
                        {this.state.password_error_message ?
                            <p className={'text-danger'}>{this.state.password_error_message}</p> : ''}
                        <span className="input input--nao">
                            <input className="input__field input__field--nao"
                                   type={this.state.isShowConfirmPassword ? "text" : "password"}
                                   name="password_confirmation" id="input-1"
                                   onBlur={(e) => this.handleInputChange(e.target.name, e.target.value)}/>
                            <label className="input__label input__label--nao" htmlFor="input-1">
                                <span className="input__label-content input__label-content--nao">
                                    Confirm New password
                                </span>
                            </label>
                            <i className={this.state.isShowConfirmPassword ? "fa fa-eye password-eye" : "fa fa-eye-slash password-eye"}
                               onClick={() => this.togglePassword('isShowConfirmPassword')}/>
                            <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                                 preserveAspectRatio="none">
                                <path
                                    d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                            </svg>
                        </span>
                        {this.state.password_confirmation_error_message ?
                            <p className={'text-danger'}>{this.state.password_confirmation_error_message}</p> : ''}
                        <div className="search-btn-Reset ">
                            <button onClick={() => this.resetPassword()} className="btn primary-btn">Reset Password
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default ResetPassword;