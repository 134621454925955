import React, { Component, Fragment } from "react";
import Profile from "../../../util/profile.service";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import ButtonLoader from "../../../util/ButtonLoader";
import { AWS_S3_URL } from "../../../constants/apiConstants";
import Common from "../../../util/common.service";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import { loginUserSuccess } from "../../../redux/auth/actions";
import { connect } from "react-redux";
import Auth from "../../../util/auth.service";
import CustomFeedback from "../../../components/formValidations/CustomFeedback";

class UserProfile extends Component {
  state = {
    isLoading: true,
    userDetails: [],
    existedProfilePhoto: null,
    countryList: [],
    country: null,
    countrySelectError: null,
    stateList: [],
    state: null,
    stateSelectError: null,
    cityList: [],
    city: null,
    citySelectError: null,
    profilePhotoError: null,
  };

  componentDidMount() {
    this.getProfileDetails();
    this.getCountryDetails();
  }

  getProfileDetails = () => {
    Profile.all()
      .then((response) => {
        if (response !== undefined) {
          let state = null,
              city = null,
              country = null;
          let userDetails = response.data.data;
          this.props.loginUserSuccess(userDetails);
          Auth.user = userDetails;

          if (response.data.status === "success") {
            if (userDetails.state) {
              state = {
                label: userDetails.state.name,
                value: userDetails.state.id,
                key: userDetails.state.id,
              };
            }
            if (userDetails.city) {
              city = {
                label: userDetails.city.name,
                value: userDetails.city.id,
                key: userDetails.city.id,
              };
            }
            if (userDetails.country) {
              country = {
                label: userDetails.country.name,
                value: userDetails.country.id,
                key: userDetails.country.id,
              };
            }
            console.log('t1');
            let stateList = [];
            if (userDetails.country_id) {
              Common.stateByCountry(userDetails.country_id).then((response) => {
                if (response !== undefined) {
                  let state_list = response.data.data;
                  for (let state in state_list) {
                    stateList.push({
                      label: state_list[state].name,
                      value: state_list[state].id,
                      key: state_list[state].id,
                    });
                  }
                }
              });
            }

            let cityList = [];
            if (userDetails.state_id) {
              Common.cityByState(userDetails.state_id).then((response) => {
                if (response !== undefined) {
                  let city_list = response.data.data;
                  for (let city in city_list) {
                    cityList.push({
                      label: city_list[city].name,
                      value: city_list[city].id,
                      key: city_list[city].id,
                    });
                  }
                }
              });
            }

            console.log(userDetails);
            this.setState({
              isLoading: false,
              userDetails,
              existedProfilePhoto: userDetails.photo,
              country,
              state,
              city,
              stateList,
              cityList,
            });
          }
        }
      })
      .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
  };

  getCountryDetails = () => {
    Common.getAllCountry()
      .then((response) => {
        if (response !== undefined) {
          let country_list = response.data.data,
              countryList = [];
          for (let country in country_list) {
            countryList.push({
              label: country_list[country].name,
              value: country_list[country].id,
              key: country_list[country].id,
            });
          }
          this.setState({countryList});
        }
      })
      .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
  };

  handleProfilePhoto = () => {
    this.setState({ existedProfilePhoto: null });
    var imagefile = document.querySelector("#profile_photo");
    if (imagefile.files[0]) {
      let allowed_extensions = ["pdf", "jpeg", "jpg", "png"];
      var parts = imagefile.files[0].name.split(".");
      if (
        allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1
      ) {
        this.setState({
          profilePhotoName: imagefile.files[0].name,
          profilePhotoError: null,
          profilePhoto: imagefile.files[0],
        });
      } else {
        this.setState({
          profilePhotoError: "Please select valid image file",
          profilePhotoName: null,
          profilePhoto: null,
        });
      }
    }
  };

  handleChange = (selected, type, error) => {
    if (type === "country") {
      let stateList = [];
      Common.stateByCountry(selected.value).then((response) => {
        if (response !== undefined) {
          let state_list = response.data.data;
          for (let state in state_list) {
            stateList.push({
              label: state_list[state].name,
              value: state_list[state].id,
              key: state_list[state].id,
            });
          }
          this.setState({
            [type]: selected,
            stateList,
            state: null,
            city: null,
            [error]: null,
          });
        }
      });
    } else if (type === "state") {
      let cityList = [];
      Common.cityByState(selected.value).then((response) => {
        if (response !== undefined) {
          let city_list = response.data.data;
          for (let city in city_list) {
            cityList.push({
              label: city_list[city].name,
              value: city_list[city].id,
              key: city_list[city].id,
            });
          }
          this.setState({
            [type]: selected,
            cityList,
            city: null,
            [error]: null,
          });
        }
      });
    } else {
      this.setState({ [type]: selected, [error]: null });
    }
  };

  onSubmit = (event, errors, values) => {
    let countrySelectError = "",
      stateSelectError = "",
      citySelectError = "";
    if (this.state.country === null) {
      countrySelectError = "Please select Country";
      errors.push(countrySelectError);
    }
    if (this.state.state === null) {
      stateSelectError = "Please select State";
      errors.push(stateSelectError);
    }
    if (this.state.city === null) {
      citySelectError = "Please select City";
      errors.push(citySelectError);
    }
    this.setState({ countrySelectError, stateSelectError, citySelectError });

    if (errors.length === 0) {
      let data = {
        ...values,
        profile: this.state.profilePhoto,
        country_id: this.state.country.value,
        state_id: this.state.state.value,
        city_id: this.state.city.value,
      };

      Profile.update(this.state.userDetails.id, data)
        .then((response) => {
          if (response.data.status === "success") {
            this.getProfileDetails();
            NotificationManager.success(
              "Success message",
              response.data.message
            );
          }
        })
        .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    }
  };

  render() {
    return !this.state.isLoading ? (
      <Fragment>
        <div className="booking-history-box">
          <div className="add-line-bg mt-10px">
            <h1>Personal Details</h1>
          </div>

          <AvForm onSubmit={this.onSubmit}>
            <div className="itembg">
              <div className="profile-all">
                <div className="row h-vgm">
                  <div className="col-lg-4 col-12">
                    <AvGroup className="error-t-negative">
                      <label>
                        First Name <span className="required">*</span>
                      </label>
                      <AvField
                        name="first_name"
                        type="text"
                        value={this.state.userDetails.first_name}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter First name",
                          },
                        }}
                      />
                    </AvGroup>
                  </div>

                  <div className="col-lg-4 col-12">
                    <AvGroup className="error-t-negative">
                      <label>
                        Last Name <span className="required">*</span>
                      </label>
                      <AvField
                        name="last_name"
                        type="text"
                        value={this.state.userDetails.last_name}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Last name",
                          },
                        }}
                      />
                    </AvGroup>
                  </div>

                  <div className="col-lg-4 col-12">
                    <AvGroup className="error-t-negative">
                      <label>
                        User Mobile Number <span className="required">*</span>
                      </label>
                      <AvField
                        name="mobile"
                        type="text"
                        value={this.state.userDetails.mobile}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Mobile Number",
                          },
                          pattern: {
                            value: '/^\\d{10}$/gm',
                            errorMessage: 'Mobile number should 10 digit only'
                          },
                          minLength: {
                            value: 10,
                            errorMessage: "Please enter valid Mobile Number.",
                          },
                          maxLength: {
                            value: 15,
                            errorMessage: "Please enter valid Mobile Number.",
                          },
                        }}
                      />
                    </AvGroup>
                  </div>
                  <div className="col-lg-4 col-12">
                    <AvGroup className="error-t-negative">
                      <label>
                        Country <span className="required">*</span>
                      </label>
                      <Select
                        className="home-select"
                        name="country"
                        value={this.state.country}
                        placeholder="Select Country"
                        noOptionsMessage={() => "No record found"}
                        onChange={(selected) =>
                          this.handleChange(
                            selected,
                            "country",
                            "countrySelectError"
                          )
                        }
                        options={this.state.countryList}
                      />
                      <CustomFeedback>
                        {this.state.countrySelectError}
                      </CustomFeedback>
                    </AvGroup>
                  </div>

                  <div className="col-lg-4 col-12">
                    <label>
                      State <span className="required">*</span>
                    </label>
                    <Select
                      className="home-select"
                      name="state"
                      value={this.state.state}
                      placeholder="Select State"
                      onChange={(selected) =>
                        this.handleChange(selected, "state", "stateSelectError")
                      }
                      options={this.state.stateList}
                      noOptionsMessage={() => "No record found"}
                    />
                    <CustomFeedback>
                      {this.state.stateSelectError}
                    </CustomFeedback>
                  </div>

                  <div className="col-lg-4 col-12">
                    <label>
                      City <span className="required">*</span>
                    </label>
                    <Select
                      className="home-select"
                      name="city"
                      value={this.state.city}
                      placeholder="Select City"
                      onChange={(selected) =>
                        this.handleChange(selected, "city", "citySelectError")
                      }
                      options={this.state.cityList}
                      noOptionsMessage={() => "No record found"}
                    />
                    <CustomFeedback>
                      {this.state.citySelectError}
                    </CustomFeedback>
                  </div>
                  <div className="col-lg-12 col-12">
                    <label>Profile Picture</label>
                    {/*<input type="file" name="profile_photo" id="profile_photo"*/}
                    {/*       onChange={this.validateProfilePhoto}/>*/}

                    <div className="upload-2-files">
                      <div className="row">
                        <div className="col-lg-2 col-6">
                          <div className="upload-btn-wrapper comp_fileupld">
                            <button className="btn">
                              <i className="fa fa-file-alt" /> Select File
                            </button>
                            <input
                              type="file"
                              name="profile_photo"
                              id="profile_photo"
                              onChange={this.handleProfilePhoto}
                            />
                            <p className={'fs-13px'}>
                              {this.state.profilePhotoName}
                            </p>
                            <CustomFeedback>
                              {this.state.profilePhotoError}
                            </CustomFeedback>
                          </div>
                        </div>
                        <div className="col-lg-2 col-6">
                          {this.state.existedProfilePhoto != null ? (
                            <img
                              alt="Profile Picture"
                              className={'mx-w-150px mx-h-150px'}
                              src={AWS_S3_URL + this.state.existedProfilePhoto}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12">
                    <div className="vgm_sub">
                      <button className="btn primary-btn">
                        <ButtonLoader
                          isLoading={this.state.loading}
                          label={"Update"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AvForm>
        </div>
      </Fragment>
    ) : (
      <h2 className={"loading-text"}>Loading...</h2>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps, { loginUserSuccess })(UserProfile);
