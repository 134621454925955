import React, {Component, Fragment} from "react";
import {AWS_S3_URL} from "../../constants/apiConstants";
import {Button, CardBody, CustomInput} from "reactstrap";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import ButtonLoader from "../../util/ButtonLoader";
import {maxUploadFileSize} from "../../constants/defaultValues";

class Documents extends Component {

    state = {
        documentList: null,
        documentsSelected: [],
        vgm_valid: false,
        documentErrorMsg: null,
        otherDocName: "Select file (MAX File upload limit is 6 MB)",
        otherDocError: null,
        otherDoc: null,
        documentDeleted: false
    };

    componentDidMount() {
        this.getDocumentDetails();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.bookingDetails !== this.props.bookingDetails) {
            this.getDocumentDetails();
        }
    };

    handleDocuments = (event, counter, document, doc_name) => {
        counter = parseInt(event.target.dataset.id);
        if (event.target.checked === true) {
            let documents = this.state.documentsSelected;
            documents[counter] ={[doc_name]:document};
            this.setState({documentsSelected: documents, documentErrorMsg: null});
        } else {
            let documents = [];
            this.state.documentsSelected.filter(function (value, index, arr) {
                if (index !== counter) {
                    documents[index] = value;
                }
            });
            this.setState({documentsSelected: documents});
        }
    };

    getDocumentDetails = () => {
        let bookingDetails = this.props.bookingDetails;
        let documents = bookingDetails.documents;
        let bookingDocs = [];
        let counter = 0;

        /*
        * CRO Documents
        */

        if (documents != null && documents.cro) {
            for (let key in documents.cro) {
                counter = parseInt(counter) + 1;
                var croFileName = documents.cro[key].split("/");
                bookingDocs.push(
                    <tr key={counter}>
                        <td>
                            <CustomInput
                                type="checkbox"
                                data-id={counter}
                                id={"document_" + counter}
                                onChange={(event) => this.handleDocuments(event, counter, documents.cro[key],'cro')}
                            />
                        </td>

                        <td>CRO</td>
                        <td>{croFileName[croFileName.length - 1]}</td>
                        <td>
                            <a href={AWS_S3_URL + documents.cro[key]} target="_blank">
                                <span className="badge badge-pill handle mr-1">
                                    <i className="fa fa-eye" title="View File"/>
                                </span>
                            </a>
                        </td>
                    </tr>
                )
            }
        }

        /*
        * CRO Other documents
        */

        if (documents != null && documents.other) {
            for (let key in documents.other) {
                var croFileName = documents.other[key].split("/");
                counter = parseInt(counter) + 1;
                bookingDocs.push(
                    <tr key={counter}>
                        <td>
                            <CustomInput
                                type="checkbox"
                                data-id={counter}
                                id={"document_" + counter}
                                onChange={(event) => this.handleDocuments(event, counter, documents.other[key],'other_doc')}
                            />
                        </td>

                        <td>CRO (Other)</td>
                        <td>{croFileName[croFileName.length - 1]}</td>
                        <td>
                            <a href={AWS_S3_URL + documents.other[key]} target="_blank">
                                <span className="badge badge-pill handle mr-1">
                                    <i className="fa fa-eye" title="View File"/>
                                </span>
                            </a>
                        </td>
                    </tr>
                )
            }
        }

        /*
        * VGM documents
        */

        let containers = bookingDetails.containers;
        for (let vgm_doc in containers) {
            if (containers[vgm_doc]['vgm_document'] != null && containers[vgm_doc]['vgm_document']['vgm']) {
                var vgmFileName = containers[vgm_doc]['vgm_document']['vgm'].split("/");
                counter = parseInt(counter) + 1;
                bookingDocs.push(
                    <tr key={counter}>
                        <td>
                            <CustomInput
                                type="checkbox"
                                data-id={counter}
                                id={"document_" + counter}
                                onChange={(event) => this.handleDocuments(event, counter, containers[vgm_doc]['vgm_document']['vgm'],'vgm')}
                            />
                        </td>
                        <td>VGM ({containers[vgm_doc]['container_number']})(Weighbridge Slip)</td>
                        <td>{vgmFileName[vgmFileName.length - 1]}</td>
                        <td>
                            <a href={AWS_S3_URL + containers[vgm_doc]['vgm_document']['vgm']} target="_blank">
                                 <span className="badge badge-pill handle mr-1">
                                    <i className="fa fa-eye" title="View File"/>
                                </span>
                            </a>&nbsp;
                        </td>
                    </tr>
                );
            }

            /*
            * VGM Weight Declaration Document
            */

            if (containers[vgm_doc]['vgm_document'] != null && containers[vgm_doc]['vgm_document']['weight_declaration']) {
                var vgmFileName = containers[vgm_doc]['vgm_document']['weight_declaration'].split("/");
                counter = parseInt(counter) + 1;
                bookingDocs.push(
                    <tr key={counter}>
                        <td>
                            <CustomInput
                                type="checkbox"
                                data-id={counter}
                                id={"document_" + counter}
                                onChange={(event) => this.handleDocuments(event, counter, containers[vgm_doc]['vgm_document']['weight_declaration'],'vgm')}
                            />
                        </td>
                        <td>VGM ({containers[vgm_doc]['container_number']})(Weight Declaration- Annexure 1)</td>
                        <td>{vgmFileName[vgmFileName.length - 1]}</td>
                        <td>
                            <a href={AWS_S3_URL + containers[vgm_doc]['vgm_document']['weight_declaration']}
                               target="_blank">
                                 <span className="badge badge-pill handle mr-1">
                                    <i className="fa fa-eye" title="View File"/>
                                </span>
                            </a>&nbsp;
                        </td>
                    </tr>);
            }
        }
        /*
        * Shipping Bill documents
        */
        if (documents != null && documents.shipping_bill) {
            for (let key in documents.shipping_bill) {
                var shippingBillFileName = documents.shipping_bill[key].split("/");

                counter = parseInt(counter) + 1;
                bookingDocs.push(
                    <tr key={counter}>
                        <td><CustomInput
                            type="checkbox"
                            data-id={counter}
                            id={"document_" + counter}
                            onChange={(event) => this.handleDocuments(event, counter, documents.shipping_bill[key],'shipping_bill')}
                        /></td>
                        <td>Shipping Bill</td>
                        <td>{shippingBillFileName[shippingBillFileName.length - 1]}</td>
                        <td>
                            <a href={AWS_S3_URL + documents.shipping_bill[key]} target="_blank">
                                <span className="badge badge-pill handle mr-1">
                                    <i className="fa fa-eye" title="View File"/>
                                </span>
                            </a>&nbsp;
                        </td>
                    </tr>);
            }
        }

        /*
        * Hazardous documents
        */

        if (bookingDetails.haz_documents) {
            for (let key in bookingDetails.haz_documents) {
                var hazFileName = bookingDetails.haz_documents[key].split("/");
                counter = parseInt(counter) + 1;
                bookingDocs.push(
                    <tr key={counter}>
                        <td><CustomInput
                            type="checkbox"
                            data-id={counter}
                            id={"document_" + counter}
                            onChange={(event) => this.handleDocuments(event, counter, bookingDetails.haz_documents[key],'haz_doc')}
                        /></td>
                        <td>Hazardous Documents</td>
                        <td>{hazFileName[hazFileName.length - 1]}</td>
                        <td>
                            <a href={AWS_S3_URL + bookingDetails.haz_documents[key]} target="_blank">
                                <span className="badge badge-pill handle mr-1">
                                    <i className="fa fa-eye" title="View File"/>
                                </span>
                            </a>&nbsp;
                        </td>
                    </tr>);
            }
        }

        /*
        * Form13 documents
        */

        if (bookingDetails.form13) {
            for (let form13 of bookingDetails.form13) {
                for (let form13Key in form13.documents) {
                    var form13FileName = form13.documents[form13Key].file.split("/");
                    counter = parseInt(counter) + 1;
                    bookingDocs.push(
                        <tr key={counter}>
                            <td><CustomInput
                                type="checkbox"
                                data-id={counter}
                                id={"document_" + counter}
                                onChange={(event) => this.handleDocuments(event, counter, form13.documents[form13Key].file,'form13')}
                            /></td>
                            <td>Form13 Documents</td>
                            <td>{form13FileName[form13FileName.length - 1]}</td>
                            <td>
                                <a href={AWS_S3_URL + form13.documents[form13Key].file} target="_blank">
                                     <span className="badge badge-pill handle mr-1">
                                        <i className="fa fa-eye" title="View File"/>
                                    </span>
                                </a>&nbsp;
                            </td>
                        </tr>);
                }
            }
        }

        /*
        * Other Manually added documents
        */

        if (bookingDetails.other_documents) {
            for (let other in bookingDetails.other_documents) {

                var otherDocFileName = bookingDetails.other_documents[other].file_name.split("/");
                counter = parseInt(counter) + 1;
                bookingDocs.push(
                    <tr key={counter}>
                        <td><CustomInput
                            type="checkbox"
                            data-id={counter}
                            id={"document_" + counter}
                            onChange={(event) => this.handleDocuments(event, counter, bookingDetails.other_documents[other].file_name,'other_doc')}
                        /></td>
                        <td>{bookingDetails.other_documents[other].file_type}</td>
                        <td>{otherDocFileName[otherDocFileName.length - 1]}</td>
                        <td>
                            <a href={AWS_S3_URL + bookingDetails.other_documents[other].file_name} target="_blank">
                                    <span className="badge badge-pill handle mr-1">
                                        <i className="fa fa-eye" title="View File"/>
                                    </span>
                            </a>
                            <a onClick={() => this.props.deleteDoc(this.props.bookingDetails.id,'other_documents', other)}>
                                <span className="badge badge-pill handle mr-1">
                                    <i className="fa fa-trash-o" title="delete document" />
                                </span>
                            </a>
                        </td>
                    </tr>);

            }
        }
        this.setState({
            bookingDetails: bookingDetails,
            documentList: bookingDocs,
            vgm_valid: bookingDetails.containers && bookingDetails.containers.length && typeof bookingDetails.containers[0] !== 'undefined' && bookingDetails.containers[0]['vgm_done_at'] !== null
        });
    };

    // deleteDoc = (id, doc, key) => {
    //     this.setState({responseStatus: null});
    //     Booking.document_delete(id, doc, key)
    //         .then(response => {
    //             if (response.data.status === 'success') {
    //                 this.setState({documentDeleted:true});
    //                 NotificationManager.success('Success message', response.data.message);
    //                 this.getDocumentDetails();
    //                 this.setState({responseStatus: response.data.message});
    //                 this.setState({documentDeleted:false});
    //             }
    //             window.scrollTo(0, 0);
    //         }).catch(error => {
    //         NotificationManager.error('Error found');
    //         console.log(error);
    //     });
    // };

    sendToShipline = (event, errors, values) => {
        this.setState({responseStatus: ""});
        if (this.state.documentsSelected.length === 0 && !this.state.vgm_valid) {
            this.setState({documentErrorMsg: 'Please select at least one document'});
        } else if (errors.length === 0) {
            let attachments = this.state.documentsSelected.filter(Boolean);
            attachments = JSON.stringify(attachments);
            let data = {
                attachments: attachments,
                booking_id: this.state.bookingDetails.id,
                shipper_email: values.shipper_email
            };
            this.props.sendToShipline(data);
        }

    };

    getFilename = (file, docName, errorName, fileVariable) => {
        var imagefile = document.querySelector('#' + file);
        if (imagefile.files[0]) {
            var parts = imagefile.files[0].name.split('.');
            let allowed_extensions = ['pdf'];
            if (allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1) {

                if (imagefile.files[0].size <= maxUploadFileSize) {
                    this.setState({
                        [fileVariable]: imagefile.files[0] ? imagefile.files[0] : '',
                        [docName]: imagefile.files[0] ? imagefile.files[0].shortName(30) : 'Upload VGM',
                        [errorName]: null
                    });
                } else {
                    this.setState({[errorName]: 'File size is greater than 5 MB.'});
                }
            }
        }
    }

    onDocumentSubmit = (event, errors, values) => {
        let otherDocError = null;
        if (!this.state.otherDoc) {
            otherDocError = "Please select File.";
            errors.push(otherDocError);
        }
        this.setState({otherDocError});
        if (errors.length === 0 && !this.props.documentUploadButtonLoading) {
            let data = {
                ...values,
                other_doc: this.state.otherDoc,
                booking_id: this.props.bookingDetails.id
            }
            this.props.upload_documents(data);
            this.setState({otherDoc: null, otherDocError: null, otherDocName: "Select file"});
            this.myFormRef.reset();
        }
    };

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <CardBody>
                                <table className="details_table table table-sm">
                                    <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.documentList}
                                    </tbody>
                                </table>

                                <AvForm onSubmit={this.onDocumentSubmit} ref={(el) => this.myFormRef = el}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <AvGroup className="error-t-negative">
                                                <AvField name='file_type' type="text"
                                                         placeholder="Document Type"
                                                         id='file_type'
                                                         value=""
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Document Type.'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>
                                        <div className="col-md-6">
                                            <AvGroup className="error-t-negative div-view-file">
                                                <div className="custom-file-full">
                                                    <div className="custom-file">
                                                        <input type="file" id="other_booking_doc"
                                                               name="other_booking_doc"
                                                               className="custom-file-input"
                                                               onChange={() => this.getFilename('other_booking_doc', 'otherDocName', 'otherDocError', 'otherDoc')}
                                                               multiple/>
                                                        <label className="custom-file-label"
                                                               htmlFor="weight_declaration">{this.state.otherDocName}</label>
                                                    </div>
                                                </div>
                                                <label
                                                    className={'text-red'}>{this.state.otherDocError}</label>
                                            </AvGroup>
                                        </div>
                                        <div className="col-md-2">
                                            <Button color="primary" size="xs">
                                                <ButtonLoader color="primary"
                                                              isLoading={this.props.buttonDocumentsLoading}
                                                              label="Add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </AvForm>
                                <AvForm onSubmit={this.sendToShipline}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <AvGroup className="error-t-negative">
                                                <label>Send above selected documents to Comma separated Email
                                                    ID’s:</label>
                                                <AvField name='shipper_email' type="text"
                                                         className="shipper_email mb-0px"
                                                         customprop='shipper_email'
                                                         id='shipper_email'
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Email of the Shiping Line'
                                                             }
                                                         }}/>
                                                <label className={'text-red'}>
                                                    Note: Above selected documents will be sent on mentioned email.
                                                </label>
                                            </AvGroup>
                                        </div>
                                        <div className="col-md-6">
                                            <Button color={"primary"} size={"sm"} className={'mt-30px'}>
                                                <ButtonLoader color="primary"
                                                              isLoading={this.props.buttonDocumentsLoading}
                                                              label="Send Email"/>
                                            </Button>
                                        </div>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

}

export default Documents;