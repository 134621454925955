import React, {Component, Fragment} from "react";
import {Col} from "reactstrap";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import Request from "../../../util/api.service";
import {API_URL} from "../../../constants/apiConstants";
import {NotificationContainer, NotificationManager} from "react-notifications";
import DataTablePagination from "../../../components/DataTables/pagination";
import {filterCaseInsensitive} from "../../../util/util.service";
import Storage from "../../../util/storage.service";
import axios from 'axios';
import Select from "react-select";
import {blStatus, ISSUED, USER_APPROVED} from "../../../constants/blStatus";

class BlList extends Component {

    state = {
        isLoading: true,
        blStatusOptions: [],
        blData: {
            data: [],
            pages: null,
            loading: true,
            sorted: []
        },
        blListingColumns: [{
            Header: "Bl Number",
            accessor: "bl_number",
            Cell: props => <p className="text-muted">{props.value} {props.original.bl_issued && !props.original.shipped_date  ? <i title="Received for shipment" className="fa fa-info-circle"/>: "" }</p>
        },
            {
                Header: "Bl Status",
                accessor: "bl_status",
                Cell: props => <p className="text-muted">{props.original.show_issue_bl != '1' ? blStatus[props.original.prev_bl_status] : blStatus[props.value]}</p>,
                Filter: ({filter, onChange}) => (
                    <Select
                        className="react-table-filter"
                        options={this.state.blStatusOptions}
                        isClearable={true}
                        name="bl_status"
                        placeholder="BL status"
                        onChange={(selected) => onChange(selected ? selected.value : "")}
                        noOptionsMessage={() => "No record found"}
                    />
                )
            },
            {
                Header: "Vendor Name",
                accessor: "customer_name",
                Cell: props => <p
                    className="text-muted">{props.value}</p>
            },
            {
                Header: "POL",
                accessor: "pol",
                Cell: props => <p
                    className="text-muted">{props.value}</p>
            },
            {
                Header: "POD",
                accessor: "pod",
                Cell: props => <>
                    <p className="text-muted">{props.value}</p><br/>
                    </>
            },
            {
                Header: "Shipping Line",
                accessor: "shipline",
                Cell: props => <p
                    className="text-muted">{props.value}</p>
            },
            {
                Header: "Action",
                accessor: "action",
                filterable: false,
                sortable: false,
                Cell: props => <p className="text-muted">
                    <Link to={'/bl/edit-bl/' + props.original.id}>
                                                    <span
                                                        className="edit_icon badge badge-pill badge-secondary handle mr-1">
                                                        {(props.original.bl_status === USER_APPROVED || props.original.bl_status ===ISSUED) ?
                                                            <i className="fa fa-eye" title="View BL"/> :
                                                            <i className="fa fa-pencil-square-o" title="Edit BL"/>
                                                        }

                                                    </span>
                    </Link>
                    &nbsp; &nbsp;
                    {props.original.show_issue_bl == '1' && <>
                    <a onClick={() => {this.handleBlPdf(props.original)}}>
                        <span className="fa fa-download badge-secondary" title="Download BL PDF"/>
                    </a>&nbsp; &nbsp;
                    {props.original.invoice_id !== null ?
                        <a onClick={() => {this.handleInvoicePdf(props.original, 0)}}>
                            <span className="fa fa-download badge-secondary " title="Download Tax Invoice PDF"/>
                        </a> : null}
                    {props.original.invoice_id !== null ?
                        <a onClick={() => {this.handleInvoicePdf(props.original, 1)}}>
                            <span className="fa fa-download badge-secondary" title="Download Non Tax Invoice PDF"/>
                        </a> : null}</>}
                </p>
            }

        ]
    }

    componentDidMount() {
        let blStatusOptions = [];
        for (let status in blStatus) {
            blStatusOptions.push({label: blStatus[status], value: status, key: status});
        }
        this.setState({blStatusOptions});

        let storageMessage = Storage.getFlash('success_message');
        if (storageMessage) {
            NotificationManager.success('Success message', storageMessage);
        }
    }

    fetchBlData = (state, instance) => {
        let {blData} = this.state;
        blData = {...blData, loading: true};
        let reactTable = {state, instance};

        this.setState({blData, reactTable});
        let customFilters = [];

        let payload = {
            pageSize: state.pageSize,
            page: state.page,
            sorted: JSON.stringify(state.sorted),
            filtered: JSON.stringify(state.filtered),
            custom_filters: JSON.stringify(customFilters)
        };
        Request.post(`${API_URL}user/draft_bl/bl-list`, payload)
            .then(response => {
                if (response !== undefined) {
                    let blData = {
                        data: response.data.data.map(bl => {

                            return {
                                ...bl
                                //  bl_status: blStatus[bl.bl_status]
                            };
                        }),
                        pages: response.data.total,
                        loading: false,
                    };
                    this.setState({blData});
                }
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    handleBlPdf = (bl) => {
        return axios.get(API_URL + 'user/bl-pdf/' + bl.id, {responseType: 'blob'})
            .then((response) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('download', 'BL_PDF_' + bl.id + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    handleInvoicePdf = (bl, key) => {
        return axios.get(API_URL + 'user/invoice-pdf/' + bl.invoice_id +'/'+key, {responseType: 'blob'})
            .then((response) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('download', 'INVOICE_PDF_' + bl.invoice_id + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    render() {
        return (
            <Fragment>

                <div className="heading-bill-lading mtb-10px">
                    <h1>Bill of Lading </h1>
                    <Link to="/bl/draft-bl">Create New BL</Link>
                </div>
                <div className="container">
                    <div className="row">
                        <Col lg="12" sm="12">
                            <div className="bill-of-lading-data">

                                <ReactTable
                                    columns={this.state.blListingColumns}
                                    manual={true}
                                    pages={this.state.blData.pages}
                                    onFetchData={this.fetchBlData}
                                    noDataText={"No Record found"}
                                    loading={this.state.blData.loading}
                                    className={"row-overflow"}
                                    data={this.state.blData.data}
                                    defaultPageSize={10}
                                    filterable={true}
                                    sortable={false}
                                    showPageJump={true}
                                    PaginationComponent={DataTablePagination}
                                    showPageSizeOptions={true}
                                    defaultFilterMethod={filterCaseInsensitive}/>

                            </div>
                        </Col>
                    </div>
                </div>

                <NotificationContainer/>
            </Fragment>
        );
    }

}

export default BlList;