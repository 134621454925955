import React, {Component, Fragment} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {slideDown, slideUp} from "../../routes/desktop/bl/anim";
import ContainerTableFormRow from "./ContainerTableFormRow";
import {AvField, AvForm} from "availity-reactstrap-validation";

class AddContainerModal extends Component {

    state = {
        expanded: false,
        formLoading: false,
        containerSelected: [],
        container_count_20: 0,
        container_count_40: 0,
        container_count_40hc: 0,
        container_value_20: null,
        container_value_40: null,
        container_value_40hc: null
    };

    componentDidMount() {
        this.setState({
            container_count_20: this.props.container_count_20,
            container_count_40: this.props.container_count_40,
            container_count_40hc: this.props.container_count_40hc,
            container_value_20: this.props.container_value_20,
            container_value_40: this.props.container_value_40,
            container_value_40hc: this.props.container_value_40hc
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.container_count_20 !== this.props.container_count_20 || prevProps.container_count_40 !== this.props.container_count_40 || prevProps.container_count_40hc !== this.props.container_count_40hc) {
            this.setState({
                container_count_20: this.props.container_count_20,
                container_count_40: this.props.container_count_40,
                container_count_40hc: this.props.container_count_40hc,
                container_value_20: this.props.container_value_20,
                container_value_40: this.props.container_value_40,
                container_value_40hc: this.props.container_value_40hc
            });
        }
    }

    toggleAddModal = () => {
        this.setState({containerSelected: []});
        this.props.toggleAddModal();
    }

    cancelModal = () => {
        this.setState({containerSelected: []});
        this.props.cancelModal();
    }

    toggleExpander = (event) => {
        if (event.target.checked) {
            this.setState(
                {expanded: true},
                () => {
                    if (this.refs.expanderBody) {
                        slideDown(this.refs.expanderBody);
                    }
                }
            );
        } else {
            slideUp(this.refs.expanderBody, {
                onComplete: () => {
                    this.setState({expanded: false});
                }
            });
        }
    }

    containerSelectHandler = (event, container) => {
        /*
       * only those containers with the same freight charges as the selected containers should be selectable and rest of the containers should get disabled.
       */
        let container_value_20 = this.state.container_value_20,
            container_value_40 = this.state.container_value_40,
            container_value_40hc = this.state.container_value_40hc;
        let container_count_20 = this.state.container_count_20,
            container_count_40 = this.state.container_count_40,
            container_count_40hc = this.state.container_count_40hc;
        let containers = [];
        if (event.target.checked === true) {
            if (container.container_size === "20") {
                container_value_20 = container.freight_value;
                container_count_20++;
            } else if (container.container_size === "40") {
                container_value_40 = container.freight_value;
                container_count_40++;
            } else if (container.container_size === "40hc") {
                container_value_40hc = container.freight_value;
                container_count_40hc++;
            }
            containers = this.state.containerSelected;
            containers.push(container.id);
            this.setState({containerSelected: containers});
        } else {
            if (container.container_size === "20") {
                container_count_20--;
                if (container_count_20 === 0) {
                    container_value_20 = null;
                }
            } else if (container.container_size === "40") {
                container_count_40--;
                if (container_count_40 === 0) {
                    container_value_40 = null;
                }
            } else if (container.container_size === "40hc") {
                container_count_40hc--;
                if (container_count_40hc === 0) {
                    container_value_40hc = null;
                }
            }
            containers = [];
            this.state.containerSelected.filter(function (value, index, arr) {
                if (value !== container.id) {
                    containers.push(value);
                }
            });
        }
        this.setState({
            containerSelected: containers,
            container_count_20,
            container_count_40,
            container_count_40hc,
            container_value_20,
            container_value_40,
            container_value_40hc
        });
        this.props.setParentState('container_count_20', container_count_20);
        this.props.setParentState('container_count_40', container_count_40);
        this.props.setParentState('container_count_40hc', container_count_40hc);
        this.props.setParentState('container_value_20', container_value_20);
        this.props.setParentState('container_value_40', container_value_40);
        this.props.setParentState('container_value_40hc', container_value_40hc);
    };

    addContainers = () => {
        this.props.addContainers(this.state.containerSelected);
        this.setState({containerSelected: []});
    };

    handleBookingNoFilter = (event) => {
        if (event.target.value === "" || event.target.value.length >= 4) {
            this.props.filterByBookingNo(event.target.value);
        }
    };

    render() {
        return (
            <Fragment>

                <Modal
                    isOpen={this.props.modalContainerOpen}
                    toggle={this.toggleAddModal}
                    wrapClassName="modal-right"
                    className="bank_modal"
                    backdrop="static"
                >
                    <ModalHeader toggle={this.toggleAddModal}>
                        <h4> Add Booking Containers</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="slide-table">
                            <div className="row">
                                <div className="col-lg-12 col-sm-12 new-bl-pop">
                                    <div className="note-text-line">
                                        <p>The booking container list is based on the selected POL, POD , Shipline,
                                            Customer name, Container type and commodity at the time of creating BL</p>
                                    </div>
                                    <AvForm>
                                        <AvField type="text" name="booking_id" id="booking_id" value=""
                                                 placeholder="Search by Booking Number"
                                                 onChange={(event) => this.handleBookingNoFilter(event)}/>
                                    </AvForm>
                                    <table
                                        className="uk-table uk-table-hover  uk-table-middle uk-table-divider">
                                        <thead>
                                        <tr>
                                            <th/>
                                            <th className="uk-table-shrink">Shipment Ref No</th>
                                            <th>POL</th>
                                            <th>POD</th>
                                            <th>ETD at POL</th>
                                            <th>ETA at POD</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.props.containersData && this.props.containersData.length  ?
                                            this.props.containersData.map((container, index) =>
                                                <ContainerTableFormRow expanded={this.state.expanded}
                                                                       container_value_20={this.state.container_value_20}
                                                                       OriginalContainers={this.props.OriginalContainers}
                                                                       container_value_40={this.state.container_value_40}
                                                                       container_value_40hc={this.state.container_value_40hc}
                                                                       bl_id={this.props.bl_id}
                                                                       key={index}
                                                                       totalContainers={this.props.totalContainers}
                                                                       containerSelected={this.state.containerSelected}
                                                                       containerSelectHandler={this.containerSelectHandler}
                                                                       index={index + 1}
                                                                       toggleExpander={this.toggleExpander}
                                                                       container={container}/>
                                            )
                                            :
                                            <tr>
                                                <td colSpan={6} className="uk-text-center"><em
                                                    className="uk-text-muted">Containers are not available for this
                                                    route.</em></td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="add-new-con">
                        <a onClick={this.addContainers} className="btn btn-primary mr-5px" size="sm">
                            Submit
                        </a>
                        <a className="btn btn-primary" size="sm" onClick={this.props.cancelModal}>
                            Cancel
                        </a>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }

}

export default AddContainerModal;