import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {maxUploadFileSize} from "../../../constants/defaultValues";
import Request from "../../../util/api.service";
import Auth from "../../../util/auth.service";
import {API_URL} from "../../../constants/apiConstants";
import {Col, Row} from "reactstrap";
import {updateUser} from "../../../redux/actions";
import {NotificationManager} from "react-notifications";

class uploadDocument extends Component {

    state = {
        docError: null,
        docName: 'Upload GST document',
        doc: null,
        gstDetails: []
    };

    componentDidMount() {
        this.getCompanyDetails();
    }

    getCompanyDetails = () => {
        Request.get(API_URL + '/user/profile').then(response => {
            if (response !== undefined) {
                if (response.data.status === "success") {
                    if (response.data.data.company.account_details.gst) {
                        this.setState({gstDetails: response.data.data.company.account_details.gst});
                    }
                }
            }
        }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    }

    getFilename = () => {
        var imagefile = document.querySelector('#doc');
        this.setState({docError: null});
        if (imagefile.files[0]) {
            var parts = imagefile.files[0].name.split('.');
            let allowed_extensions = ['pdf'];
            if (allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1) {
                if (imagefile.files[0].size <= maxUploadFileSize) {
                    this.setState({
                        doc: imagefile.files[0] ? imagefile.files[0] : '',
                        docName: imagefile.files[0] ? imagefile.files[0].shortName(30) : 'Upload Doc',
                        docError: null
                    });
                } else {
                    this.setState({docError: "The uploaded file is exceeding the maximum file size of 2 MB."});
                }
            } else {
                this.setState({docError: "Please select valid file."});
            }
        }
    };

    uploadDocument = () => {
        if (this.state.doc !== null) {
            let user = Auth.user;
            let data = {
                doc: this.state.doc,
                company_id: user.company.id,
            }
            Request.post(API_URL + '/user/profile/upload-doc', data).then(response => {
                if (response !== undefined) {
                    if (response.data.status === "success") {
                        Auth.user = response.data.data;
                        this.props.updateUser(response.data.data);
                        this.setState({gstDetails: response.data.data.company.account_details.gst});
                    }
                }
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        } else {
            this.setState({docError: "Please select file."});
        }
    };

    render() {
        return (
            <Fragment>
                <div className="">
                    <div className="add-line-bg">
                        <h1>Upload Document</h1>
                    </div>
                    <br/>
                    <Row className={'mt-2'}>
                        <Col lg="4" sm="4">
                            <div className="custom-file">
                                <input type="file" id="doc" name="doc"
                                       className="custom-file-input"
                                       onChange={this.getFilename} multiple/>
                                <label className="custom-file-label"
                                       htmlFor="vgm_doc">{this.state.docName}</label>

                            </div>
                            <label
                                className={(this.state.docError !== null) ? "alert-wr-bk text-red" : "alert-wr text-red"}>{this.state.docError}</label>
                        </Col>
                        <Col lg="4" sm="4">
                            <button onClick={this.uploadDocument} className="btn primary-btn">Submit</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>Legal Name</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails.legal_name : ""}</b>
                            </div>
                        </Col>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>GST No</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails.gst_no : ""}</b>
                            </div>
                        </Col>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>GST Address</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails.gst_address : ""}</b>
                            </div>
                        </Col>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>Type</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails.type : ""}</b>
                            </div>
                        </Col>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>Trade Name</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails.trade_name : ""}</b>
                            </div>
                        </Col>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>Valid From</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails.valid_from : ""}</b>
                            </div>
                        </Col>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>Valid To</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails.valid_to : ""}</b>
                            </div>
                        </Col>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>Issued At</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails.issued_at : ""}</b>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }

}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(
    mapStateToProps,
    {updateUser}
)(uploadDocument);
