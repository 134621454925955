import React, { Component, Fragment } from "react";
import { button, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import Select from "react-select";
import Auth from "../../util/auth.service";
import { AWS_S3_URL } from "../../constants/apiConstants";
import { expoterTypes } from "../../constants/expoterTypes";
import { gstnTypes } from "../../constants/gstnTypes";   
import {
  contactType,
  SHIPPER,
  CONSIGNEE,
  NOTIFY_PARTY,
  BOOKING_PARTY,
  CHA,
  FORWARDER,
  INVOICE_PARTY,
} from "../../constants/contactType";
import Common from "../../util/common.service";
import { maxUploadFileSize } from "../../constants/defaultValues";
import ButtonLoader from "../../util/ButtonLoader";
import CustomFeedback from "../formValidations/CustomFeedback";
import PdfIcon from "../../assets/images/pdf-icon.png";

class ContactModal extends Component {
  state = {
    modalOpen: false,
    action: true,
    companyLogoName: "Only PDF, JPEG, PNG files accepted",
    companyLogoError: null,
    companyLogo: null,
    countrySelectError: null,
    stateSelectError: null,
    citySelectError: null,
    address: [],
    countryList: [],
    cityList: [],
    stateList: [],
    tags: [],
    country: null,
    state: null,
    city: null,
    VGMauthorizationLetterName: "Select File",
    VGMauthorizationLetterExistingFile: null,
    VGMauthorizationLetterDoc: null,
    VGMauthorizationLetterError: null,
    gstFile: null,
    gstFileName: null,
    gstFileError: null,
    panCard: null,
    panCardName: null,
    panCardError: null,
    utilityBills: [],
    utilityBillList: [],
    utilityBillExistingList: [],
    deletedUtilityBills: [],
    utilityBillError: null,
    contcatTypeLabel: null,
    pan_no: null,
    gstCount: [],
    firstGst: null,
    editForm: false,
    gstMasterCount: null
  };

  componentDidMount() {
    console.log(this.props.action);
    let state = null,
      city = null,
      country = null,
      stateList = this.props.stateList,
      cityList = this.props.cityList;

    if (this.props.gstDetails) {
      this.setState({ gstMasterCount: this.props.address.gst_details.length });
      let gstCountData = this.props.address.gst_details;
      let tax_id = gstCountData.pop();
      if (tax_id) {
        this.setState({ firstGst: tax_id.gst_no });
      }
      this.setState({ gstCountData });
      let gstCount = [];
      gstCountData.map((data, key) => gstCount.push(key + 1));
      this.setState({ gstCountData, gstCount });
      this.setState({ editForm: true });
    }

    if (this.props.address && this.props.address.state) {
      state = {
        label: this.props.address.state.name,
        value: this.props.address.state.id,
        key: this.props.address.state.id,
      };
    }

    if (this.props.address && this.props.address.city) {
      city = {
        label: this.props.address.city.name,
        value: this.props.address.city.id,
        key: this.props.address.city.id,
      };
    }
    if (this.props.address && this.props.address.country) {
      country = {
        label: this.props.address.country.name,
        value: this.props.address.country.id,
        key: this.props.address.country.id,
      };
    } else {
      country = this.props.country;
    }

    if(!this.props.action && this.props.address.quotation_email !== null) {
      if(JSON.parse(this.props.address.quotation_email).length > 0) {
        let quotation_emails = JSON.parse(this.props.address.quotation_email)
        this.setState({tags:quotation_emails});
      }
    }

    let contactTypeValue = this.props.selectedContactType;
    let utilityBillExistingList =
      this.props.address &&
      this.props.address.kyc_documents &&
      this.props.address.kyc_documents["utility_bill"]
        ? this.props.address.kyc_documents["utility_bill"]
        : [];

    this.setState({
      contactTypeValue,
      countryList: this.props.countryList,
      stateList,
      cityList,
      modalOpen: this.props.modalOpen,
      address: this.props.address,
      action: this.props.action,
      utilityBillExistingList,
      state,
      city,
      country,
      contcatTypeLabel:
        contactTypeValue === 2 || contactTypeValue === "3"
          ? "Tax Id"
          : "Gst Number",
    });
  }

  toggleAddModal = () => {
    this.props.toggleAddModal();
  };

  handleChange = (selected, type) => {
    if (type === "country") {
      Common.stateByCountry(selected.value).then((response) => {
        if (response !== undefined) {
          let stateList = [];
          let state_list = response.data.data;
          for (let state in state_list) {
            stateList.push({
              label: state_list[state].name,
              value: state_list[state].id,
              key: state_list[state].id,
            });
          }
          this.setState({[type]: selected, stateList, city: null, state: null});
        }
      });
    } else if (type === "state") {
      Common.cityByState(selected.value).then((response) => {
        if (response !== undefined) {
          let cityList = [];
          let city_list = response.data.data;
          for (let city in city_list) {
            cityList.push({
              label: city_list[city].name,
              value: city_list[city].id,
              key: city_list[city].id,
            });
          }
          this.setState({[type]: selected, cityList, city: null});
        }
      });
    } else {
      this.setState({ [type]: selected });
    }
  };

  addTag = (e) => {
    let expr =
      /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,3}|[0-9]{1,3})(\]?)$/;
    if (e.keyCode === 32 && expr.test(e.target.value)) {
      if (e.target.value.length > 0 && expr.test(e.target.value)) {
        let newTags = this.state.tags;
        newTags.push(e.target.value);
        this.setState(["tags", newTags]);
        e.target.value = "";
      }
    }
  };

  removeTag = (removedTag) => {
    var array = [...this.state.tags]; // make a separate copy of the array
    var index = array.indexOf(removedTag);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ tags: array });
    }
  };

  getCompanyLogo = () => {
    var company_logo = document.querySelector("#company_logo");
    if (company_logo.files[0]) {
      let allowed_extensions = ["pdf", "jpeg", "jpg", "png"];
      let parts = company_logo.files[0].name.split(".");
      let file_extension = parts[parts.length - 1];
      let companyLogoName = "Only PDF, JPEG, PNG files accepted";
      let companyLogoError = null;
      let companyLogo = null;
      if (allowed_extensions.indexOf(file_extension.toLowerCase()) !== -1) {
        if (company_logo.files[0].size <= maxUploadFileSize) {
          companyLogo = company_logo.files[0];
          companyLogoName = company_logo.files[0].name;
        } else {
          companyLogoError =
            "The uploaded file is exceeding the maximum file size of 5 MB.";
        }
      } else {
        companyLogoError = "Please select a valid document file";
      }
      this.setState({ companyLogoName, companyLogo, companyLogoError });
    }
  };

  handleContactType = (event) => {
    this.setState({
      contactTypeValue: parseInt(event.target.value),
      contcatTypeLabel:
        event.target.value == "2" || event.target.value == "3"
          ? "Tax Id"
          : "Gst Number",
    });
  };

  onSubmit = (event, errors, values) => {
    let user = Auth.user;
    let companyLogoError = null,
      VGMauthorizationLetterError = null;

    if (
      (this.state.contactTypeValue === SHIPPER &&
        user.company.company_type === 2 &&
        this.state.VGMauthorizationLetterDoc === null &&
        !this.state.address) ||
      (this.state.contactTypeValue === SHIPPER &&
        user.company.company_type === 2 &&
        this.state.VGMauthorizationLetterDoc === null &&
        this.state.address &&
        !this.state.address.authorization_letter)
    ) {
      VGMauthorizationLetterError = "Please select Authorization letter";
      errors.push(VGMauthorizationLetterError);
    }

    this.setState({ companyLogoError, VGMauthorizationLetterError });

    if (errors.length === 0 && !this.props.modalLoading) {
      let gst_numbers = [];
      for (let value in values) {
        if (value.includes("tax_id_") && values[value] !== "") {
          gst_numbers.push(values[value]);
        }
      }

      let data = {
        ...values,
        company_logo: this.state.companyLogo ? this.state.companyLogo : "",
        auth_letter: this.state.VGMauthorizationLetterDoc
          ? this.state.VGMauthorizationLetterDoc
          : "",
        country_id: this.state.country ? this.state.country.value : "",
        state_id: this.state.state ? this.state.state.value : "",
        city_id: this.state.city ? this.state.city.value : "",
        created_by: Auth.user.id,
        company_id: Auth.user.company.id,
        pan_card: this.state.panCard ? this.state.panCard : "",
        gst_file: this.state.gstFile ? this.state.gstFile : "",
        deleted_bills: this.state.deletedUtilityBills
          ? JSON.stringify(this.state.deletedUtilityBills)
          : "",
        gst_numbers,
        quotation_email:JSON.stringify(this.state.tags)
      };

      if (this.state.utilityBills) {
        for (let file in this.state.utilityBills) {
          data["utility_bill_" + file] = this.state.utilityBills[file];
        }
      }

      if (this.state.action) {
        this.props.addAddress(data);
      } else {
        this.props.updateAddress(data, this.state.address.id);
      }
    }
  };

  getFilename = (file, docName, errorName, fileVariable, existingFile) => {
    var imagefile = document.querySelector("#" + file);
    this.setState({ VGMauthorizationLetterError: null });
    if (imagefile.files[0]) {
      var parts = imagefile.files[0].name.split(".");
      let file_extension = parts[parts.length - 1];
      let allowed_extensions = ["pdf"];
      if (
        allowed_extensions.indexOf(file_extension.toLowerCase()) !== -1) {
        if (imagefile.files[0].size <= maxUploadFileSize) {
          console.log(fileVariable,docName)
          this.setState({
            [fileVariable]: imagefile.files[0] ? imagefile.files[0] : "",
            [docName]: imagefile.files[0]
              ? imagefile.files[0].shortName(30)
              : "Upload VGM",
            [errorName]: null,
            [existingFile]: null,
          });
        } else {
          this.setState({
            [errorName]:
              "The uploaded file is exceeding the maximum file size of 2 MB.",
          });
        }
      } else {
        this.setState({ [errorName]: "Please select valid file." });
      }
    }
  };

  uploadUtilityBill = () => {
    var imagefile = document.querySelector("#utility_bill");
    let allowed_extensions = [
      "pdf",
      "jpeg",
      "jpg",
      "png",
      "xlsx",
      "doc",
      "docx",
    ];
    var utilityBills = this.state.utilityBills;
    let fileError = null;
    for (let fileKey in imagefile.files) {
      let file = imagefile.files[fileKey];
      if (file && file.name) {
        var parts = file.name.split(".");
        if (
          allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !==
          -1
        ) {
          if (file.size <= maxUploadFileSize) {
            utilityBills.push(file);
          } else {
            fileError =
              "The uploaded file is exceeding the maximum file size of 5 MB.";
          }
        } else {
          // fileError = "Please select valid file.";
        }
      }
    }
    this.setState(
      { utilityBills, utilityBillError: fileError },
      this.handleUtilityListing
    );
  };

  handleUtilityListing = () => {
    let utilityBillList = [];
    if (this.state.utilityBills) {
      for (let file in this.state.utilityBills) {
        utilityBillList.push(
          <li key={file}>
            <a href="" target="_blank" rel="noopener noreferrer">
              <img src={PdfIcon} alt={""} />
              {this.state.utilityBills[file].name}
            </a>
            <i
              onClick={() => this.deleteUtilityBill(file, "utilityBills")}
              className="fa fa-times color-changes"
            />
          </li>
        );
      }
    }
    this.setState({ utilityBillList });
  };

  deleteUtilityBill = (key, name) => {
    let deletedUtilityBills = this.state.deletedUtilityBills;
    if (name === "utilityBillExistingList") {
      deletedUtilityBills.push(key);
    }
    let utilityBills = [];
    for (let doc in this.state[name]) {
      if (doc !== key) {
        utilityBills.push(this.state[name][doc]);
      }
    }
    this.setState(
      { [name]: utilityBills, deletedUtilityBills },
      this.handleUtilityListing
    );
  };

  uploadFiles = (fileName, files, fileListing, fileError) => {
    var imagefile = document.querySelector("#" + fileName);
    let fileData = this.state[files];
    let listing = this.state[fileListing];
    if (imagefile.files[0]) {
      var parts = imagefile.files[0].name.split(".");
      let allowed_extensions = ["pdf"];
      if (
        allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1
      ) {
        if (imagefile.files[0].size <= maxUploadFileSize) {
          let listing = (
            <li>
              <a href="" target="_blank" rel="noopener noreferrer">
                {/*<img src={PdfIcon} alt={""}/>*/}
                {imagefile.files[0].shortName(30)}
              </a>
            </li>
          );
          console.log(files, fileListing);
          this.setState({
            [files]: imagefile.files[0] ? imagefile.files[0] : "",
            [fileListing]: imagefile.files[0]
              ? imagefile.files[0].shortName(30)
              : "Upload File",
            [fileError]: null,
          });
        } else {
          this.setState({
            [fileError]:
              "The uploaded file is exceeding the maximum file size of 5 MB.",
          });
        }
      } else {
        this.setState({ [fileError]: "Please select valid file." });
      }
    }
  };

  addGstField = () => {
    if (this.state.editForm) {
      let gstCountData = this.state.gstCountData;
      gstCountData.push(1);
      this.setState(gstCountData);
    }
    let gstCount = this.state.gstCount;
    if (gstCount.length) {
      let last = gstCount[gstCount.length - 1];
      gstCount.push(parseInt(last) + parseInt(1));
    } else {
      gstCount.push(1);
    }
    this.setState({ gstCount });
  };

  removeGstField = (count) => {
    let gstCount = [];
    if (this.state.editForm) {
      let gstCountData = this.state.gstCountData;
      gstCountData.pop();
      this.setState(gstCountData);
    }
    this.state.gstCount.filter((value, i) => {
      if (value !== count) {
        gstCount.push(value);
      }
    });
    this.setState({ gstCount });
  };

  render() {
    let user = Auth.user;
    let contactTypes = [<option value="">Select Contact Type</option>];
    for (let type in contactType) {
      if(user.company.company_type == 1 && contactType[type] == "Shipper"){
      }else{
        contactTypes.push(<option value={type}>{contactType[type]}</option>);
      }
      
    }
    
    let gstnType = [<option value="">Select GSTN Types</option>];
    for (let gstn in gstnTypes) {
      gstnType.push(<option value={gstn}>{gstnTypes[gstn]}</option>);
    }
    let expoterType = [<option value="">Select Expoter Type</option>];
    for (let expoter in expoterTypes) {
      expoterType.push(<option value={expoter}>{expoterTypes[expoter]}</option>);
    }

    let utilityBillExistingList = [];
    if (this.state.utilityBillExistingList) {
      for (let utility_bill in this.state.utilityBillExistingList) {
        var fileName =
          this.state.utilityBillExistingList[utility_bill].split("/");
        utilityBillExistingList.push(
          <li key={utility_bill}>
            <a
              href={
                AWS_S3_URL + this.state.utilityBillExistingList[utility_bill]
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={PdfIcon} alt={""} />
              {fileName[fileName.length - 1]}
            </a>
            <i
              onClick={() =>
                this.deleteUtilityBill(utility_bill, "utilityBillExistingList")
              }
              className="fa fa-times color-changes"
            />
          </li>
        );
      }
    }

    let gstFields = [];

    if (this.state.editForm) {
      let counter = 1;
      for (let count of this.state.gstCountData) {
        gstFields.push(
          <div className="col-md-8" key={counter}>
            <AvGroup className="error-t-negative input-change">
              <AvField
                name={"tax_id_" + counter}
                type="text"
                value={count.gst_no}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "enter gst number",
                  },
                }}
              />
              <a onClick={() => this.removeGstField(counter)}>
                <i className="fa fa-times" aria-hidden="true" />
              </a>
            </AvGroup>
          </div>
        );
        counter = counter + 1;
      }
    } else {
      for (let count of this.state.gstCount) {
        gstFields.push(
          <div className="col-md-12" key={count}>
            <div className="gst-field">
              <AvGroup className="error-t-negative input-change">
                <AvField
                  name={"tax_id_" + count}
                  type="text"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "enter gst number",
                    },
                  }}
                />
                <a onClick={() => this.removeGstField(count)}>
                  <i className="fa fa-times" aria-hidden="true" />
                </a>
              </AvGroup>
            </div>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggleAddModal}
          wrapClassName="modal-right"
          backdrop="static"
          className="add-address-popoup"
        >
          <ModalHeader toggle={this.toggleAddModal}>
            {this.state.action ? <h2>Add Address</h2> : <h2>Edit Address</h2>}
          </ModalHeader>
          <AvForm onSubmit={this.onSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <AvGroup>
                      <label>
                        Select Contact Type <span className="required">*</span>
                      </label>
                      <AvField
                          type="select"
                          name="type"
                          id="type"
                          onChange={this.handleContactType}
                          placeholder="Select Contact Type"
                          value={
                            this.state.address && this.state.address.type
                                ? this.state.address.type
                                : this.props.selectedContactType
                          }
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please select Contact Type",
                            },
                          }}
                      >
                        {contactTypes}
                      </AvField>
                    </AvGroup>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <AvGroup>
                      <label>
                        Company Name <span className="required">*</span>
                      </label>
                      <AvField
                          type="text"
                          id="company_name"
                          name="company_name"
                          value={
                            this.state.address && this.state.address.company_name
                                ? this.state.address.company_name
                                : ""
                          }
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter Company Name",
                            },
                          }}
                      />
                    </AvGroup>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <AvGroup>
                      <label>
                        Contact Person <span className="required">*</span>
                      </label>
                      <AvField
                          type="text"
                          id="contact_person"
                          name="contact_person"
                          value={
                            this.state.address &&
                            this.state.address.contact_person
                                ? this.state.address.contact_person
                                : ""
                          }
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter Contact Person",
                            },
                          }}
                      />
                    </AvGroup>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <AvGroup>
                      <label>Mobile</label>
                      <AvField
                          type="text"
                          id="mobile"
                          name="mobile"
                          value={
                            this.state.address && this.state.address.mobile
                                ? this.state.address.mobile
                                : ""
                          }
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'This is required field'
                            },
                            pattern: {
                              value: '/^\\d{10}$/gm',
                              errorMessage: 'Mobile number should 10 digit only'
                            },
                          }}
                      />
                    </AvGroup>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <AvGroup>
                      <label>Email</label>
                      <AvField
                          type="text"
                          id="email"
                          name="email"
                          value={
                            this.state.address && this.state.address.email
                                ? this.state.address.email
                                : ""
                          }
                          validate={{
                            required: {
                              value:
                                  this.state.contactTypeValue === CONSIGNEE ||
                                  this.props.selectedContactType === CONSIGNEE,
                              errorMessage: "Please enter Email.",
                            },
                            email: {
                              value: true,
                              errorMessage: "Please enter valid Email.",
                            },
                          }}
                      />
                    </AvGroup>
                  </div>
                </div>
                {this.props.selectedContactType === SHIPPER ||
                this.state.contactTypeValue === SHIPPER ||
                this.props.selectedContactType === BOOKING_PARTY ||
                this.state.contactTypeValue === BOOKING_PARTY ||
                this.props.selectedContactType === CHA ||
                this.state.contactTypeValue === CHA ||
                this.props.selectedContactType === FORWARDER ||
                this.state.contactTypeValue === FORWARDER ||
                this.props.selectedContactType === INVOICE_PARTY ||
                this.state.contactTypeValue === INVOICE_PARTY ? (
                    <Fragment>
                      <div className="col-lg-4 col-sm-4">
                        <div className="form-group">
                          <AvGroup>
                            <label>Cro Email</label>
                            <AvField
                                type="text"
                                id="cro_email"
                                name="cro_email"
                                value={
                                  this.state.address && this.state.address.cro_email
                                      ? this.state.address.cro_email
                                      : ""
                                }
                                validate={{
                                  email: {
                                    value: true,
                                    errorMessage: "Please enter valid Email.",
                                  },
                                }}
                            />
                          </AvGroup>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4">
                        <div className="form-group">
                          <AvGroup>
                            <label>Invoice Email</label>
                            <AvField
                                type="text"
                                id="invoice_email"
                                name="invoice_email"
                                value={
                                  this.state.address &&
                                  this.state.address.invoice_email
                                      ? this.state.address.invoice_email
                                      : ""
                                }
                                validate={{
                                  email: {
                                    value: true,
                                    errorMessage: "Please enter valid Email.",
                                  },
                                }}
                            />
                          </AvGroup>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4">
                        <div className="form-group">
                          <AvGroup>
                            <label>BL Email</label>
                            <AvField
                                type="text"
                                id="bl_email"
                                name="bl_email"
                                value={
                                  this.state.address && this.state.address.bl_email
                                      ? this.state.address.bl_email
                                      : ""
                                }
                                validate={{
                                  email: {
                                    value: true,
                                    errorMessage: "Please enter valid Email.",
                                  },
                                }}
                            />
                          </AvGroup>
                        </div>
                      </div>
                    </Fragment>
                ) : null}
                {this.props.selectedContactType === SHIPPER ||
                this.state.contactTypeValue === SHIPPER ? <Fragment>
                  <div className="col-lg-4 col-sm-4">
                    <div className="form-group">
                      <AvGroup>
                        <label>Quotation Email</label>
                        <div className="tag-container">
                          {this.state.tags.map((cro, croIndex) => {
                            return (
                                <div key={"cro" + croIndex} className="tag">
                                  {cro}{" "}
                                  <span onClick={() => this.removeTag(cro)}>
                                    x
                                </span>
                                </div>
                            );
                          })}

                          <AvField
                              onKeyDown={this.addTag}
                              id="cro_email"
                              name="cro_email"
                              validate={{
                                email: {
                                  value: true,
                                  errorMessage: "Please enter valid Email.",
                                },
                              }}
                          />
                        </div>
                      </AvGroup>
                    </div>
                  </div>
                </Fragment> : null}
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <AvGroup>
                      <label>
                        Address <span className="required">*</span>
                      </label>
                      <AvField
                          type="textarea"
                          id="address"
                          name="address"
                          value={
                            this.state.address && this.state.address.address
                                ? this.state.address.address
                                : ""
                          }
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter Address",
                            },
                          }}
                      />
                    </AvGroup>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Select Country </label>
                    <Select
                        className="home-select"
                        name="country"
                        value={this.state.country}
                        placeholder="Select Country"
                        noOptionsMessage={() => "No record found"}
                        onChange={(selected) =>
                            this.handleChange(selected, "country")
                        }
                        options={this.state.countryList}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Select State</label>
                    <Select
                        className="home-select"
                        name="state"
                        value={this.state.state}
                        placeholder="Select State"
                        onChange={(selected) =>
                            this.handleChange(selected, "state")
                        }
                        options={this.state.stateList}
                        noOptionsMessage={() => "No record found"}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Select City</label>
                    <Select
                        className="home-select"
                        name="city"
                        value={this.state.city}
                        placeholder="Select City"
                        onChange={(selected) =>
                            this.handleChange(selected, "city")
                        }
                        options={this.state.cityList}
                        noOptionsMessage={() => "No record found"}
                    />
                  </div>
                </div>
                {(this.props.selectedContactType === FORWARDER || this.state.contactTypeValue === FORWARDER || this.props.selectedContactType === CHA || this.state.contactTypeValue === CHA || this.props.selectedContactType === SHIPPER || this.state.contactTypeValue === SHIPPER) && (
                          <div className="col-lg-4 col-sm-4">
                            <div className="form-group">
                              <AvGroup>
                                <label>
                                  GSTN Type.{" "}
                                  <span className="required">*</span>
                                </label>
                                <AvField
                                    type="select"
                                    id="gstn_type"
                                    name="gstn_type"
                                    value={
                                      this.state.address &&
                                      this.state.address.gstn_type
                                          ? this.state.address.gstn_type
                                          : ""
                                    }
                                >
                                  {gstnType}
                                </AvField>
                              </AvGroup>
                            </div>
                          </div>)
                }
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <AvGroup>
                      <label>{this.state.contcatTypeLabel} </label>
                      <AvField
                          type="text"
                          id="tax_id"
                          name="tax_id"
                          value={
                            this.state.firstGst
                                ? this.state.firstGst
                                : this.state.address && this.state.address.tax_id
                                    ? this.state.address.tax_id
                                    : ""
                          }
                      />
                    </AvGroup>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="row">
                    <div className="col-12 gst-field">
                      <a
                          onClick={this.addGstField}
                          className="edit_icon handle"
                      >
                        Add more GST No.
                        <i className="fa fa-plus" title="Add GST Number"/>
                      </a>
                    </div>
                    {gstFields}
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <AvGroup>
                      <label>Pan Number</label>
                      <AvField
                          type="text"
                          id="pan_no"
                          name="pan_no"
                          value={
                            this.state.address && this.state.address.pan_no
                                ? this.state.address.pan_no
                                : ""
                          }
                      />
                    </AvGroup>
                  </div>
                </div>

                {this.props.selectedContactType === SHIPPER ||
                this.state.contactTypeValue === SHIPPER ? (
                    <Fragment>
                      <div className="col-lg-4 col-sm-4">
                        <div className="form-group">
                          <AvGroup>
                            <label>
                              Registration Type.{" "}
                              <span className="required">*</span>
                            </label>
                            <AvField
                                type="select"
                                id="registration_type"
                                name="registration_type"
                                value={
                                  this.state.address &&
                                  this.state.address.registration_type
                                      ? this.state.address.registration_type
                                      : ""
                                }
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter Registration Type",
                                  },
                                }}
                            >
                              <option value="">SELECT</option>
                              <option value="IEC No">IEC No</option>
                              <option value="CIN No">CIN No</option>
                              <option value="PAN No">PAN No</option>
                              <option value="Aadhaar No">Aadhaar No</option>
                              <option value="Passport No">Passport No</option>
                            </AvField>
                          </AvGroup>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4">
                        <div className="form-group">
                          <AvGroup>
                            <label>
                              Registration No./IEC No.{" "}
                              <span className="required">*</span>
                            </label>
                            <AvField
                                type="text"
                                id="registration_no"
                                name="registration_no"
                                value={
                                  this.state.address &&
                                  this.state.address.registration_no
                                      ? this.state.address.registration_no
                                      : ""
                                }
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                        "Please enter Registration No./IEC No",
                                  },
                                }}
                            />
                          </AvGroup>
                        </div>
                      </div>
                    </Fragment>
                ) : null}

                {this.props.selectedContactType === FORWARDER || this.state.contactTypeValue === FORWARDER || this.props.selectedContactType === CHA || this.state.contactTypeValue === CHA || this.props.selectedContactType === SHIPPER || this.state.contactTypeValue === SHIPPER ? (
                    <Fragment>
                      <div className="col-lg-4 col-sm-4">
                        <div className="form-group">
                          <AvGroup>
                            <label>Expoter Type{" "}</label>
                            <AvField
                                type="select"
                                id="type_of_expoter"
                                name="type_of_expoter"
                                value={
                                  this.state.address &&
                                  this.state.address.type_of_expoter
                                      ? this.state.address.type_of_expoter
                                      : ""
                                }
                            >
                              {expoterType}

                            </AvField>

                          </AvGroup>
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-4">
                        <div className="form-group">
                          <AvGroup>
                            <label>Forex Bank Acc </label>
                            <AvField
                                type="text"
                                id="forex_bank_acc"
                                name="forex_bank_acc"
                                value={
                                  this.state.address &&
                                  this.state.address.forex_bank_acc
                                      ? this.state.address.forex_bank_acc
                                      : ""
                                }
                            />
                          </AvGroup>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4">
                        <div className="form-group">
                          <AvGroup>
                            <label>Authorised Dealer Code</label>
                            <AvField
                                type="text"
                                id="authorised_dealer_code"
                                name="authorised_dealer_code"
                                value={
                                  this.state.address &&
                                  this.state.address.authorised_dealer_code
                                      ? this.state.address.authorised_dealer_code
                                      : ""
                                }
                            />
                          </AvGroup>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4">
                        <div className="form-group">
                          <AvGroup>
                            <label> I.F.S. Code </label>
                            <AvField
                                type="text"
                                id="ifs_code"
                                name="ifs_code"
                                value={
                                  this.state.address &&
                                  this.state.address.ifs_code
                                      ? this.state.address.ifs_code
                                      : ""
                                }
                            />
                          </AvGroup>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4">
                        <div className="form-group">
                          <AvGroup>
                            <label>RBI Waiver No </label>
                            <AvField
                                type="text"
                                id="rbi_waiver_no"
                                name="rbi_waiver_no"
                                value={
                                  this.state.address &&
                                  this.state.address.rbi_waiver_no
                                      ? this.state.address.rbi_waiver_no
                                      : ""
                                }
                            />
                          </AvGroup>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4">
                        <div className="form-group">
                          <AvGroup>
                            <label>Drawback Account No </label>
                            <AvField
                                type="text"
                                id="drawback_acc_no"
                                name="drawback_acc_no"
                                value={
                                  this.state.address &&
                                  this.state.address.drawback_acc_no
                                      ? this.state.address.drawback_acc_no
                                      : ""
                                }
                            />
                          </AvGroup>
                        </div>
                      </div>
                    </Fragment>
                ) : null}

                {this.props.selectedContactType === SHIPPER || this.state.contactTypeValue === SHIPPER ? (
                    <div className="col-lg-4 col-sm-4">
                      <div className="form-group">
                        <AvGroup>
                          <label>
                            Invoice Prefix <span className="required">*</span>
                          </label>
                          <AvField
                              type="textarea"
                              id="invoice_prefix"
                              name="invoice_prefix"
                              value={
                                this.state.address && this.state.address.invoice_prefix
                                    ? this.state.address.invoice_prefix
                                    : ""
                              }
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter Invoice Prefix",
                                },
                              }}
                          />
                        </AvGroup>
                      </div>
                    </div>
                ) : null}


                <div className="col-lg-4 col-sm-4">
                  <label>Company Logo</label>
                  <div className="form-group">
                    <div className="upload-2-files hazardous-uplod-file">
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          <i className="fa fa-file-alt"/> Select File
                        </button>
                        <input
                            type="file"
                            name="company_logo"
                            id="company_logo"
                            onChange={this.getCompanyLogo}
                        />

                        <p class="error-color" className={'fs-13px'}>
                          {this.state.companyLogoName}
                        </p>
                        <CustomFeedback>
                          {" "}
                          {this.state.companyLogoError}
                        </CustomFeedback>
                      </div>
                      {this.state.address && this.state.address.logo ? (
                          !this.state.address.logo.includes(".pdf") ? (
                              <a
                                  href={AWS_S3_URL + this.state.address.logo}
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                <img
                                    className={'w-50px h-50px'}
                                    alt={"CompanyLogo"}
                                    src={AWS_S3_URL + this.state.address.logo}
                                />
                              </a>
                          ) : (
                              <a
                                  href={AWS_S3_URL + this.state.address.logo}
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                <img
                                    className={'w-20px h-20px'}
                                    alt={"CompanyLogo"}
                                    src={PdfIcon}
                                />
                              </a>
                          )
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-3">
                  <label>Authorization Letter</label>
                  <div className="form-group">
                    <div className="upload-2-files hazardous-uplod-file">
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          <i className="fa fa-file-alt"/>
                          Select File
                        </button>
                        <input
                            type="file"
                            name="authorization_letter"
                            id="authorization_letter"
                            onChange={() =>
                                this.getFilename(
                                    "authorization_letter",
                                    "VGMauthorizationLetterName",
                                    "VGMauthorizationLetterError",
                                    "VGMauthorizationLetterDoc",
                                    "VGMauthorizationLetterExistingFile"
                                )
                            }
                        />

                        <CustomFeedback>
                          {" "}
                          {this.state.VGMauthorizationLetterError}
                        </CustomFeedback>
                      </div>
                      {this.state.address &&
                      this.state.address.authorization_letter ? (
                          <a
                              className="profile-address-file-ui"
                              target="_blank"
                              rel="noreferrer noopener"
                              href={
                                  AWS_S3_URL + this.state.address.authorization_letter
                              }
                          >
                            {this.state.address.authorization_letter.split('/').pop()}
                          </a>
                      ) : (
                          ""
                      )}
                      <p
                          className={'fs-13px text-black1'}
                      >
                        {this.state.VGMauthorizationLetterName}
                      </p>
                    </div>
                  </div>
                </div>
                {this.props.selectedContactType === SHIPPER ||
                this.state.contactTypeValue === SHIPPER ||
                this.props.selectedContactType === BOOKING_PARTY ||
                this.state.contactTypeValue === BOOKING_PARTY ||
                this.props.selectedContactType === CHA ||
                this.state.contactTypeValue === CHA ||
                this.props.selectedContactType === FORWARDER ||
                this.state.contactTypeValue === FORWARDER ||
                this.props.selectedContactType === INVOICE_PARTY ||
                this.state.contactTypeValue === INVOICE_PARTY ? (
                    <Fragment>
                      <div className="col-lg-4 col-sm-3">
                        <label>Please upload GST File</label>
                        <div className="upload-2-files hazardous-uplod-file">
                          <div className="upload-btn-wrapper">
                            <button className="btn">
                              <i className="fa fa-file-alt"/> Select File
                            </button>
                            <input
                                type="file"
                                name="gst_file"
                                id="gst_file"
                                onChange={() =>
                                    this.uploadFiles(
                                        "gst_file",
                                        "gstFile",
                                        "gstFileName",
                                        "gstFileError"
                                    )
                                }
                            />
                          </div>
                          {this.state.address &&
                          this.state.address.kyc_documents &&
                          this.state.address.kyc_documents["gst_file"] ? (
                              <a
                                  className="profile-address-file-ui"
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  href={
                                      AWS_S3_URL +
                                      this.state.address.kyc_documents["gst_file"]
                                  }
                              >{this.state.address.kyc_documents["gst_file"].split('/').pop()}</a>
                          ) : (
                              ""
                          )}
                          <p className={'fs-13px text-black1'}>
                            {this.state.gstFileName}
                          </p>
                        </div>
                        <CustomFeedback>{this.state.gstFileError}</CustomFeedback>
                      </div>

                      <div className="col-lg-4 col-sm-3">
                        <label>Please upload PAN Card</label>
                        <div className="upload-2-files hazardous-uplod-file">
                          <div className="upload-btn-wrapper">
                            <button className="btn">
                              <i className="fa fa-file-alt"/> Select File
                            </button>
                            <input
                                type="file"
                                name="pan_card"
                                id="pan_card"
                                onChange={() =>
                                    this.uploadFiles(
                                        "pan_card",
                                        "panCard",
                                        "panCardName",
                                        "panCardError"
                                    )
                                }
                            />
                          </div>
                          {this.state.address &&
                          this.state.address.kyc_documents &&
                          this.state.address.kyc_documents["pan_card"] ? (
                              <a
                                  className="profile-address-file-ui"
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  href={
                                      AWS_S3_URL +
                                      this.state.address.kyc_documents["pan_card"]
                                  }
                              >{this.state.address.kyc_documents["pan_card"].split('/').pop()}</a>
                          ) : (
                              ""
                          )}
                          <p className={'fs-13px text-black1'}>
                            {this.state.panCardName}
                          </p>
                        </div>
                        <CustomFeedback>{this.state.panCardError}</CustomFeedback>
                      </div>

                      <div className="col-lg-4 col-sm-3">
                        <label>Please upload Utility Bill</label>
                        <div className="upload-2-files hazardous-uplod-file">
                          <div className="upload-btn-wrapper">
                            <button className="btn">
                              <i className="fa fa-file-alt"/> Select File
                            </button>
                            <p>
                              Any 2 (Electricity, Telephone, Premises maintenance
                              bill / L&L copy, Gas bill).
                            </p>
                            <input
                                type="file"
                                name="utility_bill"
                                id="utility_bill"
                                onChange={this.uploadUtilityBill}
                                multiple
                            />
                          </div>
                        </div>
                        <div className="vgm-div-box-right vgm-div-box-hazardous-uplod-file">
                          <ul>
                            {this.state.utilityBillList}
                            {utilityBillExistingList}
                          </ul>
                        </div>
                        <CustomFeedback>
                          {this.state.utilityBillError}
                        </CustomFeedback>
                      </div>
                    </Fragment>
                ) : null}
              </div>
              <div className="submit-btn-pop">
                <Button color="primary">
                  <ButtonLoader
                      isLoading={this.props.modalLoading}
                      label={"Submit"}
                  />
                </Button>
                <Button color="secondary" outline onClick={this.toggleAddModal}>
                  Cancel
                </Button>
              </div>
            </ModalBody>
            {/*<ModalFooter>*/}

            {/*</ModalFooter>*/}
          </AvForm>
        </Modal>
      </Fragment>
    );
  }
}

export default ContactModal;
