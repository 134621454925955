import React, {Component, Fragment} from "react";
/*
Library
 */
import {Button} from 'reactstrap';
/*
 Services
*/
import Common from "../../../util/common.service";
import ContactCard from "../../../components/contact/ContactCard";
import ContactModal from "../../../components/contact/ContactModal";
import {NotificationManager} from 'react-notifications';
import Contact from "../../../util/contact.service";
import {fetchContacts} from "../../../redux/contacts/actions";
import {connect} from "react-redux";

import 'react-notifications/lib/notifications.css';
import {filterCaseInsensitive} from "../../../util/util.service";
import ReactTable from "react-table";
import {contactType} from "../../../constants/contactType";

class AddressBook extends Component {

    state = {
        addressList: [],
        countryList: [],
        stateList: [],
        cityList: [],
        address: [],
        country: null,
        modalOpen: false,
        modalViewOpen: false,
        modalLoading: false,
        action: true,
        selectedContactType: ""
    };

    componentDidMount() {
        this.props.fetchContacts();
        this.getCountryDetails();
    };


    getCountryDetails = () => {
        let countryList = [], selectedCountry = null;
        Common.getAllCountry()
            .then(response => {
                if (response !== undefined) {
                    let country_list = response.data.data;
                    for (let country in country_list) {
                        if (country_list[country].id === 96) {
                            selectedCountry = {
                                label: country_list[country].name,
                                value: country_list[country].id,
                                key: country_list[country].id
                            }
                        }

                        countryList.push({
                            label: country_list[country].name,
                            value: country_list[country].id,
                            key: country_list[country].id
                        })
                    }
                    this.setState({countryList, country: selectedCountry}, this.getStateDetails)
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    getStateDetails = () => {
        let stateList = [];
        Common.stateByCountry(this.state.country.value)
            .then(response => {
                if (response !== undefined) {
                    let state_list = response.data.data;
                    for (let state in state_list) {
                        stateList.push({
                            label: state_list[state].name,
                            value: state_list[state].id,
                            key: state_list[state].id
                        })
                    }
                    this.setState({stateList});
                }
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });;
    }

    addAddress = (data) => {
        this.setState({modalLoading: true});
        Contact.add(data)
            .then(response => {
                if (response !== undefined) {
                    if (response.data.status === "success") {
                        this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                        this.props.fetchContacts();
                        NotificationManager.success('Success message', response.data.message);
                    }
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    updateAddress = (data, id) => {
        this.setState({modalLoading: true});
        Contact.update(id, data)
            .then(response => {
                if (response !== undefined) {
                    if (response.data.status === "success") {
                        this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                        NotificationManager.success('Success message', response.data.message);
                        this.props.fetchContacts();
                    }
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    toggleAddModal = () => {
        this.setState({modalOpen: !this.state.modalOpen, action: true, address: [], cityList: [], selectedContactType:""});
    };

    editAddress = (id) => {
        let address = this.props.contacts.list.find(address => address.id === id);
        let stateList = [];
        Common.stateByCountry(address.country_id)
            .then(response => {
                if (response !== undefined) {
                    let state_list = response.data.data;
                    for (let state in state_list) {
                        stateList.push({
                            label: state_list[state].name,
                            value: state_list[state].id,
                            key: state_list[state].id
                        })
                    }
                    this.setState({});
                }
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });

        let cityList = [];
        Common.cityByState(address.state_id)
            .then(response => {
                if (response !== undefined) {
                    let city_list = response.data.data;
                    for (let city in city_list) {
                        cityList.push({
                            label: city_list[city].name,
                            value: city_list[city].id,
                            key: city_list[city].id
                        })
                    }
                }
            });
        this.setState({
            modalOpen: !this.state.modalOpen,
            action: false,
            address,
            cityList,
            stateList,
            selectedContactType: address.type
        });
    };

    viewAddress = (id) => {
        let address = this.props.contacts.list.find(address => address.id === id);
        this.setState({modalViewOpen: !this.state.modalViewOpen, address});
    };

    toggelViewAddress = () => {
        this.setState({modalViewOpen: !this.state.modalViewOpen});
    };

    removeAddress = (id) => {
        Contact.delete(id)
            .then(response => {
                if (response !== undefined) {
                    if (response.data.status === "success") {
                        this.props.fetchContacts();
                        NotificationManager.success('Success message', response.data.message);
                    }
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    render() {
        let contactDetails = [];
        if (!this.props.contacts.loading) {
            for (let contact of this.props.contacts.list) {
                contactDetails.push({
                    id: contact.id,
                    type: contactType[contact.type],
                    contact_person: contact.contact_person,
                    company_name: contact.company_name
                });
            }
        }
        return (
            <Fragment>

                <div className="add-line-bg">
                    <h1>Address Book</h1>
                    <div className="add-btn-address-plus">
                        <Button onClick={this.toggleAddModal}>
                            <i className="fa fa-plus"/> Add Address
                        </Button>
                    </div>

                </div>
                {this.state.modalOpen ?
                    <ContactModal addAddress={this.addAddress}
                                  updateAddress={this.updateAddress}
                                  countryList={this.state.countryList}
                                  stateList={this.state.stateList}
                                  cityList={this.state.cityList}
                                  country={this.state.country}
                                  address={this.state.address}
                                  modalOpen={this.state.modalOpen}
                                  action={this.state.action}
                                  toggleAddModal={this.toggleAddModal}
                                  modalLoading={this.state.modalLoading}
                                  selectedContactType={this.state.selectedContactType}/>
                    : ""}

                {this.state.modalViewOpen ?
                    <ContactCard
                        address={this.state.address}
                        modalViewOpen={this.state.modalViewOpen}
                        toggelViewAddress={this.toggelViewAddress}/>
                    : null}
                <div className="table-view-add">
                    <ReactTable
                        data={contactDetails}
                        columns={
                            [
                                {
                                    Header: "Contact Person",
                                    accessor: "contact_person",
                                    Cell: props => <p className="text-muted">{props.value}</p>
                                },
                                {
                                    Header: "Contact Type",
                                    accessor: "type",
                                    Cell: props => <p className="text-muted">{props.value}</p>
                                },
                                {
                                    Header: "Company Name",
                                    accessor: "company_name",
                                    Cell: props => <p
                                        className="text-muted">{props.value}</p>
                                },
                                {
                                    Header: "Action",
                                    accessor: "action",
                                    filterable: false,
                                    sortable: false,
                                    Cell: props => <p className="text-muted">
                                        <a onClick={() => this.editAddress(props.original.id)}>
                                                    <span
                                                        className="edit_icon badge badge-pill badge-secondary handle mr-1">
                                                        <i className="fa fa-pencil-square-o" title="Edit Address"/>
                                                    </span>
                                        </a>
                                        &nbsp; &nbsp;
                                        <a onClick={() => this.viewAddress(props.original.id)}>
                                                    <span
                                                        className="edit_icon badge badge-pill badge-secondary handle mr-1">
                                                        <i className="fa fa-eye" title="View Address"/>
                                                    </span>
                                        </a>
                                        &nbsp; &nbsp;
                                        <a onClick={() => this.removeAddress(props.original.id)}>
                                                    <span
                                                        className="edit_icon badge badge-pill badge-secondary handle mr-1">
                                                            <i className="fa fa-trash" title="Delete Address"/>
                                                    </span>
                                        </a>
                                    </p>
                                }
                            ]
                        }
                        defaultPageSize={5}
                        filterable
                        showPageJump={true}
                        previousText={<i className="fa fa-chevron-left"/>}
                        nextText={<i className="fa fa-chevron-right"/>}
                        //PaginationComponent={DataTablePagination}
                        showPageSizeOptions={true}
                        defaultFilterMethod={filterCaseInsensitive}
                    />
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({contacts}) => {
    return {contacts};
};

export default connect(mapStateToProps, {fetchContacts})(AddressBook);