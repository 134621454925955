import React, {Component, Fragment} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {AWS_S3_URL} from "../../constants/apiConstants";
import {contactType, SHIPPER} from "../../constants/contactType";

class ContactCard extends Component {
    state = {
        addressDetails: [],
        isLoading: true
    };

    componentDidMount() {
        this.setState({addressDetails: this.props.address, isLoading: false});
        console.log(this.props.address);
    }

    render() {
        return !this.state.isLoading ?
            <Fragment>
                <Modal
                    isOpen={this.props.modalViewOpen}
                    toggle={this.props.toggelViewAddress}
                    wrapClassName="modal-right"
                    backdrop="static"
                    className="add-address-popoup"
                >
                    <ModalHeader toggle={this.props.toggelViewAddress}>
                        <h2>View Address</h2>
                    </ModalHeader>
                    <ModalBody className="address-book-view-pop">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <p>Contact Person</p>
                                    <b>{this.state.addressDetails ? this.state.addressDetails.contact_person : ""}</b>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <p>Company Name</p>
                                    <b>{this.state.addressDetails ? this.state.addressDetails.company_name : ""}</b>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group">
                                    <p>Email</p>
                                    <b>{this.state.addressDetails ? this.state.addressDetails.email : ""}</b>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <p>Mobile</p>
                                    <b>{this.state.addressDetails ? this.state.addressDetails.mobile : ""}</b>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <p>Contact Type</p>
                                    <b>{contactType[this.state.addressDetails.type]}</b>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group">
                                    <p>Country</p>
                                    <b>{this.state.addressDetails.country && this.state.addressDetails.country.name ? this.state.addressDetails.country.name : ""}</b>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group">
                                    <p>State</p>
                                    <b>{this.state.addressDetails.state && this.state.addressDetails.state.name ? this.state.addressDetails.state.name : ""}</b>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group">
                                    <p>City</p>
                                    <b>{this.state.addressDetails.city && this.state.addressDetails.city.name ? this.state.addressDetails.city.name : ""}</b>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group">
                                    <p>Tax id number</p>
                                    <b>{this.state.addressDetails.tax_id ? this.state.addressDetails.tax_id : "NA"}</b>
                                </div>
                            </div>

                            {this.state.addressDetails.type === SHIPPER ?
                                <Fragment>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <p>Registration No./IEC No.</p>
                                            <b>{this.state.addressDetails.registration_no ? this.state.addressDetails.registration_no : "NA"}</b>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="form-group">
                                            <p>Registration Type</p>
                                            <b>{this.state.addressDetails.registration_type ? this.state.addressDetails.registration_type : "NA"}</b>
                                        </div>
                                    </div>
                                </Fragment> : null
                            }
                            <div className="col-6">
                                <div className="form-group">
                                    <div className="upload-2-files">
                                        <div className="upload-btn-wrapper">
                                            {this.state.addressDetails.logo ?
                                                <img className={'w-50px h-50px'}
                                                     src={AWS_S3_URL + this.state.addressDetails.logo}
                                                     alt={"Logo"}/> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <p>Address</p>
                                    <b>{this.state.addressDetails.address ? this.state.addressDetails.address : "NA"}</b>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment> : <Fragment/>
    }
}

export default ContactCard;