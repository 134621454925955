import React, {Component, Fragment} from "react";
import {Route, withRouter, Switch} from "react-router-dom";
import {connect} from "react-redux";
import TopMenu from "./../TopMenu";
import {Col, Container, Row} from "reactstrap";
import EnquiryDetails from "./EnquiryDetails";
import {fetchEnquiries} from "../../../redux/enquiry/actions";
import SideNav from "./SideNav";
import NoEnquiry from "./NoEnquiry";

class Enquiry extends Component {
    state = {
        is_loading: true,
        enquiryList: []
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.enquiries.loaded && this.props.auth.is_authenticated) {
            this.props.fetchEnquiries();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(prevProps.enquiries, prevState.enquiries);
    }

    render() {
        const {match} = this.props;
        return (
            <Fragment>
                <TopMenu/>
                <Container>
                    <Row>
                        <div className="col-sm-12 col-lg-12">
                            <div className="note">Commodities may need special handling and approvals
                                are -Soya, Raw Cotton, Batteries, Tobacco, Incense and Match Sticks, Oils, Toys,
                                Scrap, Personal Effects</div>
                        </div>
                        <SideNav/>
                        <Col lg="8" sm="8">
                            <Switch>
                                <Route path={`${match.url}/history`} component={NoEnquiry}/>
                                <Route path={`${match.url}/:id/view`} component={EnquiryDetails}/>
                                <Route path={`${match.url}/:id/check-list`} component={EnquiryDetails}/>
                            </Switch>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = ({auth, enquiries}) => {
    return {auth, enquiries};
};

export default withRouter(
    connect(
        mapStateToProps,
        {fetchEnquiries}
    )(Enquiry)
);