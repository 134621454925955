import React, {Component, Fragment} from "react";
import {Route, Switch} from "react-router-dom";
import TopMenu from "./../TopMenu";

import {Col, Container, Row} from "reactstrap";
import BlList from "./bl-list";
import ContainerFilter from "./containerFilter";
import draftBLPage from "./draft-bl";

class Bl extends Component {

    state = {};

    componentDidMount() {

    };

    render() {
        const {match} = this.props;
        return (
            <Fragment>
                <TopMenu/>
                <Container>
                    <Row>
                        <Col lg="12" sm="12">
                            <Switch>
                                <Route path={`${match.url}/history`} component={BlList}/>
                                <Route path={`${match.url}/draft-bl`} component={ContainerFilter}/>
                                <Route path={`${match.url}/draft-bl-new`} component={draftBLPage}/>
                                <Route path={`${match.url}/edit-bl/:id`} component={draftBLPage}/>
                            </Switch>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Bl;
